<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title>
        {{title}}
      </v-card-title>
      <v-card-text class="text-center">
        <v-icon
          size="300"
          color="green"
        >
          mdi-check-circle-outline
        </v-icon>
        <br>
        <div class="text-subtitle-1">{{message}}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="white--text" :color="acceptColor" block @click="acceptEvent">{{acceptText}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    show:false,
    title: "",
    message: "",
    acceptText: "Aceptar",
    acceptColor:"green",
  }),
  methods:{
    setMessage(title, message){
      this.title = title;
      this.message = message;
    },
    acceptEvent(){
      this.$emit('accept');
      this.close();
    },
    setText(accept){
      this.acceptText = accept;
    },
    setColor(accept){
      this.acceptColor = accept;
    },
    open(){
      this.show = true;
    },
    close(){
      this.show = false;
    },
    reset(){
      this.title = "";
      this.message = "";
      this.acceptText = "Aceptar";
      this.acceptColor = "red";
    }
  }
}
</script>