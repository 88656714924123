<template>
  <div class="sticky-content">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-card>
      <v-card-text>
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <span class="font-weight-bold">Folio Paciente:</span> {{paciente.folio}}
            <br>
            <span class="font-weight-bold">Nombre:</span> {{paciente.nombre}}
            <br>
            <span class="font-weight-bold">Apellido Paterno:</span> {{paciente.apellido_paterno}}
            <br>
            <span class="font-weight-bold">Apellido Materno:</span> {{paciente.apellido_materno}}
            <br>
            <span class="font-weight-bold">Fecha de Nacimiento:</span> {{paciente.fecha_nacimiento}}
            <br>
            <span class="font-weight-bold">Edad:</span> {{paciente.edad}}
            <br>
            <span class="font-weight-bold">Sexo:</span> {{paciente.genero ? 'Masculino' : 'Femenino'}}
          </v-col>
          <v-col cols="12">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="green darken-2"
                    >
                      mdi-weight-kilogram
                    </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                    Peso: {{signos_vitales.peso}} Kg
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="blue darken-2"
                    >
                      mdi-human-male-height-variant
                    </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                    Estatura: {{signos_vitales.estatura}} m
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="purple darken-2"
                    >
                      mdi-percent-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                    IMC: {{signos_vitales.imc}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0"
                v-if="signos_vitales.cintura != null"
              >
                <v-row class="ma-0 pa-0">
                  <v-col
                    
                    cols="2"
                    class="ma-0 pa-0 text-center"
                  >
                    <v-icon
                      color="teal darken-2"
                    >
                      mdi-tape-measure
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="10"
                    class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                  >
                    Cintura: {{signos_vitales.cintura}} cm
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                  <v-icon
                    color="blue-grey darken-2"
                  >
                    mdi-thermometer
                  </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                    Temperatura: {{signos_vitales.temperatura}}° C
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="red darken-2"
                    >
                      mdi-heart
                    </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                    Pulso: {{signos_vitales.pulso}} ppm
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="orange darken-2"
                    >
                      mdi-lungs
                    </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                    Respiración: {{signos_vitales.respiracion}} rpm
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="indigo darken-2"
                    >
                      mdi-counter
                    </v-icon>
                  </v-col>
                  <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                    Oximetría: {{signos_vitales.oximetria}}% Sp02
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0"
                v-if="signos_vitales.antigeno_prostatico != null"
              >
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="teal darken-2"
                      
                    >
                      mdi-clipboard-account-outline
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="10"
                    class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                  >
                    Antigeno Prostatico: {{signos_vitales.antigeno_prostatico}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0"
                v-if="signos_vitales.otros != null"
              >
                <v-row class="ma-0 pa-0">  
                  <v-col cols="2" class="ma-0 pa-0 text-center">
                    <v-icon
                      color="green darken-2"
                    >
                      mdi-comment-text
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="10"
                    class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                  >
                    Observaciones: {{signos_vitales.otros}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0"
                v-if="signos_vitales.sistolica_izquierda != null || signos_vitales.sistolica_derecha != null || signos_vitales.hipertension_arterial_otros != null"
              >
                <v-row class="ma-0 pa-0">
                  <v-col
                    cols="2"
                    class="ma-0 pa-0 text-center align-self-center"
                  >
                    <v-icon
                      color="blue darken-2"
                    >
                      mdi-heart-pulse
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="10"
                    class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                  >
                    <template
                      v-if="signos_vitales.sistolica_izquierda != null"
                    >
                      T.A. Sistólica izq: {{signos_vitales.sistolica_izquierda}}
                      <br>
                    </template>
                    <template
                      v-if="signos_vitales.diastolostica_izquierda != null"
                    >
                      T.A. Diastólica izq: {{signos_vitales.diastolostica_izquierda}}
                      <br>
                    </template>
                    <template
                      v-if="signos_vitales.sistolica_derecha != null"
                    >
                      T.A. Sistólica der: {{signos_vitales.sistolica_derecha}}
                      <br>
                    </template>
                    <template
                      v-if="signos_vitales.diastolostica_derecha != null"
                    >
                      T.A. Diastólica der: {{signos_vitales.diastolostica_derecha}}
                      <br>
                    </template>
                    <template
                      v-if="signos_vitales.hipertension_arterial_otros != null"
                    >
                      Observaciones: {{signos_vitales.hipertension_arterial_otros}}
                      <br>
                    </template>
                    <br>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0"
                v-if="signos_vitales.glucosa != null || (signos_vitales.colesterol != null || signos_vitales.colesterol_otro != null) || (signos_vitales.trigliceridos != null || signos_vitales.trigliceridos_otro != null)"
              >
                <v-row class="ma-0 pa-0">                            
                  <v-col cols="2" class="ma-0 pa-0 text-center align-self-center">
                    <v-icon
                      color="purple darken-2"
                    >
                      mdi-flask
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="10"
                    class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                  >
                    <template v-if="signos_vitales.glucosa != null">
                      Glucosa: {{signos_vitales.glucosa}}
                      <br>
                    </template>
                    <template v-if="(signos_vitales.colesterol != null || signos_vitales.colesterol_otro != null)">
                      Colesterol: {{signos_vitales.colesterol_otro || signos_vitales.colesterol}}
                      <br>
                    </template>
                    <template v-if="(signos_vitales.trigliceridos != null || signos_vitales.trigliceridos_otro != null)">
                      Trigliceridos: {{signos_vitales.trigliceridos_otro || signos_vitales.trigliceridos}}
                      <br>
                    </template>
                    <template v-if="signos_vitales.quimicas_secas_observaciones != null">
                      Observaciones: {{signos_vitales.quimicas_secas_observaciones}}
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="abrirDialogo"
          small
        >
          <v-icon>
            mdi-pencil
          </v-icon>
          Editar Datos del Paciente
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialogo"
      max-width="80%"
      persistent
    >
      <v-card>
        <v-card-title>
          Actualizar Datos del Paciente
        </v-card-title>
        <v-card-text>
          <div class="text-subtitle-1 font-weight-bold text-center">Datos Básicos</div>
          <br>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="paciente.nombre"
                label="Nombre(s)"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="paciente.apellido_paterno"
                label="Apellido Paterno"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="paciente.apellido_materno"
                label="Apellido Materno"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-datetime-picker
                label="Fecha de Nacimiento"
                v-model="paciente.fecha_nacimiento_vue"
              >
              </v-datetime-picker>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="paciente.sexo"
                :items="generos"
                item-value="id_genero"
                item-text="nombre"
                label="Sexo"
                outlined
                @change="cambioGenero"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="paciente.embarazada"
                :items="respuestas"
                item-text="text"
                item-value="value"
                label="Embarazada"
                outlined
                :readonly="paciente.sexo != 1"
                :disabled="paciente.sexo != 1"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br>
          <div class="text-subtitle-1 font-weight-bold text-center">General</div>
          <br>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.peso"
                outlined
                label="Peso (Kg)"
                @change="calculateIMC"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.estatura"
                outlined
                label="Estatura (metros)"
                @change="calculateIMC"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.imc"
                outlined
                readonly
                label="IMC (%)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.cintura"
                outlined
                label="Cintura (cm) [Opcional]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.temperatura"
                outlined
                label="Temperatura (°C)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.pulso"
                outlined
                label="Pulso (ppm)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.respiracion"
                outlined
                label="Respiración (rpm)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.oximetria"
                outlined
                label="Oximetría (% Sp02)"
              >
              </v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.antigeno_prostatico"
                outlined
                label="Antigeno Prostatico"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="signos_vitales.otros"
                outlined
                label="Otros"
              >
              </v-text-field>
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <br>
          <div class="text-subtitle-1 font-weight-bold text-center">Detección de Presión Arterial</div>
          <br>
          <v-row>
            <v-col cols="6" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0">
                 <v-col cols="12">
                  <div class="text-subtitle-1 font-weight-bold text-center">Brazo Izquierdo</div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="signos_vitales.sistolica_izquierda"
                    outlined
                    label="T.A. Sistólica izq."
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="signos_vitales.diastolostica_izquierda"
                    outlined
                    label="T.A. Diastólica"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-0 ma-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <div class="text-subtitle-1 font-weight-bold text-center">Brazo Derecho</div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="signos_vitales.sistolica_derecha"
                    outlined
                    label="T.A. Sistólica der."
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="signos_vitales.diastolostica_derecha"
                    outlined
                    label="T.A. Diastólica"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="signos_vitales.hipertension_arterial_otros"
                outlined
                label="Observaciones"
                auto-grow
                rows="1"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br>
          <div class="text-subtitle-1 font-weight-bold text-center">Detecciones químicas secas</div>
          <br>
          <v-row>
            <v-col cols="4" class="align-self-center">
              <v-text-field
                v-model="signos_vitales.glucosa"
                outlined
                label="Glucosa"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.colesterol"
                outlined
                label="Colesterol"
                dense
                hide-details
                clearable
                :disabled="signos_vitales.colesterol_otro != null"
                @change="signos_vitales.colesterol_otro = null"
                @click:clear="clearColesterol"
              >
              </v-text-field>
              <br>
              <v-autocomplete
                v-model="signos_vitales.colesterol_otro"
                :items="fuera_rango"
                outlined
                label="Colesterol - Valor Fuera Rango"
                dense
                hide-details
                clearable
                :disabled="signos_vitales.colesterol != null && signos_vitales.colesterol.length > 0"
                @change="signos_vitales.colesterol = null"
                @click:clear="clearColesterol"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="signos_vitales.trigliceridos"
                outlined
                label="Triglicéridos"
                dense
                hide-details
                clearable
                :disabled="signos_vitales.trigliceridos_otro != null"
                @change="signos_vitales.trigliceridos_otro = null"
                @click:clear="clearTrigliceridos"
              >
              </v-text-field>
              <br>
              <v-autocomplete
                v-model="signos_vitales.trigliceridos_otro"
                :items="fuera_rango"
                outlined
                label="Triglicéridos - Valor Fuera Rango"
                dense
                hide-details
                clearable
                :disabled="signos_vitales.trigliceridos != null && signos_vitales.trigliceridos.length > 0"
                @change="signos_vitales.trigliceridos = null"
                @click:clear="clearTrigliceridos"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="signos_vitales.quimicas_secas_observaciones"
                outlined
                label="Observaciones"
                auto-grow
                rows="1"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="cerrarDialogo"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="guardarDatos"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import VAlertDialog from '@/components/VAlertDialog.vue'
import VDatetimePicker from '@/components/VDatetimePicker.vue'

export default {
  name: "PacienteDatos",
  components: {
    VAlertDialog,
    VDatetimePicker
  },
  data: () => ({
    fuera_rango: [
      {
        text: "LOW",
        value: "Low"
      },
      {
        text: "HIGH",
        value: "High"
      },
    ],
    generos: [
      {
        id_genero: 1,
        nombre: "Femenino"
      },
      {
        id_genero: 2,
        nombre: "Masculino"
      }
    ],
    respuestas: [
      {
        value: 2,
        text: "Si"
      },
      {
        value: 1,
        text: "No"
      }
    ],

    default_paciente: {
      id_paciente: null,
      id_municipio: null,
      municipio: "",
      id_estado: null,
      estado: "",
      id_localidad_atencion: null,
      localidad_atencion: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      fecha_nacimiento_vue: "",
      edad: "",
      genero: true,
      curp: "",
      seguro_popular: false,
      num_poliza: "",
      telefono: "",
      email: ""
    },
    default_signos_vitales: {
      peso: 0,
      estatura: 0,
      imc: 0,
      cintura: null,
      temperatura: 0,
      pulso: 0,
      respiracion: 0,
      oximetria: 0,
      antigeno_prostatico: null,
      otros: null,
      sistolica_izquierda: null,
      diastolostica_izquierda: null,
      sistolica_derecha: null,
      diastolostica_derecha: null,
      hipertension_arterial_otros: null,
      glucosa: null,
      colesterol: null,
      colesterol_otro: null,
      trigliceridos: null,
      trigliceridos_otro: null,
      quimicas_secas_observaciones: null,
      fecha: ""
    },
    paciente: {
      id_paciente: null,
      id_municipio: null,
      municipio: "",
      id_estado: null,
      estado: "",
      id_localidad_atencion: null,
      localidad_atencion: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      fecha_nacimiento_vue: "",
      edad: "",
      genero: true,
      curp: "",
      seguro_popular: false,
      num_poliza: "",
      telefono: "",
      email: ""
    },
    signos_vitales: {
      peso: 0,
      estatura: 0,
      imc: 0,
      cintura: null,
      temperatura: 0,
      pulso: 0,
      respiracion: 0,
      oximetria: 0,
      antigeno_prostatico: null,
      otros: null,
      sistolica_izquierda: null,
      diastolostica_izquierda: null,
      sistolica_derecha: null,
      diastolostica_derecha: null,
      hipertension_arterial_otros: null,
      glucosa: null,
      colesterol: null,
      colesterol_otro: null,
      trigliceridos: null,
      trigliceridos_otro: null,
      quimicas_secas_observaciones: null,
      fecha: ""
    },
    dialogo: false,
    id_paciente_servicio: null,
    saving: false
  }),
  methods:{
    actualizar(id_paciente_servicio){
      this.id_paciente_servicio = id_paciente_servicio;
      axios
      .get(`/Pacientes/InformacionPaciente/${this.id_paciente_servicio}`)
      .then(response => {
        this.paciente = Object.assign({}, response.data.paciente);
        this.signos_vitales = Object.assign({}, response.data.signos_vitales);
        this.default_paciente = Object.assign({}, response.data.paciente);
        this.default_signos_vitales = Object.assign({}, response.data.signos_vitales);
        this.paciente.sexo = this.paciente.genero ? 2 : 1;
        this.paciente.embarazada = this.paciente.embarazada ? 2 : 1;
      })
    },
    isNotPositiveNumber(number){
      return !isFinite(parseFloat(number)) || isNaN(parseFloat(number)) || parseFloat(number) <= 0
    },
    calculateIMC(){
      try {
        var imc = (parseFloat(this.signos_vitales.peso) / Math.pow(parseFloat(this.signos_vitales.estatura), 2)).toFixed(2);
        if(!isFinite(imc) || isNaN(imc)) this.signos_vitales.imc = 0;
        else this.signos_vitales.imc = imc;
      } catch (error) {
        this.signos_vitales.imc = 0;
      }
    },
    cambioGenero(){
      if(this.paciente.sexo) this.paciente.embarazada = null;
    },
    abrirDialogo(){
      this.dialogo = true;
    },
    cerrarDialogo(){
      this.dialogo = false;
      this.paciente = Object.assign({}, this.default_paciente);
      this.signos_vitales = Object.assign({}, this.default_signos_vitales);
      this.paciente.sexo = this.paciente.genero ? 2 : 1;
      this.paciente.embarazada = this.paciente.embarazada ? 2 : 1;
    },  
    guardarDatos(){
      if(this.paciente.nombre == null || this.paciente.nombre.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Nombre del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.paciente.apellido_paterno == null || this.paciente.apellido_paterno.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Paterno del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.paciente.apellido_materno == null || this.paciente.apellido_materno.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Materno del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.paciente.fecha_nacimiento_vue == null || this.paciente.fecha_nacimiento_vue.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar la Fecha de Nacimiento del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.paciente.sexo <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Sexo del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.paciente.sexo == 1 && this.paciente.embarazada == null){
        this.$refs.alert.setMessage("", "Falta Ingresar si la Paciente esta Embarazada");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.peso == null || this.isNotPositiveNumber(this.signos_vitales.peso)){
        this.$refs.alert.setMessage("", "Falta Ingresar el Peso del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.estatura == null || this.isNotPositiveNumber(this.signos_vitales.estatura)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Altura del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.imc == null || this.isNotPositiveNumber(this.signos_vitales.imc)){
        this.$refs.alert.setMessage("", "Falta Ingresar el IMC del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.temperatura == null || this.isNotPositiveNumber(this.signos_vitales.temperatura)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Temperatura del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.pulso == null || this.isNotPositiveNumber(this.signos_vitales.pulso)){
        this.$refs.alert.setMessage("", "Falta Ingresar el Pulso del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.respiracion == null || this.isNotPositiveNumber(this.signos_vitales.respiracion)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Respiración del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.oximetria == null || this.isNotPositiveNumber(this.signos_vitales.oximetria)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Oximetría del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.signos_vitales.sistolica_izquierda != null && !this.isNotPositiveNumber(this.signos_vitales.sistolica_izquierda)){
        if(this.signos_vitales.diastolostica_izquierda == null || this.isNotPositiveNumber(this.signos_vitales.diastolostica_izquierda)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Diastólica Izquierda del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      if(this.signos_vitales.diastolostica_izquierda != null && !this.isNotPositiveNumber(this.signos_vitales.diastolostica_izquierda)){
        if(this.signos_vitales.sistolica_izquierda == null || this.isNotPositiveNumber(this.signos_vitales.sistolica_izquierda)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Sistólica Izquierda del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      if(this.signos_vitales.sistolica_derecha != null && !this.isNotPositiveNumber(this.signos_vitales.sistolica_derecha)){
        if(this.signos_vitales.diastolostica_derecha == null || this.isNotPositiveNumber(this.signos_vitales.diastolostica_derecha)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Diastólica Derecha del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      if(this.signos_vitales.diastolostica_derecha != null && !this.isNotPositiveNumber(this.signos_vitales.diastolostica_derecha)){
        if(this.signos_vitales.sistolica_derecha == null || this.isNotPositiveNumber(this.signos_vitales.sistolica_derecha)){
          this.$refs.alert.setMessage("", "Falta Ingresar la T.A. Sistólica Derecha del Paciente");
          this.$refs.alert.open();
          return;
        }
      }

      var payload = {
        id_paciente_servicio: this.id_paciente_servicio,
        nombre: this.paciente.nombre,
        apellido_paterno: this.paciente.apellido_paterno,
        apellido_materno: this.paciente.apellido_materno,
        fecha_nacimiento: this.paciente.fecha_nacimiento_vue,
        genero: (this.paciente.sexo == 2) ? true : false,
        embarazada: this.paciente.embarazada,
        peso: this.signos_vitales.peso,
        estatura: this.signos_vitales.estatura,
        imc: this.signos_vitales.imc,
        cintura: this.signos_vitales.cintura,
        temperatura: this.signos_vitales.temperatura,
        pulso: this.signos_vitales.pulso,
        respiracion: this.signos_vitales.respiracion,
        oximetria: this.signos_vitales.oximetria,
        antigeno_prostatico: this.signos_vitales.antigeno_prostatico,
        otros: this.signos_vitales.otros,
        sistolica_izquierda: this.signos_vitales.sistolica_izquierda,
        diastolostica_izquierda: this.signos_vitales.diastolostica_izquierda,
        sistolica_derecha: this.signos_vitales.sistolica_derecha,
        diastolostica_derecha: this.signos_vitales.diastolostica_derecha,
        hipertension_arterial_otros: this.signos_vitales.hipertension_arterial_otros,
        glucosa: this.signos_vitales.glucosa,
        colesterol: this.signos_vitales.colesterol,
        colesterol_otro: this.signos_vitales.colesterol_otro,
        trigliceridos: this.signos_vitales.trigliceridos,
        trigliceridos_otro: this.signos_vitales.trigliceridos_otro,
        quimicas_secas_observaciones: this.signos_vitales.quimicas_secas_observaciones
      };

      this.saving = true;

      axios
      .put(`/Pacientes/ActualizarInformacionPaciente`, payload)
      .then(() => {
        
        axios
        .get(`/Pacientes/InformacionPaciente/${this.id_paciente_servicio}`)
        .then(response => {
          this.paciente = Object.assign({}, response.data.paciente);
          this.signos_vitales = Object.assign({}, response.data.signos_vitales);
          this.default_paciente = Object.assign({}, response.data.paciente);
          this.default_signos_vitales = Object.assign({}, response.data.signos_vitales);
          this.paciente.sexo = this.paciente.genero ? 2 : 1;
          this.paciente.embarazada = this.paciente.embarazada ? 2 : 1;

          this.dialogo = false;
          this.default_paciente = Object.assign({}, this.paciente);
          this.default_signos_vitales = Object.assign({}, this.signos_vitales);
          this.paciente.sexo = this.paciente.genero ? 2 : 1;
          this.paciente.embarazada = this.paciente.embarazada ? 2 : 1;

          this.$emit('change', Object.assign({}, response.data.paciente));

          this.actualizar(this.id_paciente_servicio);
        })
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.saving = false
      })

    },
    clearTrigliceridos(){
      this.signos_vitales.trigliceridos_otro = null;
      this.signos_vitales.trigliceridos = null;
    },
    clearColesterol(){
      this.signos_vitales.colesterol_otro = null;
      this.signos_vitales.colesterol = null;
    }
  }
};
</script>

<style scoped>
.sticky-content {
  position: sticky;
  top: 20px; /* Ajusta la posición según sea necesario */
 }
</style>