<template>
  <div>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-row>
      <v-col cols="6">
        <span class="font-weight-bold">Exploración Física</span>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Piel
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_piel"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_piel_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Cabeza y cuello
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_cabeza"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_cabeza_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tórax
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_torax"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_torax_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Abdomen
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_abdomen"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_abdomen_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Columna
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_columna"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_columna_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Extremidades Superiores
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_extremidades_superiores"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_extremidades_superiores_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Extremidades Inferiores
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_extremidades_inferiores"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_extremidades_inferiores_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Genitales
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_genitales"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_genitales_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Sistema Nervioso
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_sistema_nervioso"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_sistema_nervioso_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Otros Agregados:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              v-model="editedItem.exploracion_otros"
              outlined
              hide-details
              dense
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VAlertDialog from '@/components/VAlertDialog.vue'

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialog: false,
    editedItem: {
      exploracion_piel: null,
      exploracion_piel_nota: null,
      exploracion_cabeza: null,
      exploracion_cabeza_nota: null,
      exploracion_torax: null,
      exploracion_torax_nota: null,
      exploracion_abdomen: null,
      exploracion_abdomen_nota: null,
      exploracion_columna: null,
      exploracion_columna_nota: null,
      exploracion_extremidades_superiores: null,
      exploracion_extremidades_superiores_nota: null,
      exploracion_extremidades_inferiores: null,
      exploracion_extremidades_inferiores_nota: null,
      exploracion_genitales: null,
      exploracion_genitales_nota: null,
      exploracion_sistema_nervioso: null,
      exploracion_sistema_nervioso_nota: null,
      exploracion_otros: null
    },
    defaultEditedItem: {
      exploracion_piel: null,
      exploracion_piel_nota: null,
      exploracion_cabeza: null,
      exploracion_cabeza_nota: null,
      exploracion_torax: null,
      exploracion_torax_nota: null,
      exploracion_abdomen: null,
      exploracion_abdomen_nota: null,
      exploracion_columna: null,
      exploracion_columna_nota: null,
      exploracion_extremidades_superiores: null,
      exploracion_extremidades_superiores_nota: null,
      exploracion_extremidades_inferiores: null,
      exploracion_extremidades_inferiores_nota: null,
      exploracion_genitales: null,
      exploracion_genitales_nota: null,
      exploracion_sistema_nervioso: null,
      exploracion_sistema_nervioso_nota: null,
      exploracion_otros: null
    },
    validaciones: [
      { nombre: 'Exploración Física - Piel', campo: 'exploracion_piel', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Cabeza y cuello', campo: 'exploracion_cabeza', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Tórax', campo: 'exploracion_torax', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Abdomen', campo: 'exploracion_abdomen', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Columna', campo: 'exploracion_columna', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Extremidades Superiores', campo: 'exploracion_extremidades_superiores', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Extremidades Inferiores', campo: 'exploracion_extremidades_inferiores', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Genitales', campo: 'exploracion_genitales', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Sistema Nervioso', campo: 'exploracion_sistema_nervioso', tipo: 'boolean', nota: true, valor_nota: false },
    ]
  }),
  methods: {
    obtenerDatos(){
      var valido = true;
      for (let i = 0; i < this.validaciones.length; i++) {
        if(this.editedItem[this.validaciones[i].campo] == null || (this.validaciones[i].tipo == 'string' && this.editedItem[this.validaciones[i].campo].length < 0)){
          valido = false;
          this.$refs.alert.setMessage("", `El Campo ${this.validaciones[i].nombre} es Obligatorio`);
          this.$refs.alert.open();
          break;
        }

        if(this.validaciones[i].tipo == 'boolean' && this.validaciones[i].nota == true && this.editedItem[this.validaciones[i].campo] == this.validaciones[i].valor_nota && ( this.editedItem[this.validaciones[i].campo+'_nota'] == null ||  this.editedItem[this.validaciones[i].campo+'_nota'].length < 0    )){
          valido = false;
          this.$refs.alert.setMessage("", `La Nota del Campo ${this.validaciones[i].nombre} es Obligatoria`);
          this.$refs.alert.open();
          break;
        }
      }

      return (valido) ? this.editedItem : null;
    },
    restablecer(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
    }
  }
}
</script>