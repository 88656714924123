import { render, staticRenderFns } from "./Odontograma.vue?vue&type=template&id=1c6f0729&scoped=true"
import script from "./Odontograma.vue?vue&type=script&lang=js"
export * from "./Odontograma.vue?vue&type=script&lang=js"
import style0 from "./Odontograma.vue?vue&type=style&index=0&id=1c6f0729&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c6f0729",
  null
  
)

export default component.exports