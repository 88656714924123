<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0 ma-0">
      <v-tabs
        v-model="tab"
        centered
        grow
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
           href="#vehiculos"
          @click="actualizarItems('vehiculos')"
        >
          Vehículos
        </v-tab>

        <v-tab
           href="#mantenimiento_catalogos"
          @click="actualizarItems('mantenimiento_catalogos')"
        >
          Catálogos
        </v-tab>
      </v-tabs>
          
      <v-tabs-items v-model="tab">
        <v-tab-item value="vehiculos">
          <Vehiculos
            ref="vehiculos"
          ></Vehiculos>
        </v-tab-item>

        <v-tab-item value="mantenimiento_catalogos">
          <Catalogos
            ref="mantenimiento_catalogos"
          ></Catalogos>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Catalogos from './Mantenimientos/Catalogos.vue'
import Vehiculos from './Mantenimientos/Vehiculos.vue'
//import { mapState } from 'vuex'

export default {
  computed: {
    //...mapState(['ids_vistas'])
  },
  components:{
    Catalogos,
    Vehiculos
  },
  data: () => ({
    tab: 'vehiculos'
  }),
  methods: {
    /*
    mostrarVista(id_vista){
			if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
			return (this.ids_vistas.indexOf(id_vista) >= 0);
		},
    */
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    }
  }
}
</script>