<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogDocumentReference"
      persistent
      max-width="75%"
    >
      <v-card
      >
        <v-card-title>
          Documentos de Referencia - [{{diagnosticoMedico.codigo}}] - {{diagnosticoMedico.diagnostico}}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="url"
                :items="diagnosticoMedico.documentos_referencias_bibliograficas"
                outlined
                dense
                hide-details
                label="Documento de Referencia Médica"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12"
              v-if="url != null"
              
            >
              {{url}}

              <PDFViewer
                :controls="controls"
                :source="`/api/ConsultasMedicas/DocumentoReferencia/${url}?jwt=${this.token}#toolbar=0&navpanes=0&scrollbar=0`"
                style="height: 100vh; width: 100%"
              />

            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="cerrarDocumentosReferencia"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogComplete"
      max-width="50%"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon
            size="300"
            color="green"
          >
            mdi-check-circle
          </v-icon>
          <div class="text-h4">
            Consulta Medica Guardada Correctamente
          </div>
          <br>
          <v-btn
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/RecetaMedica/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Receta Médica
          </v-btn>
          <v-btn
            v-if="editedItem.referencia_medica == true"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/ReferenciaMedica/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Referencia Médica
          </v-btn>
          <v-btn
            v-if="editedItem.id_formulario == 4"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/NotaEvolucion/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Nota Médica de Evolución
          </v-btn>
          <v-btn
            v-if="editedItem.id_formulario == 1"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/NotaPrevencion/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Historia Clínica Médica de Prevención
          </v-btn>
          <v-btn
            v-if="editedItem.id_formulario == 2"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/HistoriaEmbarazada/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Historia Clínica Médica para Embarazada
          </v-btn>
          <v-btn
            v-if="editedItem.id_formulario == 3"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/HistoriaPediatrica/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Historia Clínica Médica Pediátrica
          </v-btn>
          <v-btn
            v-if="response_consulta.consentimiento_estudios"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/ConsentimientoEstudios/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Autorización Realización de Estudios
          </v-btn>
          <v-btn
            v-if="response_consulta.consentimiento_cancer"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/ConsentimientoCancer/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Autorización Detección de Cáncer de Mama
          </v-btn>
          <v-btn
            v-if="response_consulta.consentimiento_covid"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasMedicas/ConsentimientoCovid/${consulta_medica.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Autorización Prueba Rápida de SARS-COV-2
          </v-btn>
          <br>          
          <v-btn
            block
            color="green"
            class="white--text"
            @click="cerrarDialogo"
          >
            Aceptar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <consentimientos
      ref="consentimientos"
      @change="obtenerConsentimientos"
    ></consentimientos>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
            icon
            dark
            @click="cancelarInicioConsulta"
          >
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Consulta Médica</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card
      >
        
        <referencia-medica
          ref="referenciamedica"
        >
        </referencia-medica>
        <br>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-row class="ma-0 pa-0 sticky-content">
                <v-col cols="12" class="ma-0 pa-0">
                  <PacienteDatos
                    :id_paciente_servicio="consulta_medica.id_paciente_servicio"
                    ref="paciente_datos"
                    @change="actualizarDatos"
                  ></PacienteDatos>
                </v-col>
                <v-col cols="12"
                  v-if="consulta_medica.num_consultas_medicas > 1"
                >
                  <v-btn
                    block
                    text
                    color="primary"
                    @click="abrirHistorial"
                  >
                    <v-icon>
                      mdi-book-open-blank-variant-outline
                    </v-icon>
                    Mostrar Historia Clinica
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9">
              <v-stepper v-model="tab">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="tab > 1"
                    step="1"    
                  >
                    Primera Consulta
                  </v-stepper-step>
                  
                  <v-divider></v-divider>
                  
                  <v-stepper-step
                    :complete="tab > 2"
                    step="2"
                  >
                    Historia Clinica Medica
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="tab > 3"
                    step="3"
                  >
                    Servicios a Realizar
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="tab > 4"
                    step="4"
                  >
                    Diagnostico
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <br>
                    <v-row>
                      <v-col cols="6"
                        v-if="consulta_medica.edad > 5"
                      >
                        <v-autocomplete-add
                          v-model="editedItem.id_ocupacion"
                          label="Ocupación"
                          item-value="id_ocupacion"
                          item-text="nombre"
                          url-get="/Catalogos/ListarOcupaciones"
                          url-save="/Catalogos/CrearOcupacion"
                        >
                        </v-autocomplete-add>
                      </v-col>
                      <v-col cols="6"
                        v-if="consulta_medica.edad > 5"
                      >
                        <v-autocomplete-add
                          v-model="editedItem.id_estado_civil"
                          label="Estado Civil"
                          item-value="id_estado_civil"
                          item-text="nombre"
                          url-get="/Catalogos/ListarEstadosCiviles"
                          url-save="/Catalogos/CrearEstadoCivil"
                        >
                        </v-autocomplete-add>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete-add
                          v-model="editedItem.id_grupo_etnico"
                          :label="`Grupo Etnico ${consulta_medica.edad > 5 ? '' : 'del Padre o Tutor'}`"
                          item-value="id_grupo_etnico"
                          item-text="nombre"
                          url-get="/Catalogos/ListarGruposEtnicos"
                          url-save="/Catalogos/CrearGrupoEtnico"
                        >
                        </v-autocomplete-add>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete-add
                          v-model="editedItem.id_religion"
                          :label="`Religión ${consulta_medica.edad > 5 ? '' : 'del Padre o Tutor'}`"
                          item-value="id_religion"
                          item-text="nombre"
                          url-get="/Catalogos/ListarReligiones"
                          url-save="/Catalogos/CrearReligion"
                        >
                        </v-autocomplete-add>
                      </v-col>
                      <!--
                      <v-col cols="4">
                        <v-text-field
                          v-model="editedItem.direccion"
                          outlined
                          label="Dirección [Opcional]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="editedItem.cp"
                          outlined
                          label="CP [Opcional]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="editedItem.seguro_popular"
                          :items="respuestas"
                          item-text="texto"
                          item-value="valor"
                          label="Copia Póliza Seguro Popular "
                          outlined
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="editedItem.num_poliza"
                          outlined
                          label="No. Póliza Seguro Popular [Opcional]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="editedItem.telefono"
                          outlined
                          label="Celular o teléfono de contacto [Opcional]"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="editedItem.email"
                          outlined
                          label="Correo electrónico [Opcional]"
                        >
                        </v-text-field>
                      </v-col>
                      -->
                    </v-row>
                    <br>
                    <v-btn
                      color="primary"
                      @click="validarPrimeraConsulta"
                    >
                      Siguiente
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <menores-5-anhos-complemento
                      v-if="editedItem.id_formulario == 3"
                      ref="menores5anhoscomplemento"
                    >

                    </menores-5-anhos-complemento>
                    <br>
                    <!--
                    <v-row>
                      
                    </v-row>
                    -->
                    <br>
                    <prevencion
                      v-if="editedItem.id_formulario == 1"
                      :paciente-genero="consulta_medica.paciente.genero" 
                      ref="prevencion"
                    >
                    </prevencion>
                    <embarazadas
                      v-if="editedItem.id_formulario == 2"
                      ref="embarazadas"
                    >
                    </embarazadas>
                    <menores-5-anhos
                      v-if="editedItem.id_formulario == 3"
                      ref="menores5anhos"
                    >
                    </menores-5-anhos>
                    <seguimiento
                      v-if="editedItem.id_formulario == 4"
                      ref="seguimiento"
                    >
                    </seguimiento>
                    <br>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.padecimiento_actual"
                          label="Padecimiento Actual"
                          auto-grow
                          outlined
                          rows="2"
                          hide-details
                          dense
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.pruebas_laboratorio_gabinete"
                          label="Pruebas de Laboratorio y Gabinete"
                          auto-grow
                          outlined
                          rows="2"
                          hide-details
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <br>
                    <v-btn
                      v-if="consulta_medica.num_consultas_medicas == 1"
                      text
                      @click="tab = 1"
                    >
                      Anterior
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="validarFormulario"
                    >
                      Siguiente
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <div class="text-h5">
                      Servicios a Realizar
                    </div>
                    <v-list shaped>
                      <v-list-item-group
                        v-model="editedItem.servicios"
                        multiple
                      >
                        <v-list-item
                          v-for="(servicio, idx) in servicios"
                          :key="'servicio-'+idx"
                          :value="servicio.id_servicio"
                          active-class="teal--text text--darken-1"
                          :disabled="servicio.exclusivo_mujeres == true && consulta_medica.paciente.genero == true"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="teal darken-1"
                                :disabled="
                                  (servicio.exclusivo_mujeres == true && consulta_medica.paciente.genero == true) ||
                                  (servicio.exclusivo_hombres == true && consulta_medica.paciente.genero == false)
                                "
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title >
                                <v-row class="ma-0 pa-1">
                                  <v-col
                                    :cols="otro_ultrasonido != servicio.id_servicio ? 12 : 3"
                                    class="align-self-center ma-0 pa-0"
                                  >
                                    {{servicio.nombre}}
                                    <template v-if="servicio.exclusivo_mujeres">
                                      (Exclusivo de Mujeres)
                                    </template>
                                    <template v-if="servicio.exclusivo_hombres">
                                      (Exclusivo de Hombres)
                                    </template>
                                  </v-col>
                                  <v-col
                                    v-if="otro_ultrasonido == servicio.id_servicio"
                                    cols="9"
                                    class="ma-0 pa-0"
                                  >
                                    <v-text-field
                                      v-model="editedItem.otro_ultrasonido"
                                      outlined
                                      label="Especificar"
                                      dense
                                      hide-details
                                      :disabled="!active"
                                      @click.stop
                                  >
                                  </v-text-field>
                                  </v-col>
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-btn
                      text
                      @click="tab = 2"
                    >
                      Anterior
                    </v-btn>

                    <v-btn
                      color="primary"
                      @click="validarServicios"
                    >
                      Siguiente
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-autocomplete
                          v-if="diagnosticosMedicosFocus"
                          label="Impresión Diagnóstica"
                          v-model="editedItem.ids_diagnosticos_medicos"
                          outlined
                          :items="diagnosticoMedicos"
                          item-value="id_diagnostico_medico"
                          item-text="diagnostico"
                          multiple
                          hide-details
                          chips
                          deletable-chips
                      >
                          <template v-slot:selection="{ item, attrs }">
                              <v-chip
                                v-bind="attrs"
                                :input-value="editedItem.ids_diagnosticos_medicos.includes(item.id_diagnostico_medico)"
                                close
                                close-icon="mdi-close-circle"
                                @click.native="abrirDocumentosReferencia(item)"
                                @click:close="borrarChip(item)"
                              >
                                <v-icon
                                  v-if="item.ids_documentos_referencias_bibliograficas.length > 0"
                                >
                                  mdi-file-document-outline
                                </v-icon>
                                {{ item.diagnostico }}
                              </v-chip>
                          </template>
                      </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.detalle_impresion_diagnostica"
                          outlined
                          dense
                          hide-details
                          rows="1"
                          auto-grow
                          label="Detalle de Impresión Diagnóstica"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-data-table
                          :headers="headers"
                          :items="editedItem.tratamientos"
                          hide-default-footer
                          hide-default-header
                        >
                          <template v-slot:[`item.tratamiento`]="{ item }">
                            <v-row class="ma-0 pa-0">
                              <v-col cols="8" class="ma-0 pa-2">
                                <v-autocomplete
                                  v-model="item.id_medicamento"
                                  outlined
                                  label="Medicamento"
                                  hide-details
                                  :items="medicamentos"
                                  :item-text="medicamentoNombre"
                                  item-value="id_medicamento"
                                  dense
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="4" class="ma-0 pa-2">
                                <v-number-field
                                  v-model="item.cantidad"
                                  label="Cantidad"
                                >
                                </v-number-field>
                              </v-col>
                              <v-col cols="12" class="ma-0 pa-2">
                                <v-textarea
                                  v-model="item.indicaciones"
                                  outlined
                                  label="Indicaciones"
                                  rows="1"
                                  auto-grow
                                  dense
                                  hide-details
                                >
                                </v-textarea>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip-icon
                              icon="mdi-trash-can-outline"
                              text="Eliminar"
                              @click="eliminarMedicamento(item)"
                            ></v-tooltip-icon>
                          </template> 
                        </v-data-table>
                        <v-btn
                          color="primary"
                          @click="agregarMedicamento"
                          text
                        >
                          Agregar Medicamento
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.recomendaciones_medicas"
                          outlined
                          dense
                          hide-details
                          rows="1"
                          auto-grow
                          label="Recomendaciones Medicas"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="3">
                        <v-autocomplete
                          v-model="editedItem.id_grupo_edad"
                          label="Grupo Edad"
                          outlined
                          hide-details
                          dense
                          :items="gruposEdadFiltrados"
                          item-text="nombre"
                          item-value="id_grupo_edad"
                          @change="getAccionesPrevencion"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="9">
                        <v-autocomplete
                          v-model="editedItem.ids_acciones_prevencion"
                          label="Acciones de Prevención Realizadas"
                          outlined
                          hide-details
                          dense
                          :items="accionesPrevencion"
                          item-text="nombre"
                          item-value="id_accion_prevencion"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.otra_accion_prevencion"
                          outlined
                          dense
                          hide-details
                          rows="1"
                          auto-grow
                          label="Otras Acciones de Prevención Realizadas"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.pronostico"
                          label="Pronóstico"
                          auto-grow
                          outlined
                          rows="1"
                          hide-details
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.noms"
                          label="NOMS"
                          outlined
                          hide-details
                          dense
                          :items="obtenerNOMS()"
                          item-value="id_nom"
                          item-text="nom"
                          multiple
                          chips
                          deletable-chips
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="6">
                        <consulta-checkbox
                          v-model="editedItem.consulta_dental"
                          :enable="!consulta_medica.consulta_dental"
                          consulta="Consulta Dental"
                          :outlined="true"
                          :dense="true"
                          :hide-details="true"
                        >
                        </consulta-checkbox>
                        <consulta-checkbox
                          v-model="editedItem.nutricion"
                          :enable="!consulta_medica.nutricion"
                          consulta="Consulta Nutricional"
                          :outlined="true"
                          :dense="true"
                          :hide-details="true"
                        >
                        </consulta-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-row class="ma-0 pa-0">
                          <v-col col cols="6" class="ma-0 pa-0">
                            <v-checkbox
                              v-model="editedItem.referencia_medica"
                              label="Agregar Referencia Medica"
                            >

                            </v-checkbox>
                          </v-col>
                          <v-col col cols="6" class="ma-0 pa-0 align-self-center text-align-center">
                            <v-btn
                              :disabled="!editedItem.referencia_medica"
                              color="primary"
                              @click="$refs.referenciamedica.iniciarReferenciaMedica()"
                            >
                              Información de <br> Referencia Medica
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3"></v-col>
                      <v-col cols="6">
                        <v-card>
                          <v-container class="text-center font-weight-bold">
                            <TopazSignature
                              v-if="mostrar_firma"
                              ref="paciente"
                              id="paciente"
                            ></TopazSignature>
                            <div class="text-center text-subtitle-2">
                              {{consulta_medica.paciente.nombre}} {{consulta_medica.paciente.apellido_paterno}} {{consulta_medica.paciente.apellido_materno}}
                            </div>
                          </v-container>
                        </v-card>
                      </v-col>
                      <v-col cols="3"></v-col>
                    </v-row>
                    <v-btn
                      text
                      @click="tab = 3; mostrar_firma = false;"
                    >
                      Anterior
                    </v-btn>
                    <v-btn
                      @click="terminarConsulta()"
                      color="primary"
                      :loading="saving"
                      :disabled="saving"
                    >
                      Finalizar Consulta
                    </v-btn>
                    <br>
                    <br>
                    <br>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogHistorial"
      persistent

    >
      <v-card>
        <v-card-title>
          Historial Clinico
        </v-card-title>
        <v-card-text>
          <historial
            ref="historial"
          >
          </historial>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green"
            @click="cerrarHistorial"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { mapState } from 'vuex'

import axios from 'axios'

import Prevencion from './Formularios/Prevencion.vue'
import Embarazadas from './Formularios/Embarazadas.vue'
import Menores5Anhos from './Formularios/Menores5Anhos.vue'
import Menores5AnhosComplemento from './Formularios/Menores5AnhosComplemento.vue'
import Seguimiento from './Formularios/Seguimiento.vue'
import ReferenciaMedica from './ReferenciaMedica.vue';
import VAutocompleteAdd from '@/components/VAutocompleteAdd.vue'
import VNumberField from '@/components/VNumberField.vue'
import PacienteDatos from '@/components/DashViews/Comunes/PacienteDatosVertical.vue'
import Historial from '@/components/DashViews/Comunes/Historial.vue'
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
import Consentimientos from './Consentimientos.vue'
//import vueSignature from "vue-signature";
import TopazSignature from '@/components/TopazSignature.vue';
import ConsultaCheckbox from '@/components/ConsultaCheckbox.vue'

export default {
  components: {
    Prevencion,
    Embarazadas,
    Menores5Anhos,
    Menores5AnhosComplemento,
    Seguimiento,
    ReferenciaMedica,
    VAutocompleteAdd,
    PacienteDatos,
    VNumberField,
    Historial,
    PDFViewer,
    Consentimientos,
    //vueSignature
    TopazSignature,
    ConsultaCheckbox
  },
  data: () => ({
    tab: 1,
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    dialog: false,
    dialogReferenciaMedica: false,
    otro_ultrasonido: "a6f012b7-946d-441e-8dc1-3ab8027ee34e",
    consulta_medica: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      paciente: {
          id_paciente: null,
          id_municipio: null,
          municipio: "",
          id_estado: null,
          estado: "",
          id_localidad_atencion: null,
          localidad_atencion: "",
          folio: "",
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          fecha_nacimiento: "",
          edad: "",
          edad_anhios: 0,
          genero: true,
          curp: "",
          seguro_popular: false,
          num_poliza: "",
          telefono: "",
          email: ""
      },
      num_consultas_medicas: 0,
      consulta_dental: false,
      nutricion: false
    },
    defaultConsultaMedica: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      paciente: {
          id_paciente: null,
          id_municipio: null,
          municipio: "",
          id_estado: null,
          estado: "",
          id_localidad_atencion: null,
          localidad_atencion: "",
          folio: "",
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          fecha_nacimiento: "",
          edad: "",
          edad_anhios: 0,
          genero: true,
          curp: "",
          seguro_popular: false,
          num_poliza: "",
          telefono: "",
          email: ""
      },
      num_consultas_medicas: 0,
      consulta_dental: false,
      nutricion: false
    },
    editedItem: {
      id_formulario: -1,
      formulario: null,
      servicios:[],
      padecimiento_actual: null,
      ids_diagnosticos_medicos: [],
      detalle_impresion_diagnostica: null,
      tratamientos: [
        {
          id_medicamento: null,
          cantidad: 1,
          indicaciones: null
        }
      ],
      recomendaciones_medicas: null,
      id_ocupacion: null,
      id_estado_civil: null,
      id_grupo_etnico: null,
      id_religion: null,
      direccion: null,
      cp: null,
      seguro_popular: null,
      num_poliza: null,
      telefono: null,
      email: null,
      otro_ultrasonido: null,
      /*
      firma_paciente: null,
      nombre_testigo1: null,
      firma_testigo1: null,
      nombre_testigo2: null,
      firma_testigo2: null,
      */
      consentimientos: {
        consentimiento_estudios: false,
        consentimiento_cancer: false,
        consentimiento_covid: false,
        identificado_con: null,
        curp: null,
        firma1: null,
        firma2: null,
        firma3: null,
      },
      pruebas_laboratorio_gabinete: null,
      pronostico: null,
      nutricion: false,
      consulta_dental: false,
      referencia_medica: false,
      id_grupo_edad: null,
      ids_acciones_prevencion: [],
      otra_accion_prevencion: null,
      formulario_referencia_medica: null,
      noms: [],
    },
    defaultEditedItem: {
      id_formulario: -1,
      formulario: null,
      servicios:[],
      padecimiento_actual: null,
      ids_diagnosticos_medicos: [],
      detalle_impresion_diagnostica: null,
      tratamientos: [
        {
          id_medicamento: null,
          cantidad: 1,
          indicaciones: null
        }
      ],
      recomendaciones_medicas: null,
      id_ocupacion: null,
      id_estado_civil: null,
      id_grupo_etnico: null,
      id_religion: null,
      direccion: null,
      cp: null,
      seguro_popular: null,
      num_poliza: null,
      telefono: null,
      email: null,
      otro_ultrasonido: null,
      consentimientos: {
        consentimiento_estudios: false,
        consentimiento_cancer: false,
        consentimiento_covid: false,
        identificado_con: null,
        curp: null,
        firma1: null,
        firma2: null,
        firma3: null,
      },
      /*
      firma_paciente: null,
      nombre_testigo1: null,
      firma_testigo1: null,
      nombre_testigo2: null,
      firma_testigo2: null,
      */
      pruebas_laboratorio_gabinete: null,
      pronostico: null,
      nutricion: false,
      consulta_dental: false,
      referencia_medica: false,
      id_grupo_edad: null,
      ids_acciones_prevencion: [],
      otra_accion_prevencion: null,
      formulario_referencia_medica: null,
      noms: []
    },
    defaultTratamiento: {
      id_medicamento: null,
      cantidad: 1,
      indicaciones: null
    },
    headers:[
      {
        text: "Tratamiento",
        value: "tratamiento",
        align: "center",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%",
        sortable: false
      }
    ],
    diagnosticoMedicos: [],
    loadingDiagnosticosMedicos: false,
    medicamentos: [],
    loadingMedicamentos: false,
    servicios: [],
    loadingServicios: false,
    formularios: [
      {
        value: 1,
        text: "Prevención"
      },
      {
        value: 2,
        text: "Embarazadas"
      },
      {
        value: 3,
        text: "Menores 5 Años"
      },
      {
        value: 4,
        text: "Seguimiento"
      }
    ],
    respuestas: [
      {
        valor: 2,
        texto: "Si"
      },
      {
        valor: 1,
        texto: "No"
      }
    ],
    saving: false,
    dialogComplete: false,
    gruposEdad: [],
    loadingGruposEdad: false,
    accionesPrevencion: [],
    loadingAccionesPrevencion: false,
    //dialogConsentimiento: false,
    dialogHistorial: false,
    loadingNoms: false,
    noms: [],
    noms_servicios: [
      {
        id_formulario: 1,
        noms: ['402106b1-4cf1-44f4-8f41-63927b980e19', '754f7434-e2f5-4c86-a719-16d71b270b6a']
      },
      {
        id_formulario: 2,
        noms: ['68e0588b-bd02-44ee-bf4f-f0a73ba78dba']
      },
      {
        id_formulario: 3,
        noms: ['60c80061-3372-411a-b5f4-2c03dbf4c0d2', 'a7b93513-85a0-4728-952f-c0bc73d4a313', '18611140-e8ec-49fe-85cb-ed7bb0fd0b56']
      },
      {
        id_formulario: 4,
        noms: ['a3d9fb20-5fa8-42ef-90f1-66e04f8bf2ce']
      }
    ],
    dialogDocumentReference: false,
    diagnosticoMedico: {
      cie10: "",
      codigo: "",
      diagnostico: "",
      documentos_referencias_bibliograficas: [],
      id_diagnostico_medico: "",
      ids_documentos_referencias_bibliograficas: [],
      referencia_bibliografica: ""
    },
    defaultDiagnosticoMedico: {
      cie10: "",
      codigo: "",
      diagnostico: "",
      documentos_referencias_bibliograficas: [],
      id_diagnostico_medico: "",
      ids_documentos_referencias_bibliograficas: [],
      referencia_bibliografica: ""
    },
    diagnosticosMedicosFocus: true,
    url: null,
    controls: [
      'print',
      'rotate',
      'zoom',
      'catalog',
      'switchPage',
    ],
    response_consulta: {
      consentimiento_estudios: false,
      consentimiento_cancer: false,
      consentimiento_covid: false
    },
    defaultResponseConsulta: {
      consentimiento_estudios: false,
      consentimiento_cancer: false,
      consentimiento_covid: false
    },
    mostrar_firma: false
  }),
  created() {
    this.getServicios();
    this.getMedicamentos();
    this.getDiagnosticosMedicos();
    this.getGruposEdad();
    this.getNOMS();
  },
  computed: {
    ...mapState(['token', 'extemporaneo']),
    estudiosRealizar(){
      if(this.editedItem.servicios == null || this.editedItem.servicios.length <= 0) return [];

      return this.servicios.filter(s => this.editedItem.servicios.some(x => x == s.id_servicio));
    },
    gruposEdadFiltrados(){
      return this.gruposEdad.filter(ge => 
        (ge.genero == null || ge.genero == this.consulta_medica.paciente.genero) &&
        ((ge.edad_desde == null && ge.edad_hasta == null) || (ge.edad_desde <= this.consulta_medica.paciente.edad_anhios && ge.edad_hasta >= this.consulta_medica.paciente.edad_anhios))
      );
    }
  },
  methods: {
    medicamentoNombre: medicamento => `[${medicamento.codigo}] - ${medicamento.farmaco} ${medicamento.concentracion} - ${medicamento.stock} PZA - ${medicamento.presentacion}`,
    getServicios(){
      this.loadingServicios = true;
      axios
        .get('/Catalogos/ListarServicios?consulta_medica=true')
        .then(response => {
          this.servicios = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingServicios = false;
        })
    },
    getMedicamentos(){
      this.loadingMedicamentos = true;
      axios
        .get('/Catalogos/ListarMedicamentos?id_servicio=35edc2dc-6d5d-4553-857d-3287160d325f&mostrar_stock=true')
        .then(response => {
          this.medicamentos = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingMedicamentos = false;
        })
    },
    getNOMS(){
      this.loadingNoms = true;
      axios
        .get('/Catalogos/ListarNOMS/35edc2dc-6d5d-4553-857d-3287160d325f')
        .then(response => {
          this.noms = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingNoms = false;
        })
    },
    getDiagnosticosMedicos(){
      this.loadingDiagnosticosMedicos = true;
      axios
        .get('/Catalogos/ListarDiagnosticosMedicos')
        .then(response => {
          this.diagnosticoMedicos = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingDiagnosticosMedicos = false;
        })
    },
    getGruposEdad(){
      this.loadingGruposEdad = true;
      axios
        .get('/Catalogos/ListarGruposEdad')
        .then(response => {
          this.gruposEdad = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingGruposEdad = false;
        })
    },
    getAccionesPrevencion() {
      this.loadingAccionesPrevencion = true;
      const maxRetries = 5;
      const retryDelay = 500; // 500 milliseconds
      let attempt = 0;
      this.fetchAccionesPrevencion(maxRetries, retryDelay, attempt);
    },
    fetchAccionesPrevencion(maxRetries, retryDelay, attempt) {
      axios
        .get(`/Catalogos/ListarAccionesPrevencion/${this.editedItem.id_grupo_edad}`)
        .then(response => {
          this.editedItem.ids_acciones_prevencion = [];
          this.accionesPrevencion = response.data;
          this.loadingAccionesPrevencion = false;
        })
        .catch(error => {
          attempt++;
          if (attempt < maxRetries) {
            //console.log(`Attempt ${attempt} failed. Retrying in ${retryDelay}ms...`);
            setTimeout(() => this.fetchAccionesPrevencion(maxRetries, retryDelay, attempt), retryDelay);
          } else {
            console.log(`All ${maxRetries} attempts failed.`, error);
            this.loadingAccionesPrevencion = false;
          }
        });
    },
    iniciarConsulta(id_paciente_servicio){
      axios
      .post(`/ConsultasMedicas/IniciarConsulta/${id_paciente_servicio}`)
      .then(response => {
        this.consulta_medica = response.data;

        if(this.consulta_medica.edad <= 5 && this.consulta_medica.num_consultas_medicas <= 1) {
          this.editedItem.id_formulario = 3;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 3);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }
        else if(this.consulta_medica.paciente.genero == false && this.consulta_medica.embarazada == true) {
          this.editedItem.id_formulario = 2;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 2);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }
        else if(this.consulta_medica.num_consultas_medicas <= 1) {
          this.editedItem.id_formulario = 1;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 1);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }
        else {
          this.editedItem.id_formulario = 4;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 4);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }

        if(this.consulta_medica.num_consultas_medicas == 1) this.tab = 1;
        else this.tab = 2;

        this.dialog = true;

        setTimeout(() => {
          this.$refs.paciente_datos.actualizar(id_paciente_servicio);
        },250);

        
      })
      .catch(error => {
        console.log(error)
      })
    },

    isNotPositiveNumber(number){
      return !isFinite(parseFloat(number)) || isNaN(parseFloat(number)) || parseFloat(number) <= 0
    },
    terminarConsulta(){
      if(this.editedItem.ids_diagnosticos_medicos == null || this.editedItem.ids_diagnosticos_medicos.length <= 0){
        this.$refs.alert.setMessage("", "Falta Indicar al menos una Impresión Diagnóstica");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.tratamientos == null || this.editedItem.tratamientos.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar al menos un Tratamiento");
        this.$refs.alert.open();
        return;
      }

      for (var i = 0; i < this.editedItem.tratamientos.length; i++) {
        if(this.editedItem.tratamientos[i].id_medicamento == null){
          this.$refs.alert.setMessage("", `Falta Ingresar el Medicamento en el Renglón #${i+1}`);
          this.$refs.alert.open();
          return;
        }

        if(this.editedItem.tratamientos[i].cantidad == null || this.isNotPositiveNumber(this.editedItem.tratamientos[i].cantidad)){
          this.$refs.alert.setMessage("", `Falta Ingresar la Cantidad en el Renglón #${i+1}`);
          this.$refs.alert.open();
          return;
        }

        if(this.editedItem.tratamientos[i].indicaciones == null || this.editedItem.tratamientos[i].indicaciones.length <= 0){
          this.$refs.alert.setMessage("", `Falta Ingresar las Indicaciones en el Renglón #${i+1}`);
          this.$refs.alert.open();
          return;
        }
      }

      if(this.editedItem.recomendaciones_medicas == null || this.editedItem.recomendaciones_medicas.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar Recomendaciones Medicas");
        this.$refs.alert.open();
        return;
      }
      
      if(this.editedItem.id_grupo_edad == null){
        this.$refs.alert.setMessage("", "Falta Ingresar el Grupo de Edad");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.ids_acciones_prevencion == null || this.editedItem.ids_acciones_prevencion.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar al menos una Acción de Prevención");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.pronostico == null || this.editedItem.pronostico.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar un Pronóstico");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.noms.length <= 0){
        this.$refs.alert.setMessage("", "Falta Indicar las NOMS Aplicadas al Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_formulario == 2 && this.editedItem.formulario.antecedentes_perinatales == false && this.editedItem.referencia_medica != true ){
        if(this.editedItem.formulario_referecia_medica == null){
          this.$refs.alert.setMessage("", "Es Obligatorio llenar la Referencia Medica al ser una Mujer Embarazada sin Antecedentes Perinatales");
          this.$refs.alert.open();
          return;
        } 
      }

      if(this.editedItem.referencia_medica == true){
        this.editedItem.formulario_referencia_medica = this.$refs.referenciamedica.obtenerDatos();
        if(this.editedItem.formulario_referencia_medica == null){
          this.$refs.alert.setMessage("", "Falta llenar el Formulario de Referencia Medica");
          this.$refs.alert.open();
          return;
        }        
      }
      
      if(this.$refs.paciente.isEmpty() && !this.extemporaneo){
        this.$refs.alert.setMessage("", "Falta la Firma del Paciente");
        this.$refs.alert.open();
        return;
      }

      this.saving = true;

      axios
      .post(`/ConsultasMedicas/FinalizarConsulta`,{
        id_paciente_servicio: this.consulta_medica.id_paciente_servicio,
        id_ocupacion: this.editedItem.id_ocupacion,
        id_estado_civil: this.editedItem.id_estado_civil,
        id_grupo_etnico: this.editedItem.id_grupo_etnico,
        id_religion: this.editedItem.id_religion,
        direccion: this.editedItem.direccion,
        cp: this.editedItem.cp,
        seguro_popular: this.editedItem.seguro_popular,
        num_poliza: this.editedItem.num_poliza,
        telefono: this.editedItem.telefono,
        email: this.editedItem.email,
        id_formulario: this.editedItem.id_formulario,
        formulario: JSON.stringify(this.editedItem.formulario),
        servicios: this.editedItem.servicios,
        padecimiento_actual: this.editedItem.padecimiento_actual,
        pruebas_laboratorio_gabinete: this.editedItem.pruebas_laboratorio_gabinete,
        ids_diagnosticos_medicos: this.editedItem.ids_diagnosticos_medicos,
        detalle_impresion_diagnostica: this.editedItem.detalle_impresion_diagnostica,
        tratamientos: this.editedItem.tratamientos,
        recomendaciones_medicas: this.editedItem.recomendaciones_medicas,
        pronostico: this.editedItem.pronostico,
        consulta_dental: this.editedItem.consulta_dental,
        nutricion: this.editedItem.nutricion,
        referencia_medica: this.editedItem.referencia_medica,
        id_grupo_edad: this.editedItem.id_grupo_edad,
        ids_acciones_prevencion: this.editedItem.ids_acciones_prevencion,
        otra_accion_prevencion: this.editedItem.otra_accion_prevencion,
        formulario_referencia_medica: (this.editedItem.formulario_referencia_medica != null) ? JSON.stringify(this.editedItem.formulario_referencia_medica) : null,
        /*
        firma_paciente: this.editedItem.firma_paciente,
        firma_testigo1: this.editedItem.firma_testigo1,
        firma_testigo2: this.editedItem.firma_testigo2,
        nombre_testigo1: this.editedItem.nombre_testigo1,
        nombre_testigo2: this.editedItem.nombre_testigo2,
        */
        usg_otro: this.editedItem.otro_ultrasonido,
        consentimiento_formulario: JSON.stringify(this.editedItem.consentimientos),
        noms: this.editedItem.noms,
        firma_paciente: (!this.extemporaneo) ? this.$refs.paciente.save() : null
      })
      .then(response => {
        if(this.editedItem.id_formulario == 1) this.$refs.prevencion.restablecer();
        if(this.editedItem.id_formulario == 2) this.$refs.embarazadas.restablecer();
        if(this.editedItem.id_formulario == 3) {
          this.$refs.menores5anhoscomplemento.restablecer();
          this.$refs.menores5anhos.restablecer();
        }
        if(this.editedItem.id_formulario == 4) this.$refs.seguimiento.restablecer();
        //this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
        this.$refs.paciente.clear();
        this.mostrar_firma = false;
        this.editedItem.tratamientos = [];
        this.editedItem.tratamientos.push(Object.assign({}, this.defaultTratamiento));
        if(this.editedItem.referencia_medica == true){
          this.editedItem.formulario_referencia_medica = this.$refs.referenciamedica.restablecer();
        }

        this.response_consulta = Object.assign({}, response.data);
        this.dialogComplete = true;

      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.saving = false;
      })
    },
    cancelarInicioConsulta(){
      axios
      .post(`/ConsultasMedicas/CancelarInicioConsulta/${this.consulta_medica.id_paciente_servicio}`)
      .then(() => {
        if(this.editedItem.id_formulario == 1) this.$refs.prevencion.restablecer();
        if(this.editedItem.id_formulario == 2) this.$refs.embarazadas.restablecer();
        if(this.editedItem.id_formulario == 3) this.$refs.menores5anhos.restablecer();
        if(this.editedItem.id_formulario == 4) this.$refs.seguimiento.restablecer();
        this.consulta_medica = Object.assign({}, this.defaultConsultaMedica);
        this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
        this.editedItem.tratamientos = [];
        this.editedItem.tratamientos.push(Object.assign({}, this.defaultTratamiento));
        this.dialog = false;
        this.tab = 1;
        this.$emit('complete', null);
      })
      .catch(error => {
        console.log(error)
      });
    },
    agregarMedicamento(){
      this.editedItem.tratamientos.push(Object.assign({}, this.defaultTratamiento))
    },
    eliminarMedicamento(item){
      this.editedItem.tratamientos = this.editedItem.tratamientos.filter(x => x != item);
    },
    validarFormulario(){
      if(this.editedItem.id_formulario == 3) {
        if(this.$refs.menores5anhoscomplemento.obtenerDatos() == null) return;
      }

      if(this.editedItem.padecimiento_actual == null || this.editedItem.padecimiento_actual.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Padecimiento Actual");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_formulario == 1) this.editedItem.formulario = this.$refs.prevencion.obtenerDatos();
      if(this.editedItem.id_formulario == 2) this.editedItem.formulario = this.$refs.embarazadas.obtenerDatos();
      if(this.editedItem.id_formulario == 3) {
        var obj1 = this.$refs.menores5anhoscomplemento.obtenerDatos();
        var obj2 = this.$refs.menores5anhos.obtenerDatos();
        if(obj2 == null) return;
        this.editedItem.formulario = Object.assign({}, obj1, obj2);
      }
      if(this.editedItem.id_formulario == 4) this.editedItem.formulario = this.$refs.seguimiento.obtenerDatos();

      if(this.editedItem.formulario == null) return;

      /*
      if(this.editedItem.pruebas_laboratorio_gabinete == null || this.editedItem.pruebas_laboratorio_gabinete.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar las Pruebas de Laboratorio y Gabinete");
        this.$refs.alert.open();
        return;
      }
      */

      this.tab = 3;
    },
    validarServicios(){
      if(this.editedItem.servicios.some(id_servicio => id_servicio == this.otro_ultrasonido)){
        if(this.editedItem.otro_ultrasonido == null || this.editedItem.otro_ultrasonido.length <= 0){
          this.$refs.alert.setMessage("", "Falta Ingresar la Descripción de Otro Ultrasonido");
          this.$refs.alert.open();
          return;
        }
      }

      if(this.servicios.some(s => this.editedItem.servicios.some(x => x == s.id_servicio) && s.consentimiento == true) && (this.editedItem.consentimientos.firma1 == null && this.editedItem.consentimientos.firma2 == null && this.editedItem.consentimientos.firma3 == null)){
        //this.dialogConsentimiento = true;
        this.$refs.consentimientos.mostrar(this.editedItem.servicios, this.consulta_medica);
      }
      else {
        this.tab = 4;
        setTimeout(() => {
          this.mostrar_firma = true;
        },250);
      }
    },
    /*
    validarConsentimiento(){
      if(this.$refs.paciente.isEmpty()){
        this.$refs.alert.setMessage("", "Falta la Firma del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.$refs.testigo1.isEmpty()){
        this.$refs.alert.setMessage("", "Falta la Firma del Testigo #1");
        this.$refs.alert.open();
        return;
      }
      
      if(this.editedItem.nombre_testigo1 == null || this.editedItem.nombre_testigo1.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Nombre del Testigo #1");
        this.$refs.alert.open();
        return;
      }

      if(this.$refs.testigo2.isEmpty()){
        this.$refs.alert.setMessage("", "Falta la Firma del Testigo #2");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.nombre_testigo2 == null || this.editedItem.nombre_testigo2.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Nombre del Testigo #2");
        this.$refs.alert.open();
        return;
      }

      this.editedItem.firma_paciente = this.$refs.paciente.save();
      this.editedItem.firma_testigo1 = this.$refs.testigo1.save();
      this.editedItem.firma_testigo2 = this.$refs.testigo2.save();

      this.tab = 4;
      //this.dialogConsentimiento = false;
    },
    */
    validarPrimeraConsulta(){
      if(this.editedItem.id_ocupacion == null && this.consulta_medica.edad > 5){
        this.$refs.alert.setMessage("", "Falta Ingresar una Ocupación");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_estado_civil == null && this.consulta_medica.edad > 5){
        this.$refs.alert.setMessage("", "Falta Ingresar un Estado Civil");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_grupo_etnico == null){
        this.$refs.alert.setMessage("", "Falta Ingresar un Grupo Etnico");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_religion == null){
        this.$refs.alert.setMessage("", "Falta Ingresar una Religión");
        this.$refs.alert.open();
        return;
      }

      /*
      if(this.editedItem.seguro_popular == null){
        this.$refs.alert.setMessage("", "Falta Indicar si tiene Seguro Popular");
        this.$refs.alert.open();
        return;
      }
      */

      this.tab = 2;
    },
    cerrarDialogo(){
      try {
        this.$refs.historial.limpiarHistorial();
      } catch (error) { 
          console.log(error);
      }

      this.dialogComplete = false;
      this.response_consulta = Object.assign({}, this.defaultResponseConsulta);
      this.consulta_medica = Object.assign({}, this.defaultConsultaMedica);
      this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
      this.dialog = false;
      this.tab = 1;
      this.$emit('complete', null);
    },
    actualizarDatos(paciente){
      //console.log(this.consulta_medica.embarazada);
      //console.log(paciente.embarazada);

      var cambio_modo = this.consulta_medica.embarazada != paciente.embarazada;
      this.consulta_medica.paciente = paciente;
      this.consulta_medica.embarazada = paciente.embarazada;

      if(cambio_modo){
        if(this.editedItem.id_formulario == 1) this.$refs.prevencion.restablecer();
        if(this.editedItem.id_formulario == 2) this.$refs.embarazadas.restablecer();
        if(this.editedItem.id_formulario == 3) {
          this.$refs.menores5anhoscomplemento.restablecer();
          this.$refs.menores5anhos.restablecer();
        }
        if(this.editedItem.id_formulario == 4) this.$refs.seguimiento.restablecer();

        this.consulta_medica.embarazada = paciente.embarazada;

        if(this.consulta_medica.edad <= 5 && this.consulta_medica.num_consultas_medicas <= 1) {
          this.editedItem.id_formulario = 3;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 3);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }
        else if(this.consulta_medica.paciente.genero == false && this.consulta_medica.embarazada == true) {
          this.editedItem.id_formulario = 2;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 2);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }
        else if(this.consulta_medica.num_consultas_medicas <= 1) {
          this.editedItem.id_formulario = 1;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 1);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }
        else {
          this.editedItem.id_formulario = 4;
          let lista_nom = this.noms_servicios.find(x => x.id_formulario == 4);
          if(lista_nom != undefined) this.editedItem.noms = JSON.parse(JSON.stringify(lista_nom.noms));
        }

        if(this.consulta_medica.num_consultas_medicas == 1) this.tab = 1;
        else this.tab = 2;
      }
    },
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    abrirHistorial(){
      this.dialogHistorial = true;
      setTimeout(() => 
      this.$refs.historial.cambiarPaciente(this.consulta_medica.paciente.id_paciente), 100);
    },
    cerrarHistorial(){
      try {
        this.$refs.historial.limpiarHistorial();
      } catch (error) { 
          console.log(error);
      }
      this.dialogHistorial = false;
    },
    obtenerNOMS(){
      var nom_ignorar = this.noms_servicios
        .filter(x => x.id_formulario != this.editedItem.id_formulario)
        .map(x => x.noms)
        .flat(1);

      return this.noms.filter(x => nom_ignorar.indexOf(x.id_nom) == -1);
    },
    abrirDocumentosReferencia(item){
      if(item.ids_documentos_referencias_bibliograficas.length <= 0) return;

      this.diagnosticoMedico = Object.assign({}, item);
      this.diagnosticosMedicosFocus = false;
      this.$nextTick(() => {
        this.diagnosticosMedicosFocus = true;
        this.dialogDocumentReference = true;
      });
      

    },
    cerrarDocumentosReferencia(){
      this.diagnosticoMedico = Object.assign({}, this.defaultConsultaMedica);
      this.dialogDocumentReference = false;
      this.url = null;
    },
    borrarChip(item) {
      const index = this.editedItem.ids_diagnosticos_medicos.indexOf(item.id_diagnostico_medico);
      if (index !== -1) {
          this.editedItem.ids_diagnosticos_medicos.splice(index, 1);
      }
    },
    obtenerConsentimientos(value){
      this.editedItem.consentimientos = value;
      this.tab = 4;
      setTimeout(() => {
        this.mostrar_firma = true;
      },250);
    }
  }
}
</script>

<style scoped>
.sticky-content {
  position: sticky;
  top: 20px; /* Ajusta la posición según sea necesario */
 }
</style>