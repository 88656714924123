<template>
  <v-row justify-sm="center">
    <v-card 
      class="mt-4"
      width="95%"
      fill
    >
      <v-tabs
        background-color="blue darken-2"
        grow
        dark
      >
        <v-tab>
          <v-icon left>
            mdi-cog
          </v-icon>
          Error de Configuración
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <lottie :options="loadingAnimation" :height="250" :width="500" />
                </v-col>
              </v-row>
              <div class="text-body-1 text-justify">
                Para poder hacer uso de Mi UBGA, es necesario que previamente un Supervisor configure la Unidad Médica. En donde este deberá de indicar Programa, Proyecto, Estado, Municipio, Localidad de Atención, entre otros datos. Desafortunadamente, usted no puede realizar esta acción, solicite a un Supervisor que realice la configuración para poder continuar.
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-row>
</template>


<script>
import Lottie from 'vue-lottie';
import animationData from '@/assets/stop.json';

export default {
  components:{
    'lottie': Lottie
  },
  data: () => ({
    loadingAnimation: {animationData: animationData},
  })
}
</script>