<template>
  <div>
    <html
      sigplusextliteextension-installed="true"
      sigwebext-installed="true"
    ></html>
    <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="400"
      elevation="0"

    >
      <template slot="progress">
        <v-progress-linear
          color="primary"
          height="5"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-img
        v-if="modo == 1"
        height="200"
        :src="image"
      ></v-img>
      <vueSignature
        v-if="modo == 2"
        ref="firma"
        :sigOption="option"
        :w="'859px'"
        :h="'220px'"
        :disabled="modo == 1"
      ></vueSignature>

      <v-card-actions>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" class="ma-0 pa-0">
            <v-btn
              color="green"
              block
              text
              @click="StartSign"
              :loading="loading"
            >
              Firmar con Pad
            </v-btn>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-btn
              color="blue"
              block
              text
              @click="manualSign"
              :loading="loading"
            >
              Firmar Touch / Mouse
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import vueSignature from "vue-signature";
/* eslint-disable no-undef */
export default {
  components: {
    vueSignature
  },
  data: () => ({
    height_sign: 220,
    width_sign: 859,
    image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAADIAQMAAAD82yWhAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAA5SURBVHic7cuhAQAADAKg+f/Tu8Fgg06uFkVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRNuUBzQUAydNVqx4AAAAASUVORK5CYII=",
    defaultImage: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAADIAQMAAAD82yWhAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAA5SURBVHic7cuhAQAADAKg+f/Tu8Fgg06uFkVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRNuUBzQUAydNVqx4AAAAASUVORK5CYII=",
    loading: false,
    modo: 1,
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    }
  }),
  created() {
    this.start();
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      //this.ShowVersionInfo();
      this.loading = false;
    }, 2000);
  },
  methods: {
    start() {
      try {
        var url = document.documentElement.getAttribute("SigPlusExtLiteWrapperURL");
        var script = document.createElement("script");
        script.onerror = function () {
          alert("Unable to load required SigPlusExtLite extension wrapper. Confirm extension is up-to-date, installed, and enabled, the NMH/SDK is installed, and SigPlus OCX is installed.");
        };
        script.src = url;
        document.head.appendChild(script);
      } catch (ex) {
        alert("Unable to load required SigPlusExtLite extension wrapper. Confirm extension is up-to-date, installed, and enabled, the NMH/SDK is installed, and SigPlus OCX is installed.");
      }
    },
    async ShowVersionInfo() {
      try {
        let global = Topaz.Global;

        let extVersion = await global.GetSigPlusExtLiteVersion();
        if (extVersion != null) {
          //console.log(extVersion);
        } else {
          alert("Unable to get the extension version. Confirm it is installed and enabled.");
        }

        let nmhVersion = await global.GetSigPlusExtLiteNMHVersion();
        if (nmhVersion != null) {
          //console.log(nmhVersion);

          //Get SigPlusOCX version if able to get nmh version info
          let sigplusVersion = await global.GetSigPlusActiveXVersion();
          if (sigplusVersion != null) {
            //console.log(sigplusVersion);
          } else {
            let lastError = await global.GetLastError();
            if (lastError != null) {
              alert("Issue getting SigPlus OCX version information. " + lastError);
            } else {
              alert("Unable to get SigPlus OCX version information");
            }
          }
        } else {
          let lastError = await global.GetLastError();
          if (lastError != null) {
            alert("Issue getting NMH/SDK version information. " + lastError);
          } else {
            alert("Unable to get NMH/SDK version information");
          }
        }
      } catch (ex) {
        console.log(ex);
        alert("Unable to get version information. Confirm extension is installed and enabled, the NMH/SDK is installed, and SigPlus OCX is installed.");
      }
    },
    async StartSign() {
      var isInstalled = document.documentElement.getAttribute("SigPlusExtLiteExtension-installed");
      if (!isInstalled) {
        alert("SigPlusExtLite extension is either not installed or disabled. Please install or enable extension.");
        return;
      }
      try {
        let sign = Topaz.SignatureCaptureWindow.Sign;
        sign.SetImageDetails(0, this.width_sign, this.height_sign, true, false, 0.0);
        sign.SetPenDetails("Black", 2);
        sign.SetMinSigPoints(25);
        //console.log(sign);
        await sign.StartSign(false, 1, 0, "");

        let lastError = await Topaz.Global.GetLastError();

        if (lastError != null && lastError != "") {
          if (
            lastError =="The signature does not have enough points to be valid." ||
            lastError == "User cancelled signing.")
          {
            alert("SigPlusExtLite Info: " + lastError);
          }
          else {
            alert("SigPlusExtLite Error: On Signing - " + lastError);
          }
        }

        else {
          if (await sign.IsSigned()) {
            let imgData = await sign.GetSignatureImage();
            this.image = "data:image/png;base64," + imgData;
            //console.log(this.image);
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    isEmpty(){
      if(this.modo == 1) return (this.image === this.defaultImage);
      else if(this.modo == 2) return this.$refs.firma.isEmpty();
    },
    clear(){
      if(this.modo == 1) this.image = (' ' + this.defaultImage).slice(1);
      else if(this.modo == 2) return this.$refs.firma.clear();
      this.modo = 1;
    },
    save(){
      if(this.modo == 1) return this.image;
      else if(this.modo == 2) return this.$refs.firma.save();
    },
    manualSign(){
      this.modo = 2;
    }
  },
};
/* eslint-enable no-undef */
</script>
