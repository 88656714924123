// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Vuetify from 'vuetify'
import es from 'vuetify/es5/locale/es'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-select/dist/vue-select.css';
import axios from 'axios'
import vSelect from 'vue-select'
import VueNumberInput from '@chenfengyuan/vue-number-input';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue-apexcharts'; //Importamos la libreria de ApexCharts
import 'vue2-timepicker/dist/VueTimepicker.css'

Vue.prototype.$http = axios

// Sets the default url used by all of this axios instance's requests
//axios.defaults.baseURL = "/api";
//axios.defaults.baseURL = "https://mi.ubga.org.mx/api";
//axios.defaults.baseURL = "https://project11.masttl.com/api";
//axios.defaults.baseURL = "http://localhost:5000/api";
axios.defaults.baseURL = "/api";
axios.defaults.headers.get['Accept'] = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

// Sync store with router
sync(store, router)

Vue.use(Vuetify)
Vue.component('v-select-advance', vSelect)
Vue.use(VueNumberInput);
Vue.use(VueSweetalert2);
Vue.component('apexchart', VueApexCharts); //Importamos de nuevo

axios.interceptors.response.use(function (response) {
  if(store.state.netError == true) store.state.netError = false;
  return response;
}, async function (error) {
  if (!error.response) {
    if(store.state.netError == false){
      store.state.netError = true;
    }
    
  } 
  const originalRequest = error.config;
  if (401 === error.response.status) {
    if(originalRequest._retry != true){
      await store.dispatch('refreshtoken');
      originalRequest._retry = true;
      originalRequest.headers['Authorization'] = 'Bearer ' + store.state.token;
      return Promise.resolve(await axios(originalRequest));
    }
    else{
      store.dispatch('logout');
      router.go();
      return Promise.reject(error);
    }
  }
  else if(403 === error.response.status) {
    store.dispatch('logout');
    router.go();
    return Promise.reject(error);
  }

  else if(530 === error.response.status){
    var can_edit = (store.state.ids_vistas.filter(x => x == 12).length >= 1)
    if (can_edit && router.path !== '/dashboard/configuracion') {
      router.push('/dashboard/configuracion');
    }

    else if (!can_edit &&router.path !== '/dashboard/error-530') {
      router.push('/dashboard/error-530');
    }
    return Promise.reject(error);
  }
  else if(531 === error.response.status){
    if (router.path !== '/dashboard/error-531') {
      router.push('/dashboard/error-531');
    }
    return Promise.reject(error);
  }

  else if(532 === error.response.status){
    if (router.path !== '/dashboard/error-532') {
      router.push('/dashboard/error-532');
    }
    return Promise.reject(error);
  }

  else {
      return Promise.reject(error);
  }
});

const vuetifyOpts = {
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    themes: {
      light: {
        primary: '#A96419',
        secondary: '#E75300',
        accent: '#F7CF3C',
        error: '#FF5252',  // Rojo
        info: '#2196F3',  // Azul
        success: '#4CAF50',  // Verde
        warning: '#FFC107',  // Amarillo
        footer: '#F7A800'
      },
      dark: {
        primary: '#A96419',
        secondary: '#E75300',
        accent: '#F7CF3C',
        error: '#FF5252',  // Rojo
        info: '#2196F3',  // Azul
        success: '#4CAF50',  // Verde
        warning: '#FFC107'  // Amarillo
      },
    },
  }
}
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  render: h => h(App),
  template: '<App/>',
  vuetify: new Vuetify(vuetifyOpts)
})
