<template>
  <v-container
    class="px-5"
    style="width: 100%; max-width: 1920px; position: relative; margin: 0 auto"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog v-model="dialog" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          Validar Proveedor
        </v-card-title>
        <v-card-text>  
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Datos Generales</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorNombreCompleto"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorNombreCompleto === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  label="Nombre Completo del Proveedor *"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.nombreCompleto"
                  readonly
                  :error="editedItem.errorNombreCompleto === false"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorNombreCompleto === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Nombre Completo del Proveedor"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaNombreCompleto"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>

            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorNombreContacto"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorNombreContacto === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  label="Nombre del Contacto"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.nombreContacto"
                  readonly
                  :error="editedItem.errorNombreContacto === false"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorNombreContacto === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Nombre del Contacto"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaNombreContacto"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>

            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorTelefonoContacto"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorTelefonoContacto === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  label="Teléfono de Contacto"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.telefonoContacto"
                  readonly
                  :error="editedItem.errorTelefonoContacto === false"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorTelefonoContacto === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Teléfono de Contacto"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaTelefonoContacto"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>

            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorCorreoContacto"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorCorreoContacto === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  label="Correo Electrónico"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.correoContacto"
                  readonly
                  :error="editedItem.errorCorreoContacto === false"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorCorreoContacto === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Correo Electrónico"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaCorreoContacto"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>
          </v-row>


          <br />
          <v-divider></v-divider>
          <br />

          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Datos Fiscales</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorTipoProveedor"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorTipoProveedor === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-select
                  label="Tipo de Proveedor *"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.tipoProveedor"
                  :items="tiposProveedor"
                  item-text="nombre"
                  item-value="id_tipo_proveedor"
                  readonly
                  :error="editedItem.errorTipoProveedor === false"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorTipoProveedor === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Tipo de Proveedor"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaTipoProveedor"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>

            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorTipoPersona"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorTipoPersona === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-autocomplete
                  :items="tiposPersonas"
                  label="Tipo de Persona *"
                  item-value="id_tipo_persona"
                  item-text="nombre"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.tipoPersona"
                  readonly
                  :error="editedItem.errorTipoPersona === false"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorTipoPersona === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Tipo de Persona"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaTipoPersona"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>

            <template v-if="editedItem.tipoProveedor == 1">
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorRazonSocial"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorRazonSocial === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Nombre/Razón Social *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.razonSocial"
                    readonly
                    :error="editedItem.errorRazonSocial === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorRazonSocial === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Nombre/Razón Social"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaRazonSocial"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorRfc"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorRfc === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="RFC *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.rfc"
                    readonly
                    :error="editedItem.errorRfc === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorRfc === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - RFC"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaRfc"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorDomicilioFiscal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorDomicilioFiscal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Domicilio Fiscal *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.domicilioFiscal"
                    readonly
                    :error="editedItem.errorDomicilioFiscal === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorDomicilioFiscal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Domicilio Fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaDomicilioFiscal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorEstado"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorEstado === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-autocomplete
                    :items="estados"
                    label="Estado *"
                    item-value="id_estado"
                    item-text="nombre"
                    dense
                    outlined
                    hide-details
                    @change="getMunicipios"
                    v-model="editedItem.id_estado"
                    readonly
                    :error="editedItem.errorEstado === false"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorEstado === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Estado"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaEstado"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorMunicipio"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorMunicipio === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-autocomplete
                    :items="municipios"
                    item-value="id_municipio"
                    item-text="nombre"
                    label="Municipio *"
                    dense
                    outlined
                    hide-details
                    :loading="loadingMunicipios"
                    v-model="editedItem.id_municipio"
                    readonly
                    :error="editedItem.errorMunicipio === false"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorMunicipio === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Municipio"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaMunicipio"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorRegimenFiscal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorRegimenFiscal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-autocomplete
                    :items="regimenFiscales"
                    label="Régimen Fiscal *"
                    item-text="nombre"
                    item-value="id_regimen_fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.regimenFiscal"
                    readonly
                    :error="editedItem.errorRegimenFiscal === false"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorRegimenFiscal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Régimen Fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaRegimenFiscal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCodigoPostal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCodigoPostal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Código Postal *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.codigoPostal"
                    readonly
                    :error="editedItem.errorCodigoPostal === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCodigoPostal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Código Postal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCodigoPostal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorDireccion"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorDireccion === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Dirección *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.direccion"
                    readonly
                    :error="editedItem.errorDireccion === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorDireccion === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Dirección"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaDireccion"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorColonia"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorColonia === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Colonia *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.colonia"
                    readonly
                    :error="editedItem.errorColonia === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorColonia === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Colonia"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaColonia"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>
            </template>

            <template v-if="editedItem.tipoProveedor == 2">
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorRazonSocial"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorRazonSocial === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Nombre/Razón Social *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.razonSocial"
                    readonly
                    :error="editedItem.errorRazonSocial === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorRazonSocial === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Nombre/Razón Social"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaRazonSocial"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorNumeroFiscal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorNumeroFiscal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Número de Identificación Fiscal *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.numeroFiscal"
                    readonly
                    :error="editedItem.errorNumeroFiscal === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorNumeroFiscal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Número de Identificación Fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaNumeroFiscal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorDireccionFiscal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorDireccionFiscal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Dirección Fiscal *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.direccionFiscal"
                    readonly
                    :error="editedItem.errorDireccionFiscal === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorDireccionFiscal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Dirección Fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaDireccionFiscal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCodigoPostal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCodigoPostal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Código Postal *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.codigoPostal"
                    readonly
                    :error="editedItem.errorCodigoPostal === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCodigoPostal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Código Postal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCodigoPostal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCiudad"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCiudad === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Ciudad *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.ciudad"
                    readonly
                    :error="editedItem.errorCiudad === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCiudad === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Ciudad"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCiudad"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorRegion"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorRegion === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Región/Estado/Provincia *"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.region"
                    readonly
                    :error="editedItem.errorRegion === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorRegion === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Región/Estado/Provincia"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaRegion"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorPais"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorPais === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-autocomplete
                    :items="paises"
                    label="País *"
                    item-value="codigo"
                    item-text="nombre"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.pais"
                    readonly
                    :error="editedItem.errorPais === false"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorPais === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - País"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaPais"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>
            </template>

            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorTelefono"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorTelefono === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  label="Teléfono *"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.telefonoFiscal"
                  readonly
                  :error="editedItem.errorTelefono === false"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorTelefono === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Teléfono"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaTelefono"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>

            <v-col cols="12" md="6" class="py-2">
              <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                <v-checkbox
                  v-model="editedItem.errorCorreo"
                  class="mr-2"
                  indeterminate
                  :error="editedItem.errorCorreo === false"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  label="Correo *"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.correoFiscal"
                  readonly
                  :error="editedItem.errorCorreo === false"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="editedItem.errorCorreo === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  label="Anomalía - Correo"
                  dense
                  outlined
                  hide-details
                  v-model="editedItem.anomaliaCorreo"
                  auto-grow
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-col>
          </v-row>

          <br />
          <v-divider></v-divider>
          <br />

          <v-row>
            <v-col cols="12" class="text-center">
              <h2>
                Cuenta Bancaria
                {{editedItem.tipoProveedor == 1
                  ? "Nacional"
                  : editedItem.tipoProveedor == 2
                  ? "Internacional"
                  : ""}}
              </h2>
            </v-col>
          </v-row>

          <v-row>
            <template v-if="editedItem.tipoProveedor == 1">
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorBanco"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorBanco === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-autocomplete
                    label="Banco *"
                    :items="bancos"
                    item-value="id_banco"
                    item-text="nombre"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.id_banco"
                    readonly
                    :error="editedItem.errorBanco === false"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorBanco === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Banco"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaBanco"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorNombreBeneficiario"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorNombreBeneficiario === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Nombre del Beneficiario *"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.nombreBeneficiario"
                    readonly
                    :error="editedItem.errorNombreBeneficiario === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorNombreBeneficiario === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Nombre del Beneficiario"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaNombreBeneficiario"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="5" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorClaveInt"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorClaveInt === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="CLABE Interbancaria *"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.clabe"
                    readonly
                    :error="editedItem.errorClaveInt === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorClaveInt === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - CLABE Interbancaria"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaClaveInt"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="5" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCuenta"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCuenta === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Cuenta *"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.cuenta"
                    readonly
                    :error="editedItem.errorCuenta === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCuenta === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Cuenta"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCuenta"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="2" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorMoneda"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorMoneda === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-select
                    label="Moneda *"
                    :items="monedas"
                    item-value="id_moneda"
                    item-text="nombre"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.id_moneda"
                    readonly
                    :error="editedItem.errorMoneda === false"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorMoneda === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Moneda"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaMoneda"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCie"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCie === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="CIE"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.cie"
                    readonly
                    :error="editedItem.errorCie === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCie === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - CIE"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCie"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorReferencia"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorReferencia === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    label="Referencia"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.referencia"
                    readonly
                    :error="editedItem.errorReferencia === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorReferencia === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Referencia"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaReferencia"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>
            </template>

            <template v-if="editedItem.tipoProveedor == 2">
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorBanco"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorBanco === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    v-model="editedItem.banco"
                    label="Banco *"
                    outlined
                    dense
                    hide-details
                    readonly
                    :error="editedItem.errorBanco === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorBanco === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Banco"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaBanco"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorNombreBeneficiario"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorNombreBeneficiario === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    v-model="editedItem.nombreBeneficiario"
                    label="Nombre del Beneficiario *"
                    outlined
                    dense
                    hide-details
                    readonly
                    :error="editedItem.errorNombreBeneficiario === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorNombreBeneficiario === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Nombre del Beneficiario"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaNombreBeneficiario"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="5" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorClaveInt"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorClaveInt === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    v-model="editedItem.claveInt"
                    label="Clave Int *"
                    outlined
                    dense
                    hide-details
                    readonly
                    :error="editedItem.errorClaveInt === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorClaveInt === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Clave Int"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaClaveInt"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="5" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCuenta"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCuenta === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    v-model="editedItem.cuenta"
                    label="Cuenta *"
                    outlined
                    dense
                    hide-details
                    readonly
                    :error="editedItem.errorCuenta === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCuenta === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Cuenta"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCuenta"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="2" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorMoneda"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorMoneda === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-select
                    label="Moneda *"
                    :items="monedas"
                    item-value="id_moneda"
                    item-text="nombre"
                    outlined
                    dense
                    hide-details
                    v-model="editedItem.id_moneda"
                    readonly
                    :error="editedItem.errorMoneda === false"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorMoneda === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Moneda"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaMoneda"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorSwift"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorSwift === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    v-model="editedItem.swift"
                    label="SWIFT"
                    outlined
                    dense
                    hide-details
                    readonly
                    :error="editedItem.errorSwift === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorSwift === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - SWIFT"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaSwift"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorIsbn"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorIsbn === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    v-model="editedItem.isbn"
                    label="ISBN"
                    outlined
                    dense
                    hide-details
                    readonly
                    :error="editedItem.errorIsbn === false"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorIsbn === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - ISBN"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaIsbn"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>
            </template>

          </v-row>

          <br />
          <v-divider></v-divider>
          <br />

          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Documentos Adjuntos</h2>
            </v-col>
          </v-row>

          <v-row>
            <template v-if="editedItem.tipoProveedor == 1 && editedItem.tipoPersona == 1">
              <!-- Currículum Vitae empresarial -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCurriculumVitaeEmpresarial"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCurriculumVitaeEmpresarial === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCurriculumVitaeEmpresarial === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.curriculumVitaeEmpresarial}?jwt=${token}`)"
                  >
                    Currículum Vitae empresarial *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCurriculumVitaeEmpresarial === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Currículum Vitae empresarial"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCurriculumVitaeEmpresarial"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Copia simple de identificación oficial -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorIdentificacionOficial"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorIdentificacionOficial === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorIdentificacionOficial === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.identificacionOficial}?jwt=${token}`)"
                  >
                    Copia simple de identificación oficial *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorIdentificacionOficial === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia simple de identificación oficial"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaIdentificacionOficial"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Copia simple de comprobante domicilio -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorComprobanteDomicilio"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorComprobanteDomicilio === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorComprobanteDomicilio === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.comprobanteDomicilio}?jwt=${token}`)"
                  >
                    Copia simple de comprobante domicilio *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorComprobanteDomicilio === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia simple de comprobante domicilio"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaComprobanteDomicilio"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">Nota: No mayor a 3 meses.</div>
              </v-col>

              <!-- Copia de la carátula del estado de cuenta -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCaratulaEstadoCuenta"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.caratulaEstadoCuenta}?jwt=${token}`)"
                  >
                    Copia de la carátula del estado de cuenta *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCaratulaEstadoCuenta === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia de la carátula del estado de cuenta"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCaratulaEstadoCuenta"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  En la portada, se debe incluir la siguiente información: nombre del titular de
                  la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e
                  institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda
                  al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la
                  información proporcionada", junto con la firma correspondiente.
                </div>
              </v-col>

              <!-- Constancia de situación fiscal -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorSituacionFiscal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorSituacionFiscal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorSituacionFiscal === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.situacionFiscal}?jwt=${token}`)"
                  >
                    Constancia de situación fiscal *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorSituacionFiscal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Constancia de situación fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaSituacionFiscal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Emitida en el mes que solicita el registro.
                </div>
              </v-col>

              <!-- Opinión de cumplimiento positiva de obligaciones fiscales -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorOpinionCumplimiento"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorOpinionCumplimiento === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorOpinionCumplimiento === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.opinionCumplimiento}?jwt=${token}`)"
                  >
                    Opinión de cumplimiento positiva de obligaciones fiscales *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorOpinionCumplimiento === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Opinión de cumplimiento positiva de obligaciones fiscales"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaOpinionCumplimiento"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Emitida en el mes que solicita el registro.
                </div>
              </v-col>

              <!-- 2 cartas de referencias comerciales -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorReferenciaComercial1"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorReferenciaComercial1 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorReferenciaComercial1 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.referenciaComercial1}?jwt=${token}`)"
                  >
                    Carta de referencia comercial 1 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorReferenciaComercial1 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Carta de referencia comercial 1"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaReferenciaComercial1"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: En papel membretado (no mayor a 3 meses).
                </div>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorReferenciaComercial2"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorReferenciaComercial2 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorReferenciaComercial2 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.referenciaComercial2}?jwt=${token}`)"
                  >
                    Carta de referencia comercial 2 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorReferenciaComercial2 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Carta de referencia comercial 2"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaReferenciaComercial2"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: En papel membretado (no mayor a 3 meses).
                </div>
              </v-col>

              <!-- Copia de la certificación, licencia o permiso -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCertificacion"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCertificacion === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCertificacion === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.certificacion}?jwt=${token}`)"
                  >
                    Copia de la certificación, licencia o permiso *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCertificacion === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia de la certificación, licencia o permiso"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCertificacion"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Asegúrate que el documento esté vigente.
                </div>
              </v-col>

              <!-- 4 Fotografías del producto o servicio proporcionado -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFotografiaProducto1"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFotografiaProducto1 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFotografiaProducto1 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fotografiaProducto1}?jwt=${token}`)"
                  >
                    Fotografía del producto o servicio 1 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFotografiaProducto1 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía del producto o servicio 1"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFotografiaProducto1"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFotografiaProducto2"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFotografiaProducto2 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFotografiaProducto2 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fotografiaProducto2}?jwt=${token}`)"
                  >
                    Fotografía del producto o servicio 2 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFotografiaProducto2 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía del producto o servicio 2"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFotografiaProducto2"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFotografiaProducto3"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFotografiaProducto3 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFotografiaProducto3 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fotografiaProducto3}?jwt=${token}`)"
                  >
                    Fotografía del producto o servicio 3 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFotografiaProducto3 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía del producto o servicio 3"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFotografiaProducto3"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFotografiaProducto4"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFotografiaProducto4 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFotografiaProducto4 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fotografiaProducto4}?jwt=${token}`)"
                  >
                    Fotografía del producto o servicio 4 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFotografiaProducto4 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía del producto o servicio 4"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFotografiaProducto4"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>
            </template>

            <template v-else-if="editedItem.tipoProveedor == 1 && editedItem.tipoPersona == 2">
              <!-- Currículum de la empresa -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCurriculumEmpresa"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCurriculumEmpresa === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCurriculumEmpresa === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.curriculumEmpresa}?jwt=${token}`)"
                  >
                    Currículum de la empresa *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCurriculumEmpresa === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Currículum de la empresa"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCurriculumEmpresa"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Copia simple del acta constitutiva y actas modificatorias -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorActaConstitutiva"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorActaConstitutiva === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorActaConstitutiva === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.actaConstitutiva}?jwt=${token}`)"
                  >
                    Copia simple del acta constitutiva y actas modificatorias *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorActaConstitutiva === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia simple del acta constitutiva y actas modificatorias"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaActaConstitutiva"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Copia simple del poder notariado del representante legal -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorPoderRepresentante"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorPoderRepresentante === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorPoderRepresentante === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.poderRepresentante}?jwt=${token}`)"
                  >
                    Copia simple del poder notariado del representante legal *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorPoderRepresentante === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia simple del poder notariado del representante legal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaPoderRepresentante"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Copia simple de identificación oficial del representante legal -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorIdentificacionRepresentante"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorIdentificacionRepresentante === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorIdentificacionRepresentante === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.identificacionRepresentante}?jwt=${token}`)"
                  >
                    Copia simple de identificación oficial del representante legal *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorIdentificacionRepresentante === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia simple de identificación oficial del representante legal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaIdentificacionRepresentante"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Copia simple de comprobante domicilio -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorComprobanteDomicilio"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorComprobanteDomicilio === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorComprobanteDomicilio === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.comprobanteDomicilio}?jwt=${token}`)"
                  >
                    Copia simple de comprobante domicilio *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorComprobanteDomicilio === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia simple de comprobante domicilio"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaComprobanteDomicilio"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">Nota: No mayor a 3 meses.</div>
              </v-col>

              <!-- Copia de la carátula del estado de cuenta -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCaratulaEstadoCuenta"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.caratulaEstadoCuenta}?jwt=${token}`)"
                  >
                    Copia de la carátula del estado de cuenta *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCaratulaEstadoCuenta === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia de la carátula del estado de cuenta"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCaratulaEstadoCuenta"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  En la portada, se debe incluir la siguiente información: nombre del titular de la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la información proporcionada", junto con la firma correspondiente.
                </div>
              </v-col>

              <!-- Constancia de situación fiscal -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorSituacionFiscal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorSituacionFiscal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorSituacionFiscal === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.situacionFiscal}?jwt=${token}`)"
                  >
                    Constancia de situación fiscal *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorSituacionFiscal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Constancia de situación fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaSituacionFiscal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Emitida en el mes que solicita el registro.
                </div>
              </v-col>

              <!-- Opinión de cumplimiento positiva de obligaciones fiscales -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorOpinionCumplimiento"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorOpinionCumplimiento === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorOpinionCumplimiento === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.opinionCumplimiento}?jwt=${token}`)"
                  >
                    Opinión de cumplimiento positiva de obligaciones fiscales *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorOpinionCumplimiento === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Opinión de cumplimiento positiva de obligaciones fiscales"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaOpinionCumplimiento"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Emitida en el mes que solicita el registro.
                </div>
              </v-col>

              <!-- 2 cartas de referencias comerciales -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorReferenciaComercial1"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorReferenciaComercial1 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorReferenciaComercial1 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.referenciaComercial1}?jwt=${token}`)"
                  >
                    Carta de referencia comercial 1 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorReferenciaComercial1 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Carta de referencia comercial 1"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaReferenciaComercial1"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: En papel membretado (no mayor a 3 meses).
                </div>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorReferenciaComercial2"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorReferenciaComercial2 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorReferenciaComercial2 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.referenciaComercial2}?jwt=${token}`)"
                  >
                    Carta de referencia comercial 2 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorReferenciaComercial2 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Carta de referencia comercial 2"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaReferenciaComercial2"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: En papel membretado (no mayor a 3 meses).
                </div>
              </v-col>

              <!-- Copia de la certificación, licencia o permiso -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCertificacion"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCertificacion === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCertificacion === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.certificacion}?jwt=${token}`)"
                  >
                    Copia de la certificación, licencia o permiso *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCertificacion === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia de la certificación, licencia o permiso"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCertificacion"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Asegúrate que el documento esté vigente.
                </div>
              </v-col>

              <!-- Fotografías de la fachada de la empresa de diferentes ángulos -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFachadaEmpresa1"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFachadaEmpresa1 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFachadaEmpresa1 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fachadaEmpresa1}?jwt=${token}`)"
                  >
                    Fotografía de la fachada de la empresa 1 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFachadaEmpresa1 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía de la fachada de la empresa 1"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFachadaEmpresa1"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFachadaEmpresa2"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFachadaEmpresa2 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFachadaEmpresa2 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fachadaEmpresa2}?jwt=${token}`)"
                  >
                    Fotografía de la fachada de la empresa 2 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFachadaEmpresa2 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía de la fachada de la empresa 2"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFachadaEmpresa2"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFachadaEmpresa3"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFachadaEmpresa3 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFachadaEmpresa3 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fachadaEmpresa3}?jwt=${token}`)"
                  >
                    Fotografía de la fachada de la empresa 3 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFachadaEmpresa3 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía de la fachada de la empresa 3"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFachadaEmpresa3"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorFachadaEmpresa4"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorFachadaEmpresa4 === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorFachadaEmpresa4 === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.fachadaEmpresa4}?jwt=${token}`)"
                  >
                    Fotografía de la fachada de la empresa 4 *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorFachadaEmpresa4 === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Fotografía de la fachada de la empresa 4"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaFachadaEmpresa4"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>
            </template>

            <template v-else-if="editedItem.tipoProveedor == 2 && editedItem.tipoPersona == 1">
              <!-- Identificación oficial con fotografía del país de origen -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorIdentificacionOficial"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorIdentificacionOficial === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorIdentificacionOficial === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.identificacionOficial}?jwt=${token}`)"
                  >
                    Identificación oficial con fotografía del país de origen *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorIdentificacionOficial === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Identificación oficial con fotografía del país de origen"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaIdentificacionOficial"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Cédula de identificación fiscal -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCedulaIdentificacionFiscal"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCedulaIdentificacionFiscal === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCedulaIdentificacionFiscal === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.cedulaIdentificacionFiscal}?jwt=${token}`)"
                  >
                    Cédula de identificación fiscal *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCedulaIdentificacionFiscal === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Cédula de identificación fiscal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCedulaIdentificacionFiscal"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Clave única de registro de población, si existe en el país de origen -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCurp"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCurp === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCurp === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.curp}?jwt=${token}`)"
                  >
                    Clave única de registro de población *
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="editedItem.errorCurp === false" class="ma-0 pa-0 py-2">
                  <v-textarea
                    label="Anomalía - Clave única de registro de población"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCurp"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Si existe en el país de origen.
                </div>
              </v-col>

              <!-- Solicitud de certificación del medio de identificación electrónica para personas físicas extranjeras -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorSolicitudCertificacion"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorSolicitudCertificacion === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorSolicitudCertificacion === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.solicitudCertificacion}?jwt=${token}`)"
                  >
                    Solicitud de certificación del medio de identificación electrónica
                    para personas físicas extranjeras *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorSolicitudCertificacion === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Solicitud de certificación del medio de identificación electrónica para personas físicas extranjeras"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaSolicitudCertificacion"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Copia de la carátula del estado de cuenta -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCaratulaEstadoCuenta"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.caratulaEstadoCuenta}?jwt=${token}`)"
                  >
                    Copia de la carátula del estado de cuenta *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCaratulaEstadoCuenta === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia de la carátula del estado de cuenta"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCaratulaEstadoCuenta"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  En la portada, se debe incluir la siguiente información: nombre del
                  titular de la cuenta, número de cuenta, CLABE interbancaria, moneda,
                  sucursal, plaza e institución bancaria, y/o contrato de apertura.
                  Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de
                  esta cuenta y me responsabilizo de la información proporcionada", junto
                  con la firma correspondiente.
                </div>
              </v-col>
            </template>

            <template v-else-if="editedItem.tipoProveedor == 2 && editedItem.tipoPersona == 2">
              <!-- Solicitud de certificación del medio de identificación electrónica para personas morales extranjeras -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorSolicitudCertificacionMorales"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorSolicitudCertificacionMorales === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorSolicitudCertificacionMorales === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.solicitudCertificacionMorales}?jwt=${token}`)"
                  >
                    Solicitud de certificación del medio de identificación electrónica
                    para personas morales extranjeras *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorSolicitudCertificacionMorales === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Solicitud de certificación del medio de identificación electrónica para personas morales extranjeras"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaSolicitudCertificacionMorales"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Clave única de registro de población (CURP) del representante legal o apoderado -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCurpRepresentante"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCurpRepresentante === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCurpRepresentante === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.curpRepresentante}?jwt=${token}`)"
                  >
                    Clave única de registro de población (CURP) del representante legal o
                    apoderado *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCurpRepresentante === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Clave única de registro de población (CURP) del representante legal o apoderado"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCurpRepresentante"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Cédula de identificación fiscal de la persona moral -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCedulaFiscalMoral"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCedulaFiscalMoral === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCedulaFiscalMoral === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.cedulaFiscalMoral}?jwt=${token}`)"
                  >
                    Cédula de identificación fiscal de la persona moral *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCedulaFiscalMoral === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Cédula de identificación fiscal de la persona moral"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCedulaFiscalMoral"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- Identificación oficial con fotografía del representante legal o apoderado -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorIdentificacionRepresentante"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorIdentificacionRepresentante === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorIdentificacionRepresentante === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.identificacionRepresentante}?jwt=${token}`)"
                  >
                    Identificación oficial con fotografía del representante legal *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorIdentificacionRepresentante === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Identificación oficial con fotografía del representante legal"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaIdentificacionRepresentante"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Ejemplo pasaporte vigente.
                </div>
              </v-col>

              <!-- Testimonio de la escritura pública con existencia legal y facultades del representante legal -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorTestimonioEscritura"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorTestimonioEscritura === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorTestimonioEscritura === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.testimonioEscritura}?jwt=${token}`)"
                  >
                    Testimonio de la escritura pública *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorTestimonioEscritura === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Testimonio de la escritura pública"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaTestimonioEscritura"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  Nota: Debe presentarse debidamente apostillada o legalizada y en el
                  idioma español.
                </div>
              </v-col>

              <!-- Copia de la carátula del estado de cuenta -->
              <v-col cols="12" md="6" class="py-2">
                <v-col cols="12" class="d-flex align-center pa-0 ma-0">
                  <v-checkbox
                    v-model="editedItem.errorCaratulaEstadoCuenta"
                    class="mr-2"
                    indeterminate
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    dense
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    color="primary"
                    dense
                    outlined
                    readonly
                    :error="editedItem.errorCaratulaEstadoCuenta === false"
                    @click="nuevaPestana(`/api/Proveedores/DescargarDocumento/${editedItem.caratulaEstadoCuenta}?jwt=${token}`)"
                  >
                    Copia de la carátula del estado de cuenta *
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedItem.errorCaratulaEstadoCuenta === false"
                  class="ma-0 pa-0 py-2"
                >
                  <v-textarea
                    label="Anomalía - Copia de la carátula del estado de cuenta"
                    dense
                    outlined
                    hide-details
                    v-model="editedItem.anomaliaCaratulaEstadoCuenta"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <div class="text-caption note-style">
                  En la portada, se debe incluir la siguiente información: nombre del
                  titular de la cuenta, número de cuenta, CLABE interbancaria, moneda,
                  sucursal, plaza e institución bancaria, y/o contrato de apertura.
                  Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de
                  esta cuenta y me responsabilizo de la información proporcionada", junto
                  con la firma correspondiente.
                </div>
              </v-col>
            </template>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="green" @click="closeDialog" :loading="saving">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="save('5440e0ac-affe-444b-ada3-3ab02028e264')" :loading="saving">Solicitar Revisión</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="save('b0eb6081-7d49-4af5-af7f-dd6be81179f5')" :loading="saving">Rechazar Proveedor</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="save('b82371dd-76a9-4a06-a568-314e776cad74')" :loading="saving">Aceptar Proveedor</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEmail" max-width="60%" persistent>
      <v-card
        v-if="dialogEmail"
      >
        <v-card-title>
          Solicitar Datos del Proveedor
        </v-card-title>
        <v-card-text>
          <v-row>
            <!--
            <v-col cols="6">
              <v-text-field
                label="Nombre del Contacto"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            -->
            <v-col cols="12">
              <v-text-field
                v-model="emailItem.email"
                label="Correo Electrónico"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="closeDialogEmail" :loading="loadingEmail">Cancelar</v-btn>
          <v-btn text color="red" @click="sendEmail" :loading="loadingEmail">Enviar Solicitud</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="dialogEmail = true">
          Solicitar Datos a Proveedor
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
        >
          <template v-slot:[`item.activo`]="{ item }">
            {{item.activo ? 'Si' : 'No'}}
          </template>
          <template v-slot:[`item.cambiar_password`]="{ item }">
            {{item.cambiar_password ? 'Si' : 'No'}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="openDialogValidate(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import VAlertDialog from '@/components/VAlertDialog.vue'

export default {
  components: {
    VAlertDialog
  },
  data() {
    return {
      dialog: false,
      loading: false,
      dialogEmail: false,
      headers: [
        {
          text: "Estado del Proveedor",
          value: "estado_carga_externa",
          align: "center",
        },
        {
          text: "Correo Electrónico de Contacto",
          value: "email",
          align: "center",
        },
        {
          text: "RFC",
          value: "rfc",
          align: "center",
        },
        {
          text: "Activo",
          value: "activo",
          align: "center",
        },
        {
          text: "¿Requiere Cambiar Contraseña?",
          value: "cambiar_password",
          align: "center",
        },
        {
          text: "Ultimo Inicio de Sesión",
          value: "ultimo_login",
          align: "center",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      emailItem: {
        email: null,
      },
      defaultEmailItem: {
        email: null,
      },
      loadingEmail: false,
      items: [],
      
      editedItem: {
        id_proveedor_carga_externa: null,
        nombreCompleto: null,
        nombreContacto: null,
        telefonoContacto: null,
        correoContacto: null,
        tipoProveedor: null,
        tipoPersona: null,
        //Datos Fiscales Nacionales
        razonSocial: null,
        rfc: null,
        domicilioFiscal: null,
        id_estado: null,
        id_municipio: null,
        regimenFiscal: null,
        codigoPostal: null,
        direccion: null,
        colonia: null,

        //Datos Fiscales Internacionales
        //razonSocial: null,
        numeroFiscal: null,
        direccionFiscal: null,
        //codigoPostal: null,
        ciudad: null,
        region: null,
        pais: null,
        telefonoFiscal: null,
        correoFiscal: null,
        //Datos Cuenta Bancaria Nacional
        id_banco: null,
        nombreBeneficiario: null,
        clabeInt: null,
        cuenta: null,
        id_moneda: null,
        cie: null,
        referencia: null,
        //Datos Cuenta Bancaria Internacional
        banco: null,
        //nombreBeneficiario: null,
        //claveInt: null,
        //cuenta: null,
        //id_moneda: null,
        swift: null,
        isbn: null,
        aceptoTerminos: null,
        //Documentos
        curriculumVitaeEmpresarial: null,
        identificacionOficial: null,
        comprobanteDomicilio: null,
        caratulaEstadoCuenta: null,
        situacionFiscal: null,
        opinionCumplimiento: null,
        referenciaComercial1: null,
        referenciaComercial2: null,
        certificacion: null,
        fotografiaProducto1: null,
        fotografiaProducto2: null,
        fotografiaProducto3: null,
        fotografiaProducto4: null,
        curriculumEmpresa: null,
        actaConstitutiva: null,
        poderRepresentante: null,
        identificacionRepresentante: null,
        fachadaEmpresa1: null,
        fachadaEmpresa2: null,
        fachadaEmpresa3: null,
        fachadaEmpresa4: null,
        cedulaIdentificacionFiscal: null,
        curp: null,
        solicitudCertificacion: null,
        solicitudCertificacionMorales: null,
        curpRepresentante: null,
        cedulaFiscalMoral: null,
        testimonioEscritura: null,
        actualizadoNombreCompleto: null,
        actualizadoNombreContacto: null,
        actualizadoTelefonoContacto: null,
        actualizadoCorreoContacto: null,
        actualizadoRazonSocial: null,
        actualizadoRFC: null,
        actualizadoDomicilioFiscal: null,
        actualizadoCodigoPostal: null,
        actualizadoDireccion: null,
        actualizadoColonia: null,
        actualizadoNumeroFiscal: null,
        actualizadoDireccionFiscal: null,
        actualizadoCiudad: null,
        actualizadoRegion: null,
        actualizadoTelefono: null,
        actualizadoCorreo: null,
        actualizadoNombreBeneficiario: null,
        actualizadoClabeInt: null,
        actualizadoClaveInternacional: null,
        actualizadoCuenta: null,
        actualizadoCIE: null,
        actualizadoReferencia: null,
        actualizadoBanco: null,
        actualizadoSwift: null,
        actualizadoISBN: null,
        actualizadoCurriculumVitae: null,
        actualizadoIdentificacionOficial: null,
        actualizadoComprobanteDomicilio: null,
        actualizadoCaratulaEstadoCuenta: null,
        actualizadoSituacionFiscal: null,
        actualizadoOpinionCumplimiento: null,
        actualizadoReferenciaComercial1: null,
        actualizadoReferenciaComercial2: null,
        actualizadoCertificacion: null,
        actualizadoFotografiaProducto1: null,
        actualizadoFotografiaProducto2: null,
        actualizadoFotografiaProducto3: null,
        actualizadoFotografiaProducto4: null,
        actualizadoCurriculumEmpresa: null,
        actualizadoActaConstitutiva: null,
        actualizadoPoderRepresentante: null,
        actualizadoIdentificacionRepresentante: null,
        actualizadoFachadaEmpresa1: null,
        actualizadoFachadaEmpresa2: null,
        actualizadoFachadaEmpresa3: null,
        actualizadoFachadaEmpresa4: null,
        actualizadoCedulaIdentificacionFiscal: null,
        actualizadoCurp: null,
        actualizadoSolicitudCertificacion: null,
        actualizadoSolicitudCertificacionMorales: null,
        actualizadoCurpRepresentante: null,
        actualizadoCedulaFiscalMoral: null,
        actualizadoTestimonioEscritura: null,
        anteriorErrorNombreCompleto: null,
        anteriorErrorNombreContacto: null,
        anteriorErrorTelefonoContacto: null,
        anteriorErrorCorreoContacto: null,
        anteriorErrorRazonSocial: null,
        anteriorErrorRFC: null,
        anteriorErrorDomicilioFiscal: null,
        anteriorErrorCodigoPostal: null,
        anteriorErrorDireccion: null,
        anteriorErrorColonia: null,
        anteriorErrorNumeroFiscal: null,
        anteriorErrorDireccionFiscal: null,
        anteriorErrorCiudad: null,
        anteriorErrorRegion: null,
        anteriorErrorTelefono: null,
        anteriorErrorCorreo: null,
        anteriorErrorNombreBeneficiario: null,
        anteriorErrorClabeInt: null,
        anteriorErrorClaveInternacional: null,
        anteriorErrorCuenta: null,
        anteriorerrorCie: null,
        anteriorErrorReferencia: null,
        anteriorErrorBanco: null,
        anteriorErrorSwift: null,
        anteriorErrorISBN: null,
        anteriorErrorCurriculumVitae: null,
        anteriorErrorIdentificacionOficial: null,
        anteriorErrorComprobanteDomicilio: null,
        anteriorErrorCaratulaEstadoCuenta: null,
        anteriorErrorSituacionFiscal: null,
        anteriorErrorOpinionCumplimiento: null,
        anteriorErrorReferenciaComercial1: null,
        anteriorErrorReferenciaComercial2: null,
        anteriorErrorCertificacion: null,
        anteriorErrorFotografiaProducto1: null,
        anteriorErrorFotografiaProducto2: null,
        anteriorErrorFotografiaProducto3: null,
        anteriorErrorFotografiaProducto4: null,
        anteriorErrorCurriculumEmpresa: null,
        anteriorErrorActaConstitutiva: null,
        anteriorErrorPoderRepresentante: null,
        anteriorErrorIdentificacionRepresentante: null,
        anteriorErrorFachadaEmpresa1: null,
        anteriorErrorFachadaEmpresa2: null,
        anteriorErrorFachadaEmpresa3: null,
        anteriorErrorFachadaEmpresa4: null,
        anteriorErrorCedulaIdentificacionFiscal: null,
        anteriorErrorCurp: null,
        anteriorErrorSolicitudCertificacion: null,
        anteriorErrorSolicitudCertificacionMorales: null,
        anteriorErrorCurpRepresentante: null,
        anteriorErrorCedulaFiscalMoral: null,
        anteriorErrorTestimonioEscritura: null,
        anomaliaNombreCompleto: null,
        anomaliaNombreContacto: null,
        anomaliaTelefonoContacto: null,
        anomaliaCorreoContacto: null,
        anomaliaTipoProveedor: null,
        anomaliaTipoPersona: null,
        anomaliaRazonSocial: null,
        anomaliaRfc: null,
        anomaliaDomicilioFiscal: null,
        anomaliaEstado: null,
        anomaliaMunicipio: null,
        anomaliaRegimenFiscal: null,
        anomaliaCodigoPostal: null,
        anomaliaDireccion: null,
        anomaliaColonia: null,
        anomaliaNumeroFiscal: null,
        anomaliaDireccionFiscal: null,
        anomaliaCiudad: null,
        anomaliaRegion: null,
        anomaliaPais: null,
        anomaliaTelefono: null,
        anomaliaCorreo: null,
        anomaliaBanco: null,
        anomaliaNombreBeneficiario: null,
        anomaliaClaveInt: null,
        anomaliaCuenta: null,
        anomaliaMoneda: null,
        anomaliaCie: null,
        anomaliaReferencia: null,
        anomaliaSwift: null,
        anomaliaIsbn: null,
        anomaliaCurriculumVitaeEmpresarial: null,
        anomaliaIdentificacionOficial: null,
        anomaliaComprobanteDomicilio: null,
        anomaliaCaratulaEstadoCuenta: null,
        anomaliaSituacionFiscal: null,
        anomaliaOpinionCumplimiento: null,
        anomaliaReferenciaComercial1: null,
        anomaliaReferenciaComercial2: null,
        anomaliaCertificacion: null,
        anomaliaFotografiaProducto1: null,
        anomaliaFotografiaProducto2: null,
        anomaliaFotografiaProducto3: null,
        anomaliaFotografiaProducto4: null,
        anomaliaCurriculumEmpresa: null,
        anomaliaActaConstitutiva: null,
        anomaliaPoderRepresentante: null,
        anomaliaIdentificacionRepresentante: null,
        anomaliaFachadaEmpresa1: null,
        anomaliaFachadaEmpresa2: null,
        anomaliaFachadaEmpresa3: null,
        anomaliaFachadaEmpresa4: null,
        anomaliaCedulaIdentificacionFiscal: null,
        anomaliaCurp: null,
        anomaliaSolicitudCertificacion: null,
        anomaliaSolicitudCertificacionMorales: null,
        anomaliaCurpRepresentante: null,
        anomaliaCedulaFiscalMoral: null,
        anomaliaTestimonioEscritura: null,
        errorNombreCompleto: null,
        errorNombreContacto: null,
        errorTelefonoContacto: null,
        errorCorreoContacto: null,
        errorTipoProveedor: null,
        errorTipoPersona: null,
        errorRazonSocial: null,
        errorRfc: null,
        errorDomicilioFiscal: null,
        errorEstado: null,
        errorMunicipio: null,
        errorRegimenFiscal: null,
        errorCodigoPostal: null,
        errorDireccion: null,
        errorColonia: null,
        errorNumeroFiscal: null,
        errorDireccionFiscal: null,
        errorCiudad: null,
        errorRegion: null,
        errorPais: null,
        errorTelefono: null,
        errorCorreo: null,
        errorBanco: null,
        errorNombreBeneficiario: null,
        errorClaveInt: null,
        errorCuenta: null,
        errorMoneda: null,
        errorCie: null,
        errorReferencia: null,
        errorSwift: null,
        errorIsbn: null,
        errorCurriculumVitaeEmpresarial: null,
        errorIdentificacionOficial: null,
        errorComprobanteDomicilio: null,
        errorCaratulaEstadoCuenta: null,
        errorSituacionFiscal: null,
        errorOpinionCumplimiento: null,
        errorReferenciaComercial1: null,
        errorReferenciaComercial2: null,
        errorCertificacion: null,
        errorFotografiaProducto1: null,
        errorFotografiaProducto2: null,
        errorFotografiaProducto3: null,
        errorFotografiaProducto4: null,
        errorCurriculumEmpresa: null,
        errorActaConstitutiva: null,
        errorPoderRepresentante: null,
        errorIdentificacionRepresentante: null,
        errorFachadaEmpresa1: null,
        errorFachadaEmpresa2: null,
        errorFachadaEmpresa3: null,
        errorFachadaEmpresa4: null,
        errorCedulaIdentificacionFiscal: null,
        errorCurp: null,
        errorSolicitudCertificacion: null,
        errorSolicitudCertificacionMorales: null,
        errorCurpRepresentante: null,
        errorCedulaFiscalMoral: null,
        errorTestimonioEscritura: null,
      },

      defaultEditedItem: {
        id_proveedor_carga_externa: null,
        nombreCompleto: null,
        nombreContacto: null,
        telefonoContacto: null,
        correoContacto: null,
        tipoProveedor: null,
        tipoPersona: null,
        //Datos Fiscales Nacionales
        razonSocial: null,
        rfc: null,
        domicilioFiscal: null,
        id_estado: null,
        id_municipio: null,
        regimenFiscal: null,
        codigoPostal: null,
        direccion: null,
        colonia: null,

        //Datos Fiscales Internacionales
        //razonSocial: null,
        numeroFiscal: null,
        direccionFiscal: null,
        //codigoPostal: null,
        ciudad: null,
        region: null,
        pais: null,
        telefonoFiscal: null,
        correoFiscal: null,
        //Datos Cuenta Bancaria Nacional
        id_banco: null,
        nombreBeneficiario: null,
        clabeInt: null,
        cuenta: null,
        id_moneda: null,
        cie: null,
        referencia: null,
        //Datos Cuenta Bancaria Internacional
        banco: null,
        //nombreBeneficiario: null,
        //claveInt: null,
        //cuenta: null,
        //id_moneda: null,
        swift: null,
        isbn: null,
        aceptoTerminos: null,
        //Documentos
        curriculumVitaeEmpresarial: null,
        identificacionOficial: null,
        comprobanteDomicilio: null,
        caratulaEstadoCuenta: null,
        situacionFiscal: null,
        opinionCumplimiento: null,
        referenciaComercial1: null,
        referenciaComercial2: null,
        certificacion: null,
        fotografiaProducto1: null,
        fotografiaProducto2: null,
        fotografiaProducto3: null,
        fotografiaProducto4: null,
        curriculumEmpresa: null,
        actaConstitutiva: null,
        poderRepresentante: null,
        identificacionRepresentante: null,
        fachadaEmpresa1: null,
        fachadaEmpresa2: null,
        fachadaEmpresa3: null,
        fachadaEmpresa4: null,
        cedulaIdentificacionFiscal: null,
        curp: null,
        solicitudCertificacion: null,
        solicitudCertificacionMorales: null,
        curpRepresentante: null,
        cedulaFiscalMoral: null,
        testimonioEscritura: null,
        actualizadoNombreCompleto: null,
        actualizadoNombreContacto: null,
        actualizadoTelefonoContacto: null,
        actualizadoCorreoContacto: null,
        actualizadoRazonSocial: null,
        actualizadoRFC: null,
        actualizadoDomicilioFiscal: null,
        actualizadoCodigoPostal: null,
        actualizadoDireccion: null,
        actualizadoColonia: null,
        actualizadoNumeroFiscal: null,
        actualizadoDireccionFiscal: null,
        actualizadoCiudad: null,
        actualizadoRegion: null,
        actualizadoTelefono: null,
        actualizadoCorreo: null,
        actualizadoNombreBeneficiario: null,
        actualizadoClabeInt: null,
        actualizadoClaveInternacional: null,
        actualizadoCuenta: null,
        actualizadoCIE: null,
        actualizadoReferencia: null,
        actualizadoBanco: null,
        actualizadoSwift: null,
        actualizadoISBN: null,
        actualizadoCurriculumVitae: null,
        actualizadoIdentificacionOficial: null,
        actualizadoComprobanteDomicilio: null,
        actualizadoCaratulaEstadoCuenta: null,
        actualizadoSituacionFiscal: null,
        actualizadoOpinionCumplimiento: null,
        actualizadoReferenciaComercial1: null,
        actualizadoReferenciaComercial2: null,
        actualizadoCertificacion: null,
        actualizadoFotografiaProducto1: null,
        actualizadoFotografiaProducto2: null,
        actualizadoFotografiaProducto3: null,
        actualizadoFotografiaProducto4: null,
        actualizadoCurriculumEmpresa: null,
        actualizadoActaConstitutiva: null,
        actualizadoPoderRepresentante: null,
        actualizadoIdentificacionRepresentante: null,
        actualizadoFachadaEmpresa1: null,
        actualizadoFachadaEmpresa2: null,
        actualizadoFachadaEmpresa3: null,
        actualizadoFachadaEmpresa4: null,
        actualizadoCedulaIdentificacionFiscal: null,
        actualizadoCurp: null,
        actualizadoSolicitudCertificacion: null,
        actualizadoSolicitudCertificacionMorales: null,
        actualizadoCurpRepresentante: null,
        actualizadoCedulaFiscalMoral: null,
        actualizadoTestimonioEscritura: null,
        anteriorErrorNombreCompleto: null,
        anteriorErrorNombreContacto: null,
        anteriorErrorTelefonoContacto: null,
        anteriorErrorCorreoContacto: null,
        anteriorErrorRazonSocial: null,
        anteriorErrorRFC: null,
        anteriorErrorDomicilioFiscal: null,
        anteriorErrorCodigoPostal: null,
        anteriorErrorDireccion: null,
        anteriorErrorColonia: null,
        anteriorErrorNumeroFiscal: null,
        anteriorErrorDireccionFiscal: null,
        anteriorErrorCiudad: null,
        anteriorErrorRegion: null,
        anteriorErrorTelefono: null,
        anteriorErrorCorreo: null,
        anteriorErrorNombreBeneficiario: null,
        anteriorErrorClabeInt: null,
        anteriorErrorClaveInternacional: null,
        anteriorErrorCuenta: null,
        anteriorerrorCie: null,
        anteriorErrorReferencia: null,
        anteriorErrorBanco: null,
        anteriorErrorSwift: null,
        anteriorErrorISBN: null,
        anteriorErrorCurriculumVitae: null,
        anteriorErrorIdentificacionOficial: null,
        anteriorErrorComprobanteDomicilio: null,
        anteriorErrorCaratulaEstadoCuenta: null,
        anteriorErrorSituacionFiscal: null,
        anteriorErrorOpinionCumplimiento: null,
        anteriorErrorReferenciaComercial1: null,
        anteriorErrorReferenciaComercial2: null,
        anteriorErrorCertificacion: null,
        anteriorErrorFotografiaProducto1: null,
        anteriorErrorFotografiaProducto2: null,
        anteriorErrorFotografiaProducto3: null,
        anteriorErrorFotografiaProducto4: null,
        anteriorErrorCurriculumEmpresa: null,
        anteriorErrorActaConstitutiva: null,
        anteriorErrorPoderRepresentante: null,
        anteriorErrorIdentificacionRepresentante: null,
        anteriorErrorFachadaEmpresa1: null,
        anteriorErrorFachadaEmpresa2: null,
        anteriorErrorFachadaEmpresa3: null,
        anteriorErrorFachadaEmpresa4: null,
        anteriorErrorCedulaIdentificacionFiscal: null,
        anteriorErrorCurp: null,
        anteriorErrorSolicitudCertificacion: null,
        anteriorErrorSolicitudCertificacionMorales: null,
        anteriorErrorCurpRepresentante: null,
        anteriorErrorCedulaFiscalMoral: null,
        anteriorErrorTestimonioEscritura: null,
        anomaliaNombreCompleto: null,
        anomaliaNombreContacto: null,
        anomaliaTelefonoContacto: null,
        anomaliaCorreoContacto: null,
        anomaliaTipoProveedor: null,
        anomaliaTipoPersona: null,
        anomaliaRazonSocial: null,
        anomaliaRfc: null,
        anomaliaDomicilioFiscal: null,
        anomaliaEstado: null,
        anomaliaMunicipio: null,
        anomaliaRegimenFiscal: null,
        anomaliaCodigoPostal: null,
        anomaliaDireccion: null,
        anomaliaColonia: null,
        anomaliaNumeroFiscal: null,
        anomaliaDireccionFiscal: null,
        anomaliaCiudad: null,
        anomaliaRegion: null,
        anomaliaPais: null,
        anomaliaTelefono: null,
        anomaliaCorreo: null,
        anomaliaBanco: null,
        anomaliaNombreBeneficiario: null,
        anomaliaClaveInt: null,
        anomaliaCuenta: null,
        anomaliaMoneda: null,
        anomaliaCie: null,
        anomaliaReferencia: null,
        anomaliaSwift: null,
        anomaliaIsbn: null,
        anomaliaCurriculumVitaeEmpresarial: null,
        anomaliaIdentificacionOficial: null,
        anomaliaComprobanteDomicilio: null,
        anomaliaCaratulaEstadoCuenta: null,
        anomaliaSituacionFiscal: null,
        anomaliaOpinionCumplimiento: null,
        anomaliaReferenciaComercial1: null,
        anomaliaReferenciaComercial2: null,
        anomaliaCertificacion: null,
        anomaliaFotografiaProducto1: null,
        anomaliaFotografiaProducto2: null,
        anomaliaFotografiaProducto3: null,
        anomaliaFotografiaProducto4: null,
        anomaliaCurriculumEmpresa: null,
        anomaliaActaConstitutiva: null,
        anomaliaPoderRepresentante: null,
        anomaliaIdentificacionRepresentante: null,
        anomaliaFachadaEmpresa1: null,
        anomaliaFachadaEmpresa2: null,
        anomaliaFachadaEmpresa3: null,
        anomaliaFachadaEmpresa4: null,
        anomaliaCedulaIdentificacionFiscal: null,
        anomaliaCurp: null,
        anomaliaSolicitudCertificacion: null,
        anomaliaSolicitudCertificacionMorales: null,
        anomaliaCurpRepresentante: null,
        anomaliaCedulaFiscalMoral: null,
        anomaliaTestimonioEscritura: null,
        errorNombreCompleto: null,
        errorNombreContacto: null,
        errorTelefonoContacto: null,
        errorCorreoContacto: null,
        errorTipoProveedor: null,
        errorTipoPersona: null,
        errorRazonSocial: null,
        errorRfc: null,
        errorDomicilioFiscal: null,
        errorEstado: null,
        errorMunicipio: null,
        errorRegimenFiscal: null,
        errorCodigoPostal: null,
        errorDireccion: null,
        errorColonia: null,
        errorNumeroFiscal: null,
        errorDireccionFiscal: null,
        errorCiudad: null,
        errorRegion: null,
        errorPais: null,
        errorTelefono: null,
        errorCorreo: null,
        errorBanco: null,
        errorNombreBeneficiario: null,
        errorClaveInt: null,
        errorCuenta: null,
        errorMoneda: null,
        errorCie: null,
        errorReferencia: null,
        errorSwift: null,
        errorIsbn: null,
        errorCurriculumVitaeEmpresarial: null,
        errorIdentificacionOficial: null,
        errorComprobanteDomicilio: null,
        errorCaratulaEstadoCuenta: null,
        errorSituacionFiscal: null,
        errorOpinionCumplimiento: null,
        errorReferenciaComercial1: null,
        errorReferenciaComercial2: null,
        errorCertificacion: null,
        errorFotografiaProducto1: null,
        errorFotografiaProducto2: null,
        errorFotografiaProducto3: null,
        errorFotografiaProducto4: null,
        errorCurriculumEmpresa: null,
        errorActaConstitutiva: null,
        errorPoderRepresentante: null,
        errorIdentificacionRepresentante: null,
        errorFachadaEmpresa1: null,
        errorFachadaEmpresa2: null,
        errorFachadaEmpresa3: null,
        errorFachadaEmpresa4: null,
        errorCedulaIdentificacionFiscal: null,
        errorCurp: null,
        errorSolicitudCertificacion: null,
        errorSolicitudCertificacionMorales: null,
        errorCurpRepresentante: null,
        errorCedulaFiscalMoral: null,
        errorTestimonioEscritura: null,
      },

      loadingPaises: false,
      paises: [],

      tiposPersonas: [
        { id_tipo_persona: 1, nombre: "Física" },
        { id_tipo_persona: 2, nombre: "Moral" },
      ],
      tiposProveedor: [
        {
          id_tipo_proveedor: 1,
          nombre: "Proveedor Nacional",
        },
        {
          id_tipo_proveedor: 2,
          nombre: "Proveedor Internacional",
        },
      ],

      estados: [],
      loadingEstados: false,
      
      municipios: [],
      loadingMunicipios: false,
      
      regimenFiscales: [],
      loadingRegimenFiscales: false,

      bancos: [],
      loadingBancos: false,
      
      monedas: [],
      loadingMonedas: false,
      
      saving: false
    };
  },
  computed: {
    ...mapState(['token']),
  },
  mounted() {
    this.getInvitaciones();
    this.getEstados();
    this.getMonedas();
    this.getBancos();
    this.getPaises();
    this.getRegimenesFiscales();
  },
  methods: {
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    openDialogEmail(){
      this.emailItem = Object.assign({}, this.defaultEmailItem);
      this.dialogEmail = true;
    },
    closeDialogEmail(){
      this.emailItem = Object.assign({}, this.defaultEmailItem);
      this.dialogEmail = false;
    },
    sendEmail(){
      this.loadingEmail = true;

      axios
      .post('/Proveedores/CrearInvitacion',{
        email: this.emailItem.email
      })
      .then(() => {
        this.closeDialogEmail();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingEmail = false;
      })
    },
    getInvitaciones(){
      this.loading = true;
      axios
      .get('/Proveedores/ListarProveedorUsuarios')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() =>{
        this.loading = false;
      })
    },
    getMonedas(){
      this.loadingMonedas = true;
      axios
        .get('/Catalogos/ListarMonedas')
        .then(response => {
          this.monedas = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMonedas = false;
        })
    },
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    getMunicipios(){
      if(this.editedItem.id_estado == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItem.id_estado}`)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    getBancos(){
      this.loadingBancos = true;
      axios
        .get('/Catalogos/ListarBancos')
        .then(response => {
          this.bancos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingBancos = false;
        })
    },
    getPaises(){
      this.loadingPaises = true;
      axios
        .get('/Catalogos/ListarPaises')
        .then(response => {
          this.paises = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingPaises = false;
        })
    },
    getRegimenesFiscales(){
      this.loadingRegimenFiscales = true;
      axios
        .get('/Catalogos/ListarRegimenFiscales')
        .then(response => {
          this.regimenFiscales = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRegimenFiscales = false;
        })
    },
    openDialogValidate(item){
      this.dialog = true;

      axios
      .get(`/Proveedores/ObtenerInformacionRevision/${item.id_proveedor_usuario}`)
      .then(respose => {
        var obj = Object.assign({}, this.defaultEditedItem);
        this.editedItem = Object.assign(obj, respose.data);

        this.getMunicipios();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {

      })
    },
    validate() {
      const fieldsToValidate = [
        // Datos Generales
        {
          field: 'nombreCompleto',
          errorField: 'errorNombreCompleto',
          anomalyField: 'anomaliaNombreCompleto',
          fieldName: 'Nombre Completo del Proveedor',
          required: () => true,
        },
        {
          field: 'nombreContacto',
          errorField: 'errorNombreContacto',
          anomalyField: 'anomaliaNombreContacto',
          fieldName: 'Nombre del Contacto',
          required: () => true,
        },
        {
          field: 'telefonoContacto',
          errorField: 'errorTelefonoContacto',
          anomalyField: 'anomaliaTelefonoContacto',
          fieldName: 'Teléfono de Contacto',
          required: () => true,
        },
        {
          field: 'correoContacto',
          errorField: 'errorCorreoContacto',
          anomalyField: 'anomaliaCorreoContacto',
          fieldName: 'Correo de Contacto',
          required: () => true,
        },

        // Datos Fiscales
        {
          field: 'tipoProveedor',
          errorField: 'errorTipoProveedor',
          anomalyField: 'anomaliaTipoProveedor',
          fieldName: 'Tipo de Proveedor',
          required: () => true,
        },
        {
          field: 'tipoPersona',
          errorField: 'errorTipoPersona',
          anomalyField: 'anomaliaTipoPersona',
          fieldName: 'Tipo de Persona',
          required: () => true,
        },
        {
          field: 'razonSocial',
          errorField: 'errorRazonSocial',
          anomalyField: 'anomaliaRazonSocial',
          fieldName: 'Nombre/Razón Social',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'rfc',
          errorField: 'errorRfc',
          anomalyField: 'anomaliaRfc',
          fieldName: 'RFC',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'domicilioFiscal',
          errorField: 'errorDomicilioFiscal',
          anomalyField: 'anomaliaDomicilioFiscal',
          fieldName: 'Domicilio Fiscal',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'id_estado',
          errorField: 'errorEstado',
          anomalyField: 'anomaliaEstado',
          fieldName: 'Estado',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'id_municipio',
          errorField: 'errorMunicipio',
          anomalyField: 'anomaliaMunicipio',
          fieldName: 'Municipio',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'regimenFiscal',
          errorField: 'errorRegimenFiscal',
          anomalyField: 'anomaliaRegimenFiscal',
          fieldName: 'Régimen Fiscal',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'codigoPostal',
          errorField: 'errorCodigoPostal',
          anomalyField: 'anomaliaCodigoPostal',
          fieldName: 'Código Postal',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'direccion',
          errorField: 'errorDireccion',
          anomalyField: 'anomaliaDireccion',
          fieldName: 'Dirección',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'colonia',
          errorField: 'errorColonia',
          anomalyField: 'anomaliaColonia',
          fieldName: 'Colonia',
          required: () => this.editedItem.tipoProveedor == 1,
        },

        // Cuenta Bancaria
        {
          field: 'id_banco',
          errorField: 'errorBanco',
          anomalyField: 'anomaliaBanco',
          fieldName: 'Banco',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'nombreBeneficiario',
          errorField: 'errorNombreBeneficiario',
          anomalyField: 'anomaliaNombreBeneficiario',
          fieldName: 'Nombre del Beneficiario',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'claveInt',
          errorField: 'errorClaveInt',
          anomalyField: 'anomaliaClaveInt',
          fieldName: 'CLABE Interbancaria',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'cuenta',
          errorField: 'errorCuenta',
          anomalyField: 'anomaliaCuenta',
          fieldName: 'Cuenta',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'id_moneda',
          errorField: 'errorMoneda',
          anomalyField: 'anomaliaMoneda',
          fieldName: 'Moneda',
          required: () => this.editedItem.tipoProveedor == 1,
        },

        // Documentos Adjuntos
        {
          field: 'curriculumVitaeEmpresarial',
          errorField: 'errorCurriculumVitaeEmpresarial',
          anomalyField: 'anomaliaCurriculumVitaeEmpresarial',
          fieldName: 'Currículum Vitae Empresarial',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'identificacionOficial',
          errorField: 'errorIdentificacionOficial',
          anomalyField: 'anomaliaIdentificacionOficial',
          fieldName: 'Identificación Oficial',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'comprobanteDomicilio',
          errorField: 'errorComprobanteDomicilio',
          anomalyField: 'anomaliaComprobanteDomicilio',
          fieldName: 'Comprobante de Domicilio',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'caratulaEstadoCuenta',
          errorField: 'errorCaratulaEstadoCuenta',
          anomalyField: 'anomaliaCaratulaEstadoCuenta',
          fieldName: 'Carátula del Estado de Cuenta',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'situacionFiscal',
          errorField: 'errorSituacionFiscal',
          anomalyField: 'anomaliaSituacionFiscal',
          fieldName: 'Constancia de Situación Fiscal',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'opinionCumplimiento',
          errorField: 'errorOpinionCumplimiento',
          anomalyField: 'anomaliaOpinionCumplimiento',
          fieldName: 'Opinión de Cumplimiento',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'referenciaComercial1',
          errorField: 'errorReferenciaComercial1',
          anomalyField: 'anomaliaReferenciaComercial1',
          fieldName: 'Carta de Referencia Comercial 1',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'referenciaComercial2',
          errorField: 'errorReferenciaComercial2',
          anomalyField: 'anomaliaReferenciaComercial2',
          fieldName: 'Carta de Referencia Comercial 2',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'certificacion',
          errorField: 'errorCertificacion',
          anomalyField: 'anomaliaCertificacion',
          fieldName: 'Certificación',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'fotografiaProducto1',
          errorField: 'errorFotografiaProducto1',
          anomalyField: 'anomaliaFotografiaProducto1',
          fieldName: 'Fotografía del Producto o Servicio 1',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'fotografiaProducto2',
          errorField: 'errorFotografiaProducto2',
          anomalyField: 'anomaliaFotografiaProducto2',
          fieldName: 'Fotografía del Producto o Servicio 2',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'fotografiaProducto3',
          errorField: 'errorFotografiaProducto3',
          anomalyField: 'anomaliaFotografiaProducto3',
          fieldName: 'Fotografía del Producto o Servicio 3',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'fotografiaProducto4',
          errorField: 'errorFotografiaProducto4',
          anomalyField: 'anomaliaFotografiaProducto4',
          fieldName: 'Fotografía del Producto o Servicio 4',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 1,
        },
        // Continuación de Cuenta Bancaria
        {
          field: 'cie',
          errorField: 'errorCie',
          anomalyField: 'anomaliaCie',
          fieldName: 'CIE',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'referencia',
          errorField: 'errorReferencia',
          anomalyField: 'anomaliaReferencia',
          fieldName: 'Referencia',
          required: () => this.editedItem.tipoProveedor == 1,
        },
        {
          field: 'banco',
          errorField: 'errorBanco',
          anomalyField: 'anomaliaBanco',
          fieldName: 'Banco Internacional',
          required: () => this.editedItem.tipoProveedor == 2,
        },
        {
          field: 'swift',
          errorField: 'errorSwift',
          anomalyField: 'anomaliaSwift',
          fieldName: 'SWIFT',
          required: () => this.editedItem.tipoProveedor == 2,
        },
        {
          field: 'isbn',
          errorField: 'errorIsbn',
          anomalyField: 'anomaliaIsbn',
          fieldName: 'ISBN',
          required: () => this.editedItem.tipoProveedor == 2,
        },

        // Continuación de Documentos Adjuntos
        {
          field: 'curriculumEmpresa',
          errorField: 'errorCurriculumEmpresa',
          anomalyField: 'anomaliaCurriculumEmpresa',
          fieldName: 'Currículum de la Empresa',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'actaConstitutiva',
          errorField: 'errorActaConstitutiva',
          anomalyField: 'anomaliaActaConstitutiva',
          fieldName: 'Acta Constitutiva',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'poderRepresentante',
          errorField: 'errorPoderRepresentante',
          anomalyField: 'anomaliaPoderRepresentante',
          fieldName: 'Poder Notariado del Representante Legal',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'identificacionRepresentante',
          errorField: 'errorIdentificacionRepresentante',
          anomalyField: 'anomaliaIdentificacionRepresentante',
          fieldName: 'Identificación del Representante Legal',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'fachadaEmpresa1',
          errorField: 'errorFachadaEmpresa1',
          anomalyField: 'anomaliaFachadaEmpresa1',
          fieldName: 'Fotografía de la Fachada Empresa 1',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'fachadaEmpresa2',
          errorField: 'errorFachadaEmpresa2',
          anomalyField: 'anomaliaFachadaEmpresa2',
          fieldName: 'Fotografía de la Fachada Empresa 2',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'fachadaEmpresa3',
          errorField: 'errorFachadaEmpresa3',
          anomalyField: 'anomaliaFachadaEmpresa3',
          fieldName: 'Fotografía de la Fachada Empresa 3',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'fachadaEmpresa4',
          errorField: 'errorFachadaEmpresa4',
          anomalyField: 'anomaliaFachadaEmpresa4',
          fieldName: 'Fotografía de la Fachada Empresa 4',
          required: () => this.editedItem.tipoProveedor == 1 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'cedulaIdentificacionFiscal',
          errorField: 'errorCedulaIdentificacionFiscal',
          anomalyField: 'anomaliaCedulaIdentificacionFiscal',
          fieldName: 'Cédula de Identificación Fiscal',
          required: () => this.editedItem.tipoProveedor == 2 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'curp',
          errorField: 'errorCurp',
          anomalyField: 'anomaliaCurp',
          fieldName: 'CURP',
          required: () => this.editedItem.tipoProveedor == 2 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'solicitudCertificacion',
          errorField: 'errorSolicitudCertificacion',
          anomalyField: 'anomaliaSolicitudCertificacion',
          fieldName: 'Solicitud de Certificación',
          required: () => this.editedItem.tipoProveedor == 2 && this.editedItem.tipoPersona == 1,
        },
        {
          field: 'cedulaFiscalMoral',
          errorField: 'errorCedulaFiscalMoral',
          anomalyField: 'anomaliaCedulaFiscalMoral',
          fieldName: 'Cédula Fiscal de la Persona Moral',
          required: () => this.editedItem.tipoProveedor == 2 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'testimonioEscritura',
          errorField: 'errorTestimonioEscritura',
          anomalyField: 'anomaliaTestimonioEscritura',
          fieldName: 'Testimonio de la Escritura Pública',
          required: () => this.editedItem.tipoProveedor == 2 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'curpRepresentante',
          errorField: 'errorCurpRepresentante',
          anomalyField: 'anomaliaCurpRepresentante',
          fieldName: 'CURP del Representante Legal',
          required: () => this.editedItem.tipoProveedor == 2 && this.editedItem.tipoPersona == 2,
        },
        {
          field: 'solicitudCertificacionMorales',
          errorField: 'errorSolicitudCertificacionMorales',
          anomalyField: 'anomaliaSolicitudCertificacionMorales',
          fieldName: 'Solicitud de Certificación para Personas Morales',
          required: () => this.editedItem.tipoProveedor == 2 && this.editedItem.tipoPersona == 2,
        }
      ];

      for (const field of fieldsToValidate) {
        if (field.required && !field.required()) {
          continue; // Si no es requerido, omite la validación
        }

        if (this.editedItem[field.errorField] === false) {
          if (
            !this.editedItem[field.anomalyField] ||
            this.editedItem[field.anomalyField].length < 5
          ) {
            this.$refs.alert.setMessage(
              '',
              `Debe de indicar la anomalía del campo ${field.fieldName}`
            );
            this.$refs.alert.open();
            return false;
          }
        }
        else if(this.editedItem[field.errorField] == null){
          this.$refs.alert.setMessage(
              '',
              `Debe de indicar si el campo ${field.fieldName} es correcto o incorrecto`
            );
            this.$refs.alert.open();
            return false;
        }
      }
      return true;
    },
    save(id_estado_carga){
      if(!this.validate()) return;

      this.saving = true;
      axios
      .post('/Proveedores/GuardarEstadoRevision',{
         id_proveedor_carga_externa: this.editedItem.id_proveedor_carga_externa,
         id_estado_carga_externa: id_estado_carga,
         errorNombreCompleto:  (!this.editedItem.errorNombreCompleto) ? this.editedItem.anomaliaNombreCompleto : null,
         errorNombreContacto:  (!this.editedItem.errorNombreContacto) ? this.editedItem.anomaliaNombreContacto : null,
         errorTelefonoContacto:  (!this.editedItem.errorTelefonoContacto) ? this.editedItem.anomaliaTelefonoContacto : null,
         errorCorreoContacto:  (!this.editedItem.errorCorreoContacto) ? this.editedItem.anomaliaCorreoContacto : null,
         errorRazonSocial:  (!this.editedItem.errorRazonSocial) ? this.editedItem.anomaliaRazonSocial : null,
         errorRFC:  (!this.editedItem.errorRFC) ? this.editedItem.anomaliaRFC : null,
         errorDomicilioFiscal:  (!this.editedItem.errorDomicilioFiscal) ? this.editedItem.anomaliaDomicilioFiscal : null,
         errorCodigoPostal:  (!this.editedItem.errorCodigoPostal) ? this.editedItem.anomaliaCodigoPostal : null,
         errorDireccion:  (!this.editedItem.errorDireccion) ? this.editedItem.anomaliaDireccion : null,
         errorColonia:  (!this.editedItem.errorColonia) ? this.editedItem.anomaliaColonia : null,
         errorNumeroFiscal:  (!this.editedItem.errorNumeroFiscal) ? this.editedItem.anomaliaNumeroFiscal : null,
         errorDireccionFiscal:  (!this.editedItem.errorDireccionFiscal) ? this.editedItem.anomaliaDireccionFiscal : null,
         errorCiudad:  (!this.editedItem.errorCiudad) ? this.editedItem.anomaliaCiudad : null,
         errorRegion:  (!this.editedItem.errorRegion) ? this.editedItem.anomaliaRegion : null,
         errorTelefono:  (!this.editedItem.errorTelefono) ? this.editedItem.anomaliaTelefono : null,
         errorCorreo:  (!this.editedItem.errorCorreo) ? this.editedItem.anomaliaCorreo : null,
         errorNombreBeneficiario:  (!this.editedItem.errorNombreBeneficiario) ? this.editedItem.anomaliaNombreBeneficiario : null,
         errorClabeInt:  (!this.editedItem.errorClaveInt) ? this.editedItem.anomaliaClaveInt : null,
         errorCuenta:  (!this.editedItem.errorCuenta) ? this.editedItem.anomaliaCuenta : null,
         errorCie:  (!this.editedItem.errorCie) ? this.editedItem.anomaliaCie : null,
         errorReferencia:  (!this.editedItem.errorReferencia) ? this.editedItem.anomaliaReferencia : null,
         errorBanco:  (!this.editedItem.errorBanco) ? this.editedItem.anomaliaBanco : null,
         errorSwift:  (!this.editedItem.errorSwift) ? this.editedItem.anomaliaSwift : null,
         errorISBN:  (!this.editedItem.errorISBN) ? this.editedItem.anomaliaISBN : null,
         errorCurriculumVitae:  (!this.editedItem.errorCurriculumVitae) ? this.editedItem.anomaliaCurriculumVitae : null,
         errorIdentificacionOficial:  (!this.editedItem.errorIdentificacionOficial) ? this.editedItem.anomaliaIdentificacionOficial : null,
         errorComprobanteDomicilio:  (!this.editedItem.errorComprobanteDomicilio) ? this.editedItem.anomaliaComprobanteDomicilio : null,
         errorCaratulaEstadoCuenta:  (!this.editedItem.errorCaratulaEstadoCuenta) ? this.editedItem.anomaliaCaratulaEstadoCuenta : null,
         errorSituacionFiscal:  (!this.editedItem.errorSituacionFiscal) ? this.editedItem.anomaliaSituacionFiscal : null,
         errorOpinionCumplimiento:  (!this.editedItem.errorOpinionCumplimiento) ? this.editedItem.anomaliaOpinionCumplimiento : null,
         errorReferenciaComercial1:  (!this.editedItem.errorReferenciaComercial1) ? this.editedItem.anomaliaReferenciaComercial1 : null,
         errorReferenciaComercial2:  (!this.editedItem.errorReferenciaComercial2) ? this.editedItem.anomaliaReferenciaComercial2 : null,
         errorCertificacion:  (!this.editedItem.errorCertificacion) ? this.editedItem.anomaliaCertificacion : null,
         errorFotografiaProducto1:  (!this.editedItem.errorFotografiaProducto1) ? this.editedItem.anomaliaFotografiaProducto1 : null,
         errorFotografiaProducto2:  (!this.editedItem.errorFotografiaProducto2) ? this.editedItem.anomaliaFotografiaProducto2 : null,
         errorFotografiaProducto3:  (!this.editedItem.errorFotografiaProducto3) ? this.editedItem.anomaliaFotografiaProducto3 : null,
         errorFotografiaProducto4:  (!this.editedItem.errorFotografiaProducto4) ? this.editedItem.anomaliaFotografiaProducto4 : null,
         errorCurriculumEmpresa:  (!this.editedItem.errorCurriculumEmpresa) ? this.editedItem.anomaliaCurriculumEmpresa : null,
         errorActaConstitutiva:  (!this.editedItem.errorActaConstitutiva) ? this.editedItem.anomaliaActaConstitutiva : null,
         errorPoderRepresentante:  (!this.editedItem.errorPoderRepresentante) ? this.editedItem.anomaliaPoderRepresentante : null,
         errorIdentificacionRepresentante:  (!this.editedItem.errorIdentificacionRepresentante) ? this.editedItem.anomaliaIdentificacionRepresentante : null,
         errorFachadaEmpresa1:  (!this.editedItem.errorFachadaEmpresa1) ? this.editedItem.anomaliaFachadaEmpresa1 : null,
         errorFachadaEmpresa2:  (!this.editedItem.errorFachadaEmpresa2) ? this.editedItem.anomaliaFachadaEmpresa2 : null,
         errorFachadaEmpresa3:  (!this.editedItem.errorFachadaEmpresa3) ? this.editedItem.anomaliaFachadaEmpresa3 : null,
         errorFachadaEmpresa4:  (!this.editedItem.errorFachadaEmpresa4) ? this.editedItem.anomaliaFachadaEmpresa4 : null,
         errorCedulaIdentificacionFiscal:  (!this.editedItem.errorCedulaIdentificacionFiscal) ? this.editedItem.anomaliaCedulaIdentificacionFiscal : null,
         errorCurp:  (!this.editedItem.errorCurp) ? this.editedItem.anomaliaCurp : null,
         errorSolicitudCertificacion:  (!this.editedItem.errorSolicitudCertificacion) ? this.editedItem.anomaliaSolicitudCertificacion : null,
         errorSolicitudCertificacionMorales:  (!this.editedItem.errorSolicitudCertificacionMorales) ? this.editedItem.anomaliaSolicitudCertificacionMorales : null,
         errorCurpRepresentante:  (!this.editedItem.errorCurpRepresentante) ? this.editedItem.anomaliaCurpRepresentante : null,
         errorCedulaFiscalMoral:  (!this.editedItem.errorCedulaFiscalMoral) ? this.editedItem.anomaliaCedulaFiscalMoral : null,
         errorTestimonioEscritura:  (!this.editedItem.errorTestimonioEscritura) ? this.editedItem.anomaliaTestimonioEscritura : null,
         errorTipoPersona:  (!this.editedItem.errorTipoPersona) ? this.editedItem.anomaliaTipoPersona : null,
         errorTipoProveedor:  (!this.editedItem.errorTipoProveedor) ? this.editedItem.anomaliaTipoProveedor : null,
         errorEstado:  (!this.editedItem.errorEstado) ? this.editedItem.anomaliaEstado : null,
         errorMunicipio:  (!this.editedItem.errorMunicipio) ? this.editedItem.anomaliaMunicipio : null,
         errorRegimenFiscal:  (!this.editedItem.errorRegimenFiscal) ? this.editedItem.anomaliaRegimenFiscal : null,
         errorPais:  (!this.editedItem.errorPais) ? this.editedItem.anomaliaPais : null,
         errorMoneda:  (!this.editedItem.errorMoneda) ? this.editedItem.anomaliaMoneda : null,
         errorCurriculumVitaeEmpresarial:  (!this.editedItem.errorCurriculumVitaeEmpresarial) ? this.editedItem.anomaliaCurriculumVitaeEmpresarial : null,
      })
      .then(() => {
        this.closeDialog();
      })
      .finally(() => {
        this.saving = false;
      })
    },
    closeDialog(){
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.getInvitaciones();
    }
  },
};
</script>

<style scoped>
.v-divider {
  margin: 10px 0;
}
.error-input {
  border-color: red !important;
}
</style>
