<template>
  <v-dialog
    v-model="dialogConsentimiento"
    persistent
    max-width="85%"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-card>
      <v-card-title>
        Autorización para la realización de Estudios
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="tab">
          <v-stepper-header>
            <template
              v-if="editedItem.consentimiento_estudios"
            >
              <v-stepper-step
                :complete="tab > 1"
                step="1"
              >
                Autorización Realización de Estudios
              </v-stepper-step>

              <v-divider></v-divider>
            </template>

            <template
              v-if="editedItem.consentimiento_cancer"
            >
              <v-stepper-step  
                :complete="tab > 2"
                step="2"
              >
                Autorización Detección de Cáncer de Mama
              </v-stepper-step>

              <v-divider></v-divider>
            </template>

            <v-stepper-step step="3"
              v-if="editedItem.consentimiento_covid"
            >
              Autorización Prueba Rápida de SARS-COV-2
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              v-if="editedItem.consentimiento_estudios"
              step="1"
            >
              <v-card>
                <v-card-title>
                  Autorización para Realización de Estudio
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="3">
                      <span class="font-weight-bold">Fecha de Nacimiento:</span> {{consulta_medica.paciente.fecha_nacimiento}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Edad:</span> {{consulta_medica.paciente.edad}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Nombre:</span> {{consulta_medica.paciente.nombre}} {{consulta_medica.paciente.apellido_paterno}} {{consulta_medica.paciente.apellido_materno}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Sexo:</span> {{consulta_medica.paciente.genero ? 'Masculino' : 'Femenino'}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Localidad de Atención:</span> {{consulta_medica.paciente.localidad_atencion}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Municipio:</span> {{consulta_medica.paciente.municipio}}
                    </v-col>
                    <v-col cols="12">
                      <span class="font-weight-bold">Estudios a Realizar:</span>
                      <br>
                      <ul>
                        <li
                          v-for="(servicio,idx) in otros_servicios"
                          :key="'s-'+idx"
                        >
                          {{servicio}}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12"
                      class="text-body-1"
                    >
                      Por medio de la presente hago constar que autoricé a Un Buen Grupo de Amigos A.C. para realizar e interpretar el estudio indicado.
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="4">
                      <v-card>
                        <v-container class="text-center font-weight-bold">
                          <TopazSignature
                            v-if="mostrar_firma1"
                            ref="paciente1"
                            id="paciente1"
                          ></TopazSignature>
                          <div>
                            {{consulta_medica.paciente.nombre}} {{consulta_medica.paciente.apellido_paterno}} {{consulta_medica.paciente.apellido_materno}}
                          </div>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="4"></v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green"
                    text
                    @click="validarEstudios"
                  >
                    Aceptar y Continuar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content
              v-if="editedItem.consentimiento_cancer"
              step="2"
            >
              <v-card>
                <v-card-title>
                  Carta de Consentimiento Informado para Estudios de Detección Oportuna del Cáncer de Mama
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="3">
                      <span class="font-weight-bold">Fecha de Nacimiento:</span> {{consulta_medica.paciente.fecha_nacimiento}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Edad:</span> {{consulta_medica.paciente.edad}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Nombre:</span> {{consulta_medica.paciente.nombre}} {{consulta_medica.paciente.apellido_paterno}} {{consulta_medica.paciente.apellido_materno}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Sexo:</span> {{consulta_medica.paciente.genero ? 'Masculino' : 'Femenino'}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Localidad de Atención:</span> {{consulta_medica.paciente.localidad_atencion}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Municipio:</span> {{consulta_medica.paciente.municipio}}
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="editedItem.identificado_con"
                        label="Identificado Con"
                        dense
                        hide-details
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="editedItem.curp"
                        label="CURP"
                        dense
                        hide-details
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12"
                      v-if="mastografia"
                    >
                      <div class="subtitle-1 font-weight-bold">
                        Mastografia
                      </div>
                      <div>
                        La mastografía es un estudio radiológico de la glándula mamaria, no invasivo, el cual es tomado con un aparato (mastógrafo) diseñado especialmente para facilitar la visualización interna de los tejidos de la mama a través de los RX, es un estudio muy importante para detectar tumoraciones malignas en caso de existir, éstas pueden ser muy pequeñas, no palpables ni visibles, es por esto que se utiliza como uno de los métodos para la detección oportuna del cáncer de mama.
                      </div>
                      <br>
                      <div class="font-weight-bold">
                        Por medio del presente manifiesto haber sido informada sobre el tipo de procedimientos que se realizarán en mi persona y sus beneficios, por lo que autorizo al personal de salud de la Unidad Médica Móvil realizarme el estudio de detección de Cáncer de Mama con Mastografía, lo anterior con fundamento en la Norma Oficial Mexicana NOM - 004 - SSA3 - 2012 del Expediente Clínico y la NOM 041 - SSA2 – 2011 para la prevención, diagnóstico, tratamiento, control y vigilancia epidemiológica del Cáncer de Mama.
                      </div>
                    </v-col>
                    <v-col cols="12"
                      v-if="usg"
                    >
                      <div class="subtitle-1 font-weight-bold">
                        USG
                      </div>
                      <div>
                        La detección del Cáncer de Mama mediante el ultrasonido es un estudio no invasivo, en el cual se coloca un transductor en los cuatro cuadrantes en que se divide la mama, se aplica generosamente gel para facilitar la visualización interna de los tejidos de dicha glándula; es un estudio muy importante para detectar oportunamente si las hubiera, tumoraciones malignas en mujeres jóvenes.
                      </div>
                      <br>
                      <div class="font-weight-bold">
                        Por medio del presente manifiesto haber sido informada sobre el tipo de procedimientos que se realizarán en mi persona y sus beneficios, por lo que autorizo al personal de salud de la Unidad Médica Móvil realizarme el estudio de detección de Cáncer de Mama con ultrasonido, lo anterior con fundamento en la Norma Oficial Mexicana NOM - 004 - SSA3 - 2012 del Expediente Clínico y la NOM 041 - SSA2 - 2011 para la prevención, diagnóstico, tratamiento, control y vigilancia epidemiológica del Cáncer de Mama.
                      </div>
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="4">
                      <v-card>
                        <v-container class="text-center font-weight-bold">
                          <TopazSignature
                            v-if="mostrar_firma2"
                            ref="paciente2"
                            id="paciente2"
                          ></TopazSignature>
                          <div>
                            {{consulta_medica.paciente.nombre}} {{consulta_medica.paciente.apellido_paterno}} {{consulta_medica.paciente.apellido_materno}}
                          </div>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="4"></v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="green"
                    @click="validarCancer"
                  >
                    Aceptar y Continuar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content
              v-if="editedItem.consentimiento_covid"
              step="3"
            >
              <v-card>
                <v-card-title>
                  Consentimiento Informado Para Aplicar Prueba Rápida de Antígenos para SARS-COV-2 (COVID-19)
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="3">
                      <span class="font-weight-bold">Fecha de Nacimiento:</span> {{consulta_medica.paciente.fecha_nacimiento}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Edad:</span> {{consulta_medica.paciente.edad}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Nombre:</span> {{consulta_medica.paciente.nombre}} {{consulta_medica.paciente.apellido_paterno}} {{consulta_medica.paciente.apellido_materno}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Sexo:</span> {{consulta_medica.paciente.genero ? 'Masculino' : 'Femenino'}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Localidad de Atención:</span> {{consulta_medica.paciente.localidad_atencion}}
                    </v-col>
                    <v-col cols="3">
                      <span class="font-weight-bold">Municipio:</span> {{consulta_medica.paciente.municipio}}
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="editedItem.identificado_con"
                        label="Identificado Con"
                        dense
                        hide-details
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="editedItem.curp"
                        label="CURP"
                        dense
                        hide-details
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="subtitle-1 font-weight-bold">
                        ¿Qué son las pruebas antigénicas?
                      </div>
                      <div>
                        Son inmunoensayos que se utilizan con frecuencia para el diagnóstico rápido de enfermedades infecciosas. Permiten detectar la presencia de proteínas de un agente infeccioso específico. En el caso del SARS-CoV-2, la prueba es capaz de detectar las proteínas de superficie del virus.
                      </div>
                      <br>
                      <div class="subtitle-1 font-weight-bold">
                        ¿Para qué sirve?
                      </div>
                      <div>
                        Las pruebas de antígeno utilizan el método del flujo lateral (tiras reactivas inmunocromatográficas rápidas), iguales a las que se usan en las pruebas de embarazo, que permiten en poco tiempo saber si una persona es un caso confirmado de SARS-CoV-2 al obtener un resultado positivo. De esta manera, facilita la aplicación rápida de las medidas de aislamiento. Un resultado negativo no descarta la presencia de infección y, en estos casos, si hay alta sospecha de contagio se tienen que aplicar otras pruebas diagnósticas.
                      </div>
                      <br>
                      <div class="subtitle-1 font-weight-bold">
                        ¿Como se realiza?
                      </div>
                      <div>
                        La prueba se realiza utilizando el método de flujo lateral o inmunocromatografía. Se puede utilizar la muestra obtenida mediante frotis nasofaríngeo.La muestra se aplica en el dispositivo, que se absorbe por acción capilar y se mezcla con un conjugado que fluye a través de una membrana revestida. Un conjugado es una mezcla de sustancias que contiene anticuerpos específicos para las proteínas de superficie del virus SARS-CoV-2. Si el resultado es positivo se observa una banda de color, esto significa que los anticuerpos se han unido a las proteínas del SARS-CoV-2 presentes en la muestra. Si no aparece banda de color en la línea de prueba, se considera como resultado negativo, pero no descarta la infección.
                        <br>
                        <br>
                        He recibido la información sobre lo que son las pruebas antigénicas, para qué sirven y cómo se realizan; me han explicado los beneficios y los riesgos de su aplicación. Autorizo a Un Buen Grupo de Amigos, A.C. para que me realicen la prueba mencionada.
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      Fecha de toma de muestra:
                      <date-picker v-model="editedItem.fecha_covid" type="datetime" valueType="format" format="YYYY-MM-DD HH:mm"></date-picker>
                    </v-col>
                    <v-col cols="6" class="align-self-center">
                      <v-radio-group
                        v-model="editedItem.resultado_covid"
                        row
                        dense
                        hide-details
                        class="pa-0 ma-0"
                      >
                        Resultado:
                        <v-radio
                          label="Positivo"
                          :value="true"
                          dense
                         hide-details
                        ></v-radio>
                        <v-radio
                          label="Negativo"
                          :value="false"
                          dense
                          hide-details
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4"></v-col>
                    <v-col cols="4">
                      <v-card>
                        <v-container class="text-center font-weight-bold">
                          <TopazSignature
                            v-if="mostrar_firma3"
                            ref="paciente3"
                            id="paciente3"
                          ></TopazSignature>
                          <div>
                            {{consulta_medica.paciente.nombre}} {{consulta_medica.paciente.apellido_paterno}} {{consulta_medica.paciente.apellido_materno}}
                          </div>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="4"></v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="green"
                    @click="validarCovid"
                  >
                    Aceptar y Continuar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>    
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
//import vueSignature from "vue-signature";
import TopazSignature from '@/components/TopazSignature.vue';
import VAlertDialog from '../../VAlertDialog.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapState } from 'vuex'

export default {
  components: {
    //vueSignature,
    DatePicker,
    TopazSignature,
    VAlertDialog
  },
  computed: {
    ...mapState(['extemporaneo']),
  },
  data: () => ({
    tab: 1,
    dialogConsentimiento: false,
    editedItem: {
      consentimiento_estudios: false,
      consentimiento_cancer: false,
      consentimiento_covid: false,
      identificado_con: null,
      curp: null,
      firma_estudios: null,
      firma_cancer: null,
      firma_covid: null,
      resultado_covid: null,
      fecha_covid: null
    },
    defaultEditedItem: {
      consentimiento_estudios: false,
      consentimiento_cancer: false,
      consentimiento_covid: false,
      identificado_con: null,
      curp: null,
      firma_estudios: null,
      firma_cancer: null,
      firma_covid: null,
      resultado_covid: null,
      fecha_covid: null
    },
    mostrar_firma1: false,
    mostrar_firma2: false,
    mostrar_firma3: false,
    servicios: [],
    loadingServicios: false,
    otros_servicios: [],
    consulta_medica: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      paciente: {
          id_paciente: null,
          id_municipio: null,
          municipio: "",
          id_estado: null,
          estado: "",
          id_localidad_atencion: null,
          localidad_atencion: "",
          folio: "",
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          fecha_nacimiento: "",
          edad: "",
          edad_anhios: 0,
          genero: true,
          curp: "",
          seguro_popular: false,
          num_poliza: "",
          telefono: "",
          email: ""
      },
      num_consultas_medicas: 0,
    },
    defaultConsultaMedica: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      paciente: {
          id_paciente: null,
          id_municipio: null,
          municipio: "",
          id_estado: null,
          estado: "",
          id_localidad_atencion: null,
          localidad_atencion: "",
          folio: "",
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          fecha_nacimiento: "",
          edad: "",
          edad_anhios: 0,
          genero: true,
          curp: "",
          seguro_popular: false,
          num_poliza: "",
          telefono: "",
          email: ""
      },
      num_consultas_medicas: 0,
    },
    mastografia: false,
    usg: false,
    option1: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    option2: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    option3: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  }),
  mounted(){
    this.getServicios();
  },
  methods: {
    mostrar(ids_servicios, consulta_medica){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.consulta_medica = Object.assign({}, consulta_medica);
      this.dialogConsentimiento = true;

      var ids_otros_servicios = ids_servicios
        .filter(id => 
          id != '97d17f26-9a0e-4dcf-9bf1-994de1fe7546' &&
          id != '6725973a-9fb2-4210-be4f-7b3727886e31' &&
          id != 'f431e0ca-c0ae-422e-8c39-d94e24306de2'
        );

      this.editedItem.consentimiento_estudios = this.servicios
        .filter(s => ids_otros_servicios.indexOf(s.id_servicio) >= 0 && s.consentimiento == true)
        .length > 0;

      this.otros_servicios = this.servicios
        .filter(s => ids_otros_servicios.indexOf(s.id_servicio) >= 0 && s.consentimiento == true)
        .map(s => s.nombre);

      this.mastografia = ids_servicios
        .filter(id => id == '97d17f26-9a0e-4dcf-9bf1-994de1fe7546')
        .length > 0;

      this.usg = ids_servicios
        .filter(id => id == '6725973a-9fb2-4210-be4f-7b3727886e31')
        .length > 0;

      this.editedItem.consentimiento_cancer = ids_servicios
        .filter(id => id == '97d17f26-9a0e-4dcf-9bf1-994de1fe7546' || id == '6725973a-9fb2-4210-be4f-7b3727886e31')
        .length > 0;

      this.editedItem.consentimiento_covid = ids_servicios
        .filter(id => id == 'f431e0ca-c0ae-422e-8c39-d94e24306de2')
        .length > 0;

      if(this.editedItem.consentimiento_estudios) {
        this.tab = 1;
        setTimeout(() => {
          this.mostrar_firma1 = true;
        }, 250);
      }
      else if(this.editedItem.consentimiento_cancer) {
        this.tab = 2;
        setTimeout(() => {
          this.mostrar_firma2 = true;
        }, 250);
      }
      else if(this.editedItem.consentimiento_covid) {
        this.tab = 3;
        setTimeout(() => {
          this.mostrar_firma3 = true;
        }, 250);
      }
    },
    getServicios(){
      this.loadingServicios = true;
      axios
      .get('/Catalogos/ListarServicios')
      .then(response => {
        this.servicios = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingServicios = false;
      })
    },
    validarEstudios(){
      if(this.$refs.paciente1.isEmpty() && !this.extemporaneo){
        this.$refs.alert.setMessage("", "Falta Ingresar la Firma del Paciente");
        this.$refs.alert.open();
        return;
      }

      this.editedItem.firma_estudios = (!this.extemporaneo) ? this.$refs.paciente1.save() : null;
      this.$refs.paciente1.clear();
      this.mostrar_firma1 = false;

      if(this.editedItem.consentimiento_cancer){
        this.tab = 2;
        setTimeout(() => {
          this.mostrar_firma2 = true;
          this.$refs.paciente2.clear();
        }, 250);
        
      }
      else if(this.editedItem.consentimiento_covid){
        this.tab = 3;
        setTimeout(() => {
          this.mostrar_firma3 = true;
          this.$refs.paciente3.clear();
        }, 250);
      }
      else {
        this.finalizar();
      }
    },
    validarCancer(){
      if(this.editedItem.identificado_con == null || this.editedItem.identificado_con.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar la Forma en que se Identificó el Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.curp == null || this.editedItem.curp.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar la CURP del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.$refs.paciente2.isEmpty() && !this.extemporaneo){
        this.$refs.alert.setMessage("", "Falta Ingresar la Firma del Paciente");
        this.$refs.alert.open();
        return;
      }

      this.editedItem.firma_cancer = (!this.extemporaneo) ? this.$refs.paciente2.save() : null;
      this.$refs.paciente2.clear();
      this.mostrar_firma2 = false;

      if(this.editedItem.consentimiento_covid){
        this.tab = 3;
        setTimeout(() => {
          this.mostrar_firma3 = true;
          this.$refs.paciente3.clear();
        }, 250);
      }
      else {
        this.finalizar();
      }
    },
    validarCovid(){
      if(this.editedItem.identificado_con == null || this.editedItem.identificado_con.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar la Forma en que se Identificó el Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.curp == null || this.editedItem.curp.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar la CURP del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.fecha_covid == null){
        this.$refs.alert.setMessage("", "Falta Indicar la Fecha de Realización de la Prueba Covid del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.resultado_covid == null){
        this.$refs.alert.setMessage("", "Falta Indicar el Resultado de la Prueba Covid del Paciente");
        this.$refs.alert.open();
        return;
      }

      if(this.$refs.paciente3.isEmpty() && !this.extemporaneo){
        this.$refs.alert.setMessage("", "Falta Ingresar la Firma del Paciente");
        this.$refs.alert.open();
        return;
      }

      this.editedItem.firma_covid = (!this.extemporaneo) ? this.$refs.paciente3.save() : null;
      this.$refs.paciente3.clear();
      this.mostrar_firma3 = false;

      this.finalizar();
    },
    finalizar(){
      var payload = {
        consentimiento_estudios: this.editedItem.consentimiento_estudios,
        consentimiento_cancer: this.editedItem.consentimiento_cancer,
        consentimiento_covid: this.editedItem.consentimiento_covid,
        identificado_con: this.editedItem.identificado_con,
        curp: this.editedItem.curp,
        firma1: this.editedItem.firma_estudios,
        firma2: this.editedItem.firma_cancer,
        firma3: this.editedItem.firma_covid,
        resultado_covid: this.editedItem.resultado_covid,
        fecha_covid: this.editedItem.fecha_covid
      };

      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.consulta_medica = Object.assign({}, this.defaultConsultaMedica);
      this.tab = 1;
      this.otros_servicios = [];
      this.mastografia = false;
      this.usg = false;
      this.dialogConsentimiento = false;

      this.$emit('change', payload);
    }
  }
}
</script>