<template>
  <v-container style="max-width: 1800px !important">
    <div class="pa-3 ma-3">
      <v-alert-dialog ref="alert"> </v-alert-dialog>
      <v-dialog v-model="dialogAddEdit" max-width="90%">
        <v-card>
          <v-card-title>
            {{
              editedItem.id_proveedor === null ? "Crear" : "Editar"
            }}
            Proveedor
          </v-card-title>

          <v-card-text>

            <v-tabs
              v-model="tabOpcion"
              centered
              grow
            >
              <v-tab
                href="#generales"
              >
                Datos Generales
              </v-tab>

              <v-tab
                href="#fiscales"
                :disabled="editedItem.id_proveedor == null"
              >
                Datos Fiscales
              </v-tab>

              <v-tab
                href="#cuentas"
                :disabled="editedItem.id_proveedor == null"
              >
                Cuentas Bancarias 
              </v-tab>
            </v-tabs>


            <v-tabs-items v-model="tabOpcion">
              <v-tab-item value="generales">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.clave"
                        label="Clave del Proveedor *"
                        dense
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.nombre_corto"
                        label="Nombre Corto del Proveedor *"
                        dense
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.nombre_completo"
                        label="Nombre Completo del Proveedor *"
                        dense
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        :items="tiposPersonas"
                        v-model="editedItem.id_tipo_persona"
                        label="Tipo de Persona"
                        item-value="id_tipo_persona"
                        item-text="nombre"
                        dense
                        outlined
                        hide-details
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-autocomplete-add
                        v-model="editedItem.id_tipo_proveedor"
                        label="Tipo de Proveedor"
                        item-value="id_tipo_proveedor"
                        item-text="nombre"
                        url-get="/Catalogos/ListarTipoProveedores"
                        url-save="/Catalogos/CrearTipoProveedor"
                      >
                      </v-autocomplete-add>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.nombre_contacto"
                        label="Nombre del Contacto"
                        dense
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.telefono_contacto"
                        label="Teléfono de Contacto"
                        dense
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.correo_electronico"
                        label="Correo Electrónico"
                        dense
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green" text @click="closeDialogAddEdit"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="red"
                    text
                    @click="saveDialogAddEdit"
                    :loading="loadingSave"
                    class="white--text"
                    >
                      Guardar Datos Generales</v-btn
                  >
                </v-card-actions>

              </v-tab-item>

              <v-tab-item value="fiscales">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItemFiscal.direccion_fiscal"
                        label="Domicilio Fiscal *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItemFiscal.nombre_razon_social"
                        label="Nombre/Razón Social *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItemFiscal.rfc"
                        label="RFC *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="editedItemFiscal.id_estado"
                        :items="estados"
                        label="Estado *"
                        item-value="id_estado"
                        item-text="nombre"
                        dense
                        outlined
                        hide-details
                        @change="getMunicipios"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="editedItemFiscal.id_municipio"
                        :items="municipios"
                        item-value="id_municipio"
                        item-text="nombre"                      
                        label="Municipio *"
                        dense
                        outlined
                        hide-details
                        :loading="loadingMunicipios"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="editedItemFiscal.id_tipo_persona"
                        :items="tiposPersonas"
                        label="Tipo de Persona *"
                        item-value="id_tipo_persona"
                        item-text="nombre"
                        dense
                        outlined
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="editedItemFiscal.id_regimen_fiscal"
                        :items="regimenFiscales"                      
                        label="Régimen Fiscal *"
                        :item-text="codigoNombre"
                        item-value="id_regimen_fiscal"
                        dense
                        outlined
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItemFiscal.cp"
                        label="Código Postal *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItemFiscal.direccion"
                        label="Dirección *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItemFiscal.colonia"
                        label="Colonia *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItemFiscal.telefono"
                        label="Teléfono *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItemFiscal.correo"
                        label="Correo *"
                        dense
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green" text @click="closeDialogAddEdit"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="red"
                    text
                    @click="saveDialogDatosFiscales"
                    :loading="loadingSaveDatosFiscales"
                    class="white--text"
                    >
                      Guardar Datos Fiscales
                    </v-btn
                  >
                </v-card-actions>
              </v-tab-item>

              <v-tab-item value="cuentas">
                <v-card-text>
                  <v-expansion-panels v-model="panel" accordion mandatory>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-medium">
                        Cuentas Bancarias Nacionales
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <nacionales
                          ref="nacionales"
                          :id_proveedor="editedItem.id_proveedor"
                        ></nacionales>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-medium">
                        Cuentas Bancarias Internacionales
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <internacionales
                          ref="internacionales"
                          :id_proveedor="editedItem.id_proveedor"
                        ></internacionales>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green" text @click="closeDialogAddEdit"
                    >Cancelar</v-btn
                  >
                </v-card-actions>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="openDialogAddEdit(null)">
            Agregar Proveedor
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search.nombre"
            label="Nombre(s)"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="search.id_tipo_proveedor"
            item-value="id_tipo_proveedor"
            item-text="nombre"
            :items="tipo_proveedores"
            label="Tipo de Proveedor"
            dense
            outlined
            hide-details
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4">
          <v-btn block color="primary" text outlined @click="getItems">
            <v-icon>mdi-magnify</v-icon>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
          >
            <template v-slot:[`item.tipo`]="{}">
              {{ Math.random() >= 0.5 ? 'Tipo A' : ' Tipo B' }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="openDialogAddEdit(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import VAlertDialog from "@/components/VAlertDialog.vue";
import Nacionales from './Cuentas/Nacionales.vue'
import Internacionales from'./Cuentas/Internacionales.vue'

export default {
  components: {
    VAlertDialog,
    Nacionales,
    Internacionales
  },
  data: () => ({
    panel: 0,
    search: {
      id_tipo_proveedor: null,
      nombre: null
    },
    dialogAddEdit: false,
    loading: false,
    items: [],
    tabOpcion: null,
    // Items para los autocompletados
    estados: [],
    loadingEstados: false,
    municipios: [],
    loadingMunicipios: false,
    tiposPersonas: [],
    loadingTipoPersonas: false,
    regimenFiscales: [],
    loadingRegimenFiscales: false,

    headers: [
      {
        text: "Tipo de Proveedor",
        value: "tipo",
        align: "center",
      },
      {
        text: "Clave del Proveedor",
        value: "clave",
        align: "center",
      },
      {
        text: "Nombre Corto del Proveedor",
        value: "nombre_corto",
        align: "center",
      },
      {
        text: "Nombre Completo del Proveedor",
        value: "nombre_completo",
        align: "center",
      },
      {
        text: "Tipo de Persona",
        value: "tipo_persona",
        align: "center",
      },
      {
        text: "Tipo de Proveedor",
        value: "tipo_proveedor",
        align: "center",
      },

      {
        text: "Nombre del Contacto",
        value: "nombre_contacto",
        align: "center",
      },

      {
        text: "Teléfono de Contacto",
        value: "telefono_contacto",
        align: "center",
      },

      {
        text: "Correo Electrónico",
        value: "correo_electronico",
        align: "center",
      },

      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    editedItem: {
      id_proveedor: null,
      clave: null,
      nombre_corto: null,
      nombre_completo: null,
      id_tipo_persona: null,
      id_tipo_proveedor: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
    },
    defaultEditedItem: {
      id_proveedor: null,
      clave: null,
      nombre_corto: null,
      nombre_completo: null,
      id_tipo_persona: null,
      id_tipo_proveedor: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
    },
    editedItemFiscal: {
      direccion_fiscal: null,
      nombre_razon_social: null,
      rfc: null,
      id_estado: null,
      id_municipio: null,
      id_tipo_persona: null,
      id_regimen_fiscal: null,
      cp: null,
      direccion: null,
      colonia: null,
      telefono: null,
      correo: null,
    },
    defaultEditedItemFiscal: {
      direccion_fiscal: null,
      nombre_razon_social: null,
      rfc: null,
      id_estado: null,
      id_municipio: null,
      id_tipo_persona: null,
      id_regimen_fiscal: null,
      cp: null,
      direccion: null,
      colonia: null,
      telefono: null,
      correo: null,
    },
    loadingSaveDatosFiscales: false,
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false,
    tipo_proveedores: [],
    loadingTipoProveedores: false,
  }),
  mounted() {
    this.getItems();
    this.getEstados();
    this.getTipoPersonas();
    this.getRegimenFiscales();
    this.getTipoProveedores();
  },
  methods: {
    codigoNombre: item => `[${item.codigo}] - ${item.nombre}`,
    getTipoProveedores() {
      this.loadingTipoProveedores = true;
      axios
        .get("/Catalogos/ListarTipoProveedores")
        .then((response) => {
          this.tipo_proveedores = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTipoProveedores = false;
        });
    },
    getItems() {
      const url = new URL(`http://localhost/Pagos/ListarProveedores`);
      if(this.search.id_tipo_proveedor != null) url.searchParams.append("id_tipo_proveedor", this.search.id_tipo_proveedor);
      if(this.search.nombre != null) url.searchParams.append("nombre", this.search.nombre);
      var url_string = url.href.replace('http://localhost', '');

      this.loading = true;
      axios
        .get(url_string)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTipoPersonas(){
      this.loadingTipoPersonas = true;
      axios
        .get('/Catalogos/ListarTipoPersonas')
        .then(response => {
          this.tiposPersonas = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTipoPersonas = false;
        })
    },
    getRegimenFiscales(){
      axios
        .get('/Catalogos/ListarRegimenFiscales')
        .then(response => {
          this.regimenFiscales = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRegimenFiscales = false;
        })
    },
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    getMunicipios(){
      if(this.editedItemFiscal.id_estado == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItemFiscal.id_estado}`)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    openDialogAddEdit(editItem = null) {
      if (editItem != null) {
        this.editedItem = Object.assign({}, editItem);
        if(editItem.datosFiscales != null){
          this.editedItemFiscal = editItem.datosFiscales;
          this.getMunicipios();
        }
      }
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.tabOpcion = "generales";
      this.panel = 0;
      if (this.$refs.nacionales && this.$refs.nacionales.reset) this.$refs.nacionales.reset();
      if (this.$refs.internacionales && this.$refs.internacionales.reset) this.$refs.internacionales.reset();
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit() {
      if (
        this.editedItem.clave == null ||
        this.editedItem.clave.length === 0 ||
        this.editedItem.clave.length > 8
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar una Clave entre 1 y 8 caracteres"
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.nombre_corto == null ||
        this.editedItem.nombre_corto.length === 0 ||
        this.editedItem.nombre_corto.length > 256
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar un Nombre corto del Proveedor"
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.nombre_completo == null ||
        this.editedItem.nombre_completo.length === 0 ||
        this.editedItem.nombre_completo.length > 256
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar un Nombre completo del Proveedor"
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.id_tipo_persona == null ||
        this.editedItem.id_tipo_persona.length === 0
      ) {
        this.$refs.alert.setMessage("", "Debe de Ingresar el Tipo de Persona");
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.id_tipo_proveedor == null ||
        this.editedItem.id_tipo_proveedor.length === 0
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar el Tipo de Proveedor"
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.nombre_contacto == null ||
        this.editedItem.nombre_contacto.length === 0
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar el Nombre de Contacto"
        );
        this.$refs.alert.open();
        return;
      }

      const phoneRegex = /^\d{10}$/;

      if (
        this.editedItem.telefono_contacto == null ||
        this.editedItem.telefono_contacto.length === 0
      ) {
        this.$refs.alert.setMessage("", "Debe de Ingresar el Teléfono");
        this.$refs.alert.open();
        return;
      } else if (!phoneRegex.test(this.editedItem.telefono_contacto)) {
        this.$refs.alert.setMessage(
          "",
          "Ingrese un formato de Teléfono válido (10 dígitos numéricos)"
        );
        this.$refs.alert.open();
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (
        this.editedItem.correo_electronico == null ||
        this.editedItem.correo_electronico.length === 0
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar el Correo Electrónico"
        );
        this.$refs.alert.open();
        return;
      } else if (!emailRegex.test(this.editedItem.correo_electronico)) {
        this.$refs.alert.setMessage(
          "",
          "Ingrese un formato de Correo Electrónico válido"
        );
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_proveedor === null ? "POST" : "PUT",
        url:
          this.editedItem.id_proveedor === null
            ? "/Pagos/CrearProveedor"
            : `/Pagos/EditarProveedor/${this.editedItem.id_proveedor}`,
        data: {
          clave: this.editedItem.clave,
          nombre_corto: this.editedItem.nombre_corto,
          nombre_completo: this.editedItem.nombre_completo,
          id_tipo_persona: this.editedItem.id_tipo_persona,
          id_tipo_proveedor: this.editedItem.id_tipo_proveedor,
          nombre_contacto: this.editedItem.nombre_contacto,
          telefono_contacto: this.editedItem.telefono_contacto,
          correo_electronico: this.editedItem.correo_electronico
        },
      })
        .then(response => {
          this.getItems();
          if(this.editedItem.id_proveedor === null){
            this.editedItem.id_proveedor = response.data;
            this.tabOpcion = "fiscales";
          }
          else this.closeDialogAddEdit();
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.$refs.alert.setMessage(
              "",
              "Existe un Proveedor con la misma Clave."
            );
            this.$refs.alert.open();
            return;
          }
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    openDialogDelete(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete() {
      this.loadingDelete = true;
      axios
        .delete(`/Pagos/EliminarProveedores/${this.editedItem.id_proveedor}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    openDialogDatosFiscales() {
      // Reiniciar los campos al abrir el diálogo
      this.dialogDatosFiscales = true;
      this.direccionDomicilio = "";
      this.nombreRazonSocial = "";
      this.rfc = "";
      this.estado = "";
      this.municipio = "";
      this.tipoPersona = "";
      this.regimenFiscal = "";
      this.codigoPostal = "";
      this.direccion = "";
      this.colonia = "";
      this.telefono = "";
      this.correo = "";
    },
    closeDialogDatosFiscales() {
      this.dialogAddEdit = false;
      this.editedItemFiscal = Object.assign({}, this.defaultEditedItemFiscal);
      
    },
    saveDialogDatosFiscales() {
      if (
        this.editedItemFiscal.direccion_fiscal == null ||
        this.editedItemFiscal.direccion_fiscal.length === 0 ||
        this.editedItemFiscal.direccion_fiscal.length > 256
      ) {
        this.$refs.alert.setMessage(
          "",
          "Ingresa el Domicilio Fiscal."
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItemFiscal.nombre_razon_social == null ||
        this.editedItemFiscal.nombre_razon_social.length === 0 ||
        this.editedItemFiscal.nombre_razon_social.length > 256
      ) {
        this.$refs.alert.setMessage("", "Ingresa tu Nombre o Razon Social.");
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItemFiscal.rfc == null ||
        this.editedItemFiscal.rfc.length === 0 ||
        this.editedItemFiscal.rfc.length < 12 ||
        this.editedItemFiscal.rfc.length > 13
      ) {
        this.$refs.alert.setMessage("", "Ingresa un RFC válido");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItemFiscal.id_estado == null) {
        this.$refs.alert.setMessage("", "Ingresa un Estado");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItemFiscal.id_municipio == null) {
        this.$refs.alert.setMessage("", "Ingresa un Municipio");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItemFiscal.id_tipo_persona == null) {
        this.$refs.alert.setMessage("", "Ingresa un Tipo de Persona");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItemFiscal.id_regimen_fiscal == null) {
        this.$refs.alert.setMessage("", "Ingresa un Régimen Fiscal");
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItemFiscal.cp == null ||
        this.editedItemFiscal.cp.length === 0 ||
        this.editedItemFiscal.cp.length < 5
      ) {
        this.$refs.alert.setMessage("", "Ingresa un Codigo Postal Valido");
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItemFiscal.direccion == null ||
        this.editedItemFiscal.direccion.length === 0 ||
        this.editedItemFiscal.direccion.length > 256
      ) {
        this.$refs.alert.setMessage("", "Ingresa una Direccion.");
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItemFiscal.colonia == null ||
        this.editedItemFiscal.colonia.length === 0 ||
        this.editedItemFiscal.colonia.length > 256
      ) {
        this.$refs.alert.setMessage("", "Ingresa una Colonia.");
        this.$refs.alert.open();
        return;
      }

      const phoneRegex = /^\d{10}$/;

      if (this.editedItemFiscal.telefono == null || this.editedItemFiscal.telefono.length === 0) {
        this.$refs.alert.setMessage("", "Debe de Ingresar el Teléfono");
        this.$refs.alert.open();
        return;
      } else if (!phoneRegex.test(this.editedItemFiscal.telefono)) {
        this.$refs.alert.setMessage(
          "",
          "Ingrese un formato de Teléfono válido (10 dígitos numéricos)"
        );
        this.$refs.alert.open();
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (this.editedItemFiscal.correo == null || this.editedItemFiscal.correo.length === 0) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar el Correo Electrónico"
        );
        this.$refs.alert.open();
        return;
      } else if (!emailRegex.test(this.editedItemFiscal.correo)) {
        this.$refs.alert.setMessage(
          "",
          "Ingrese un formato de Correo Electrónico válido"
        );
        this.$refs.alert.open();
        return;
      }

      this.loadingSaveDatosFiscales = true;
      axios
      .post('/Pagos/ActualizarProveedorDatosFiscales/'+this.editedItem.id_proveedor,{
        direccion_fiscal: this.editedItemFiscal.direccion_fiscal,
        nombre_razon_social: this.editedItemFiscal.nombre_razon_social,
        rfc: this.editedItemFiscal.rfc,
        id_estado: this.editedItemFiscal.id_estado,
        id_municipio: this.editedItemFiscal.id_municipio,
        id_tipo_persona: this.editedItemFiscal.id_tipo_persona,
        id_regimen_fiscal: this.editedItemFiscal.id_regimen_fiscal,
        cp: this.editedItemFiscal.cp,
        direccion: this.editedItemFiscal.direccion,
        colonia: this.editedItemFiscal.colonia,
        telefono: this.editedItemFiscal.telefono,
        correo: this.editedItemFiscal.correo
      })
      .then(() => {
        this.closeDialogDatosFiscales();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingSaveDatosFiscales = false;
      })
    },
  },
};
</script>
