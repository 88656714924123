<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0 ma-0">
      <v-tabs
        v-model="tab"
        centered
        grow
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
           href="#historico"
          @click="actualizarItems('historico')"
        >
          Pacientes Históricos
        </v-tab>

        <v-tab
           href="#diagnosticos_sin_validar"
          @click="actualizarItems('diagnosticos_sin_validar')"
        >
          Diagnosticos Sin Validar
        </v-tab>
      </v-tabs>
          
      <v-tabs-items v-model="tab">
        <v-tab-item value="historico">
          <Historico
            ref="historico"
          ></Historico>
        </v-tab-item>

        <v-tab-item value="diagnosticos_sin_validar">
          <DiagnosticosSinValidar
            ref="diagnosticos_sin_validar"
          ></DiagnosticosSinValidar>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Historico from './Historico/Historico.vue'
import DiagnosticosSinValidar from './Historico/DiagnosticosSinValidar.vue'
//import { mapState } from 'vuex'

export default {
  computed: {
    //...mapState(['ids_vistas'])
  },
  components:{
    Historico,
    DiagnosticosSinValidar
  },
  data: () => ({
    tab: 'historico'
  }),
  methods: {
    /*
    mostrarVista(id_vista){
			if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
			return (this.ids_vistas.indexOf(id_vista) >= 0);
		},
    */
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    }
  }
}
</script>