<template>
  <v-tooltip bottom color="black" class="white--text">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on"
        small
        @click="onClick"
      >
        {{icon}}
      </v-icon>
    </template>
    <span class="white--text">{{text}}</span>
  </v-tooltip>
</template>


<script>
export default {
  name: "VTooltipIcon",
  props: {
    icon: {
      type: String,
      default: "mdi-pencil"
    },
    text:{
      type: String,
      default: "Save",
    },
  },
  methods:{
    onClick($event){
      this.$emit('click',$event);
    }
  }
};
</script>