<template>
  <div class="pa-3 ma-3">
    <v-alert-dialog ref="alert"></v-alert-dialog>
    <v-dialog max-width="65%" v-model="dialogAddEdit" persistent>
      <v-card>
        <v-card-title>
          {{ editedItem.id_medicamento === null ? "Crear" : "Editar" }} Medicamento
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="editedItem.codigo" label="Código" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="editedItem.farmaco" label="Nombre" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="editedItem.forma" label="Forma" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="editedItem.concentracion" label="Concentración" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="editedItem.fraccion" label="Fracción" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="editedItem.denominacion_distintiva" label="Denominación Distintiva" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="editedItem.registro_sanitario" label="Registro Sanitario" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="editedItem.titular" label="Titular" outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="editedItem.indicacion_terapeutica" label="Indicación Terapéutica" outlined></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="65%" v-model="dialogDelete" persistent>
      <v-card>
        <v-card-title>Eliminar Medicamento</v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el medicamento llamado "{{ editedItem.farmaco }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="openDialogAddEdit(null)">Crear Medicamento</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :items="items" :headers="headers" :loading="loading">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openDialogAddEdit(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="openDialogDelete(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue';

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Código",
        value: "codigo",
        align: "center",
        width: "15%"
      },
      {
        text: "Medicamento",
        value: "farmaco",
        align: "center",
        width: "60%"
      },
      {
        text: "Forma",
        value: "forma",
        align: "center",
        width: "10%"
      },
      {
        text: "Concentración",
        value: "concentracion",
        align: "center",
        width: "10%"
      },
      {
        text: "Fracción",
        value: "fraccion",
        align: "center",
        width: "10%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "15%"
      }
    ],
    editedItem: {
      id_medicamento: null,
      codigo: "",
      farmaco: "",
      forma: "",
      concentracion: "",
      fraccion: "",
      denominacion_distintiva: "",
      registro_sanitario: "",
      titular: "",
      indicacion_terapeutica: ""
    },
    defaultEditedItem: {
      id_medicamento: null,
      codigo: "",
      farmaco: "",
      forma: "",
      concentracion: "",
      fraccion: "",
      denominacion_distintiva: "",
      registro_sanitario: "",
      titular: "",
      indicacion_terapeutica: ""
    },
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems(){
      this.loading = true;
      axios
        .get('/Catalogos/ListarMedicamentos')
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      if (this.editedItem.codigo == null || this.editedItem.codigo.length === 0 || this.editedItem.codigo.length > 16) {
        this.$refs.alert.setMessage("", "Debe ingresar un Código entre 1 y 16 caracteres");
        this.$refs.alert.open();
        return;
      }
      
      if (this.editedItem.farmaco == null || this.editedItem.farmaco.length === 0 || this.editedItem.farmaco.length > 256) {
        this.$refs.alert.setMessage("", "Debe ingresar un Nombre de Farmaco entre 1 y 256 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.forma == null || this.editedItem.forma.length === 0 || this.editedItem.forma.length > 128) {
        this.$refs.alert.setMessage("", "Debe ingresar una Forma entre 1 y 128 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.concentracion == null || this.editedItem.concentracion.length === 0 || this.editedItem.concentracion.length > 256) {
        this.$refs.alert.setMessage("", "Debe ingresar una Concentración entre 1 y 256 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.fraccion == null || this.editedItem.fraccion.length === 0 || this.editedItem.fraccion.length > 8) {
        this.$refs.alert.setMessage("", "Debe ingresar una Fracción entre 1 y 8 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.denominacion_distintiva == null || this.editedItem.denominacion_distintiva.length === 0 || this.editedItem.denominacion_distintiva.length > 128) {
        this.$refs.alert.setMessage("", "Debe ingresar una Denominación Distintiva entre 1 y 128 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.registro_sanitario == null || this.editedItem.registro_sanitario.length === 0 || this.editedItem.registro_sanitario.length > 32) {
        this.$refs.alert.setMessage("", "Debe ingresar un Registro Sanitario entre 1 y 32 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.titular == null || this.editedItem.titular.length === 0 || this.editedItem.titular.length > 256) {
        this.$refs.alert.setMessage("", "Debe ingresar un Titular entre 1 y 256 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.indicacion_terapeutica == null || this.editedItem.indicacion_terapeutica.length === 0 || this.editedItem.titular.indicacion_terapeutica > 2048) {
        this.$refs.alert.setMessage("", "Debe ingresar un Indicación Terapéutica entre 1 y 2048 caracteres");
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_medicamento === null ? 'POST' : 'PUT',
        url: this.editedItem.id_medicamento === null ? '/Catalogos/CrearMedicamento' : `/Catalogos/EditarMedicamento/${this.editedItem.id_medicamento}`,
        data: {
          codigo: this.editedItem.codigo,
          farmaco: this.editedItem.farmaco,
          forma: this.editedItem.forma,
          concentracion: this.editedItem.concentracion,
          fraccion: this.editedItem.fraccion,
          denominacion_distintiva: this.editedItem.denominacion_distintiva,
          registro_sanitario: this.editedItem.registro_sanitario,
          titular: this.editedItem.titular,
          indicacion_terapeutica: this.editedItem.indicacion_terapeutica
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        if(error.response.status == 409){
          this.$refs.alert.setMessage("", "Ya existe un Medicamento con el mismo Código.");
          this.$refs.alert.open();
          return;
        }
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
        .delete(`/Catalogos/EliminarMedicamento/${this.editedItem.id_medicamento}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    }
  }
}
</script>
