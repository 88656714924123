<template>
  <v-container style="max-width: 95% !important;">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-accept-dialog
      ref="accept"
    >
    </v-accept-dialog>

    <v-dialog
      v-model="dialogPaciente"
      persistent
      max-width="90%"
    >
      <v-card>
        <v-card-title>
          Agregar Servicios
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="2" class="text-center">
               <v-img
                height="150"
                :src="(pacienteItem.fotografia != null) ? `/api/archivos/pacientes/${pacienteItem.fotografia}?jwt=${token}` : '/img/persona.jpg'"
                contain
                width="150"
              ></v-img>
            </v-col>
            <v-col cols="5" class="align-content-center">
              <span class="font-weight-bold">Folio Paciente:</span> {{pacienteItem.folio}}
              <br>
              <span class="font-weight-bold">Nombre:</span> {{pacienteItem.nombre}}
              <br>
              <span class="font-weight-bold">Apellido Paterno:</span> {{pacienteItem.apellido_paterno}}
              <br>
              <span class="font-weight-bold">Apellido Materno:</span> {{pacienteItem.apellido_materno}}
              <br>
              <span class="font-weight-bold">Fecha de Nacimiento:</span> {{pacienteItem.fecha_nacimiento}}
            </v-col>
            <v-col cols="5"  class="align-content-center">
              <span class="font-weight-bold">Edad:</span> {{pacienteItem.edad}}
              <br>
              <span class="font-weight-bold">Sexo:</span> {{pacienteItem.genero ? 'Masculino' : 'Femenino'}}
              <br>
              <span class="font-weight-bold">Estado:</span> {{pacienteItem.estado}}
              <br>
              <span class="font-weight-bold">Municipio:</span> {{pacienteItem.municipio}}
              <br>
              <span class="font-weight-bold">Localidad de Atención:</span> {{pacienteItem.localidad_atencion}}
            </v-col>
          </v-row>
          <br>
          <v-row>
            <v-col cols="4" class="text-center">
              <v-container
                @click="servicesEditedItem.medical_consultation = !servicesEditedItem.medical_consultation"
              >
                <v-icon
                size="150"
                :color="servicesEditedItem.medical_consultation ? 'teal': 'grey lighten-2'"
                >
                  mdi-medical-bag
                </v-icon>
                <div :class="'text-subtitle-1 font-weight-bold '+(servicesEditedItem.medical_consultation ? 'black--text' : 'grey--text text--lighten-2')">Consulta Médica</div>
              </v-container>
              <v-autocomplete
                v-model="servicesEditedItem.medical_consultation_id_nu"
                :disabled="!servicesEditedItem.medical_consultation"
                :readonly="!servicesEditedItem.medical_consultation"
                outlined
                label="Nivel de Emergencia - Consulta Médica"
                :items="niveles_urgencias"
                item-text="nombre"
                item-value="id_nivel_urgencia"
                dense
                hide-details
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4" class="text-center">
              <v-container
                @click="servicesEditedItem.dental_consultation = !servicesEditedItem.dental_consultation"
              >
                <v-icon
                  size="150"
                  :color="servicesEditedItem.dental_consultation ? 'blue': 'grey lighten-2'"
                >
                  mdi-tooth-outline
                </v-icon>
                <div :class="'text-subtitle-1 font-weight-bold '+(servicesEditedItem.dental_consultation ? 'black--text' : 'grey--text text--lighten-2')">Consulta Dental</div>
              </v-container>
              <v-autocomplete
                v-model="servicesEditedItem.dental_consultation_id_nu"
                :disabled="!servicesEditedItem.dental_consultation"
                :readonly="!servicesEditedItem.dental_consultation"
                outlined
                label="Nivel de Emergencia - Consulta Dental"
                :items="niveles_urgencias"
                item-text="nombre"
                item-value="id_nivel_urgencia"
                dense
                hide-details
              >
              </v-autocomplete>
            
            </v-col>
            <v-col cols="4" class="text-center"
              @click="servicesEditedItem.nutrition = !servicesEditedItem.nutrition"
            >
              <v-icon
                size="150"
                :color="servicesEditedItem.nutrition ? 'orange': 'grey lighten-2'"
              >
                mdi-food-apple
              </v-icon>
              <div :class="'text-subtitle-1 font-weight-bold '+(servicesEditedItem.nutrition ? 'black--text' : 'grey--text text--lighten-2')">Nutrición</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              Esta operación es crítica, por lo que es necesario que sea autorizada por un usuario que cuente con el rol de "Auxiliar Administrativo de Proyecto"
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="servicesEditedItem.username"
                :rules="[rules.required, rules.min6]"
                label="Nombre de Usuario"
                hint="Al menos 6 caracteres"
                counter
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="servicesEditedItem.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min8]"
                :type="show1 ? 'text' : 'password'"
                label="Contraseña"
                hint="Al menos 8 caracteres"
                counter
                dense
                outlined
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialog">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialog" :loading="loadingSave">Agregar Servicios</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="searchInput"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row style="max-width: 1800px !important;">
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
          :search="searchInput"
        >
          <template v-slot:[`item.fotografia`]="{ item }">
            <v-container>  
              <v-img
                height="150"
                :src="(item.fotografia != null) ? `/api/archivos/pacientes/${item.fotografia}?jwt=${token}` : '/img/persona.jpg'"
                contain
                width="150"
              ></v-img>
            </v-container>
          </template>
          <template v-slot:[`item.genero`]="{ item }">
            {{item.genero ? "M" : "F"}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialog(item)"
            >
              mdi-plus
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import VAcceptDialog from '@/components/VAcceptDialog.vue'

export default {
  components: {
    VAcceptDialog
  },
  computed: {
    ...mapState(['token'])
  },
  data: () => ({
    searchInput: '',
    loading: false,
    items: [],
    headers:[
      {
        text: "Fotografia",
        value: "fotografia",
        align: "center",
        width: "10%",
        sortable: false,
      },
      {
        text: "Folio paciente",
        value: "folio",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Apellido Paterno",
        value: "apellido_paterno",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Apellido Materno",
        value: "apellido_materno",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Fecha de Nacimiento",
        value: "fecha_nacimiento",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Edad",
        value: "edad",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Sexo",
        value: "genero",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Municipio",
        value: "municipio",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Localidad Atención",
        value: "localidad_atencion",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "6.25%",
        sortable: false,
      }
    ],
    pacienteItem: {
      id_paciente: "",
      id_paciente_consulta: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      folio: "",
      fotografia: "",
      estado: "",
      municipio: "",
      localidad_atencion: "",
      fecha_nacimiento: "",
      edad: "",
      consulta_medica_pendientes: 0,
      consulta_medica_realizadas: 0,
      consulta_medica_canceladas: 0,
      consulta_dental_pendientes: 0,
      consulta_dental_realizadas: 0,
      consulta_dental_canceladas: 0,
      consulta_nutricional_pendientes: 0,
      consulta_nutricional_realizadas: 0,
      consulta_nutricional_canceladas: 0,
      fecha_atencion: ""
    },
    defaultPacienteItem: {
      id_paciente: "",
      id_paciente_consulta: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      folio: "",
      fotografia: "",
      estado: "",
      municipio: "",
      localidad_atencion: "",
      fecha_nacimiento: "",
      edad: "",
      consulta_medica_pendientes: 0,
      consulta_medica_realizadas: 0,
      consulta_medica_canceladas: 0,
      consulta_dental_pendientes: 0,
      consulta_dental_realizadas: 0,
      consulta_dental_canceladas: 0,
      consulta_nutricional_pendientes: 0,
      consulta_nutricional_realizadas: 0,
      consulta_nutricional_canceladas: 0,
      fecha_atencion: ""
    },
    dialogPaciente: false,
    loadingSave: false,
    defaultServicesEditedItem: {
      medical_consultation: false,
      medical_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      dental_consultation: false,
      dental_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      nutrition: false,
      username: null,
      password: null
    },
    servicesEditedItem: {
      medical_consultation: false,
      medical_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      dental_consultation: false,
      dental_consultation_id_nu: "283af602-4dc6-42ca-91d6-f32340dc56fa",
      nutrition: false,
      username: null,
      password: null
    },
    niveles_urgencias:[],
    loadingNivelesUrgencia: false,
    show1: false,
    rules: {
      required: value => !!value || 'Requerido.',
      min6: v => v.length >= 6 || 'Minimo 8 caracteres',
      min8: v => v.length >= 8 || 'Minimo 8 caracteres',
    },
  }),
  mounted() {
    this.getItems();
    this.getNivelesUrgencia();
  },
  methods: {
    getItems(){
      this.loading = true;

      axios
      .get('/Pacientes/ListarPacientesRecientes')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getNivelesUrgencia(){
      this.loadingNivelesUrgencia = true;
      axios
        .get('/Catalogos/ListarNivelesUrgencia')
        .then(response => {
          this.niveles_urgencias = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingNivelesUrgencia = false;
        })
    },
    openDialog(item){
      this.pacienteItem = item;
      this.dialogPaciente = true;
      this.servicesEditedItem = Object.assign({}, this.defaultServicesEditedItem);
    },
    closeDialog(){
      this.pacienteItem = Object.assign({}, this.defaultPacienteItem);
      this.dialogPaciente = false;
      this.servicesEditedItem = Object.assign({}, this.defaultServicesEditedItem);
    },
    saveDialog(){
      if(!this.servicesEditedItem.medical_consultation && !this.servicesEditedItem.dental_consultation && !this.servicesEditedItem.nutrition){
        this.$refs.alert.setMessage('', `Debe de seleccionar al menos un servicio a realizar`);
        this.$refs.alert.open();
        return;
      }

      if(this.servicesEditedItem.username.length <= 5 || this.servicesEditedItem.username.length > 255 ){
        this.$refs.alert.setMessage('', `El nombre de usuario debe de tener entre 6 y 256 caracteres.`);
        this.$refs.alert.open();
        return;
      }

      if(this.servicesEditedItem.password.length <= 7 || this.servicesEditedItem.password.length > 255 ){
        this.$refs.alert.setMessage('', `La contraseña del usuario debe de tener entre 8 y 256 caracteres.`);
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios
      .post(`/Pacientes/AgregarConsultasAdicionales`,{
        id_paciente_consulta: this.pacienteItem.id_paciente_consulta,
        consulta_medica: this.servicesEditedItem.medical_consultation,
        id_nivel_urgencia_consulta_medica: this.servicesEditedItem.medical_consultation ? this.servicesEditedItem.medical_consultation_id_nu : null,
        consulta_dental: this.servicesEditedItem.dental_consultation,
        id_nivel_urgencia_consulta_dental: this.servicesEditedItem.dental_consultation ? this.servicesEditedItem.dental_consultation_id_nu : null,
        nutricion: this.servicesEditedItem.nutrition,
        username: this.servicesEditedItem.username,
        password: this.servicesEditedItem.password
      })
      .then(response => {
        if(response.data.status_code == 200){
          this.$refs.accept.setMessage('', `Servicios Agregados Satisfactoriamente`);
          this.$refs.accept.open();
          this.closeDialog();
          this.getItems();
          this.show1 = false;
        }
        else{
          this.$refs.alert.setMessage('', response.data.message);
          this.$refs.alert.open();
          return;
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      })
    }
  }
}
</script>