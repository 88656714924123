<template>
  <div class="pa-3 ma-3">
    <v-alert-dialog ref="alert"></v-alert-dialog>
    <v-dialog max-width="65%" v-model="dialogAddEdit" persistent>
      <v-card>
        <v-card-title>
          {{ editedItem.id_unidad_medica === null ? "Crear" : "Editar" }} Unidad Médica
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.codigo"
                label="Código"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.umm_ubga"
                label="Clave UMM-UBGA"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.nombre"
                label="Nombre"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="65%" v-model="dialogDelete" persistent>
      <v-card>
        <v-card-title>
          Eliminar Unidad Médica
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar la unidad médica llamada "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="openDialogAddEdit(null)">
          Crear Unidad Médica
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :items="items" :headers="headers" :loading="loading">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openDialogAddEdit(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="openDialogDelete(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import VAlertDialog from '@/components/VAlertDialog.vue';

export default {
  components: {
    VAlertDialog,
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers: [
      {
        text: 'Código',
        value: 'codigo',
        align: 'center',
        width: '15%',
      },
      {
        text: 'Clave UMM-UBGA',
        value: 'umm_ubga',
        align: 'center',
        width: '15%',
      },
      {
        text: 'Proyecto',
        value: 'nombre',
        align: 'center',
        width: '60%',
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
        width: '10%',
      },
    ],
    editedItem: {
      id_unidad_medica: null,
      nombre: '',
      codigo: '',
      umm_ubga: '',
    },
    defaultEditedItem: {
      id_unidad_medica: null,
      nombre: '',
      codigo: '',
      umm_ubga: '',
    },
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false,
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      axios
        .get('/Catalogos/ListarUnidadesMedicas')
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialogAddEdit(editItem = null) {
      if (editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit() {
      if (
        this.editedItem.codigo == null ||
        this.editedItem.codigo.length === 0 ||
        this.editedItem.codigo.length > 8
      ) {
        this.$refs.alert.setMessage(
          '',
          'Debe ingresar un Código entre 1 y 8 caracteres'
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.nombre == null ||
        this.editedItem.nombre.length === 0 ||
        this.editedItem.nombre.length > 256
      ) {
        this.$refs.alert.setMessage(
          '',
          'Debe ingresar un Nombre entre 1 y 255 caracteres'
        );
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_unidad_medica === null ? 'POST' : 'PUT',
        url:
          this.editedItem.id_unidad_medica === null
            ? '/Catalogos/CrearUnidadMedica'
            : `/Catalogos/EditarUnidadMedica/${this.editedItem.id_unidad_medica}`,
        data: {
          codigo: this.editedItem.codigo,
          nombre: this.editedItem.nombre,
          umm_ubga: this.editedItem.umm_ubga
        },
      })
        .then(() => {
          this.getItems();
          this.closeDialogAddEdit();
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.$refs.alert.setMessage(
              '',
              'Ya existe una Unidad Médica con el mismo Código.'
            );
            this.$refs.alert.open();
            return;
          }
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    openDialogDelete(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete() {
      this.loadingDelete = true;
      axios
        .delete(`/Catalogos/EliminarUnidadMedica/${this.editedItem.id_unidad_medica}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
  },
};
</script>
