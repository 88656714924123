<template>
  <v-container style="max-width: 95% !important;">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogProcesando"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <br>
          <v-progress-circular :size="175" color="primary" indeterminate></v-progress-circular>
          <br>
          <br>
          <div class="text-h5">Procesando Carga Masiva de Pacientes Históricos</div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_paciente === -1 ? "Crear" : "Editar" }} Paciente
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.nombre"
                label="Nombre"
                outlined
                :error="errores.nombre"
                :error-messages="errores.nombre ? 'El nombre del paciente debe tener entre 1 y 256 caracteres.' : null"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
      max-width="65%"
      v-model="dialogArchivoErrores"
      persistent
    >
      <v-card>
        <v-card-title>
          Aviso de Inconsistencias en la Carga Masiva de Pacientes
        </v-card-title>
        <v-card-text class="text-center">
          <v-icon
            size="300"
            color="yellow"
          >
            mdi-alert
          </v-icon>
          <div class="text-subtitle-1">
            Se han detectado inconsistencias durante el proceso de carga masiva de pacientes. Como resultado, se ha generado un documento que contiene la lista de aquellos pacientes que no fueron cargados correctamente al sistema. Por favor, revise el documento adjunto para identificar y corregir las discrepancias encontradas.
          </div>
          <br>
          <v-btn
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/Migraciones/DescargarDocumento/${archivoErrores}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Inconsistencias
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogErrores">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogHistorico"
      persistent
    >
      <v-card>
        <v-card-title>
          Carga Masiva de Pacientes Históricos
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="historicoItem.id_estado"
                :items="estados"
                item-value="id_estado"
                item-text="nombre"
                dense
                hide-details
                outlined
                label="Estado"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="historicoItem.archivo"
                accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                label="Documento"
                dense
                hide-details
                outlined
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogHistorico">Cancelar</v-btn>
          <v-btn color="red" text @click="savegHistorico" :loading="loadingMasive">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Paciente
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar al paciente "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model.lazy="searchInput"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="3"></v-col>

      <v-col cols="3" style="text-align: right;">
        <v-btn
          text
          color="primary"
          @click="dialogHistorico = true"  
        >
          <v-icon class="mr-2">mdi-upload</v-icon>
          Carga Masiva de <br>Pacientes Históricos
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="max-width: 1800px !important;">
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.genero`]="{ item }">
            {{item.genero ? "M" : "F"}}
          </template>
          <template v-slot:[`item.curp`]="{ item }">
            {{item.curp == null ? "N/A" : item.curp}}
          </template>
          <template v-slot:[`item.seguro_popular`]="{ item }">
            {{item.seguro_popular ? "Sí" : "No"}}
          </template>
          <template v-slot:[`item.num_poliza`]="{ item }">
            {{item.num_poliza == null ? "N/A" : item.num_poliza}}
          </template>
          <template v-slot:[`item.telefono`]="{ item }">
            {{item.telefono == null ? "N/A" : item.telefono}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!--
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
            -->
            <v-icon
              small
              class="mr-2"
              @click="openDialogExpediente(item)"
            >
              mdi-newspaper-variant-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <expediente
      ref="expediente"
    >
    </expediente>
  </v-container>
</template>

<script>
import axios from 'axios'
import _ from 'lodash';
import Expediente from '@/components/DashViews/Expediente.vue'
import VAlertDialog from '@/components/VAlertDialog.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Expediente,
    VAlertDialog,
  },
  computed: {
    ...mapState(['token'])
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Folio paciente",
        value: "folio",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Apellido Paterno",
        value: "apellido_paterno",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Apellido Materno",
        value: "apellido_materno",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Fecha de Nacimiento",
        value: "fecha_nacimiento",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Edad",
        value: "edad",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Sexo",
        value: "genero",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Municipio",
        value: "municipio",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Localidad Atención",
        value: "localidad_atencion",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      /*
      {
        text: "CURP",
        value: "curp",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Copia Póliza Seguro Popular",
        value: "seguro_popular",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "No. Póliza Seguro Popular",
        value: "num_poliza",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Celular o teléfono de contacto",
        value: "telefono",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      {
        text: "Correo electrónico",
        value: "email",
        align: "center",
        width: "6.25%",
        sortable: false,
      },
      */
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "6.25%",
        sortable: false,
      }
    ],
    editedItem: {
      id_paciente: -1,
      id_domicilio: -1,
      domicilio: "",
      id_localidad: -1,
      localidad: "",
      id_municipio: -1,
      municipio: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      edad: "",
      genero: false,
      curp: null,
      seguro_popular: false,
      num_poliza: null,
      telefono: null,
      email: null
    },
    defaultEditedItem: {
      id_paciente: -1,
      id_domicilio: -1,
      domicilio: "",
      id_localidad: -1,
      localidad: "",
      id_municipio: -1,
      municipio: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      edad: "",
      genero: false,
      curp: null,
      seguro_popular: false,
      num_poliza: null,
      telefono: null,
      email: null
    },
    loadingSave: false,
    errores:{
      nombre: false
    },
    defaultErrores: {
      nombre: false
    },
    dialogDelete: false,
    loadingDelete: false,
    searchInput: '',
    dialogExpediente: false,
    pacienteItem: {
      id_paciente: -1,
      id_domicilio: -1,
      domicilio: "",
      id_localidad: -1,
      localidad: "",
      id_municipio: -1,
      municipio: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      edad: "",
      genero: false,
      curp: null,
      seguro_popular: false,
      num_poliza: null,
      telefono: null,
      email: null
    },
    loadingEstados: false,
    estados: [],
    historicoItem: {
      id_estado: null,
      archivo: null,
    },
    defaultHistoricoItem: {
      id_estado: null,
      archivo: null,
    },
    loadingMasive: false,
    dialogHistorico: false,
    dialogArchivoErrores: false,
    archivoErrores: null,
    dialogProcesando: false,
    id_job: null,
    intervalCheck: null,
  }),
  mounted() {
    this.obtenerCargaMasivaEnProgreso();
    this.getItems();
    this.getEstados();
  },
  methods: {
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    getItems(){
      this.loading = true;
      axios
        .get(this.searchInput == null ? '/Pacientes/ListarPacientes?historico=true' : `/Pacientes/ListarPacientes?historico=true&search=${this.searchInput}`)
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.errores = Object.assign({}, this.defaultErrores);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      this.errores = Object.assign({}, this.defaultErrores);

      if (this.editedItem.nombre.length === 0 || this.editedItem.nombre.length > 256) {
        this.errores.nombre = true;
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_paciente === -1 ? 'POST' : 'PUT',
        url: this.editedItem.id_paciente === -1 ? '/Pacientes/CrearPaciente' : `/Pacientes/EditarPaciente/${this.editedItem.id_paciente}`,
        data: {
          nombre: this.editedItem.nombre
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
        .delete(`/Pacientes/EliminarPaciente/${this.editedItem.id_paciente}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    openDialogExpediente(item){
      this.$refs.expediente.openDialog(item.id_paciente);
    },
    debouncedSearchInput: _.debounce(function() {
      this.getItems();
    }, 1000),
    closeDialogHistorico(){
      this.historicoItem = Object.assign({}, this.defaultHistoricoItem);
      this.dialogHistorico = false;
      this.loadingMasive = false;
    },
    savegHistorico(){
      if(this.historicoItem.id_estado == null){
        this.$refs.alert.setMessage('', `Debe de indicar un Estado.`);
        this.$refs.alert.open();
        return;
      }

      if(this.historicoItem.archivo == null){
        this.$refs.alert.setMessage('', `Debe de indicar un Archivo de Pacientes.`);
        this.$refs.alert.open();
        return;
      }

      this.loadingMasive = true;
      let formData = new FormData();
      formData.append('archivo', this.historicoItem.archivo);

      axios.post(`/Migraciones/CargaMasivaPacientes/${this.historicoItem.id_estado}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        this.dialogProcesando = true;
        this.id_job = response.data;
        this.iniciarIntervalo();
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        alert('Failed to upload file');
      })
      .finally(() => {
        this.loadingMasive = false;
      })
    },
    closeDialogErrores(){
      this.dialogArchivoErrores = false;
      this.archivoErrores = null;
    },
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    obtenerCargaMasivaEnProgreso(){
      axios
        .get(`/Migraciones/ListarCargaMasivaEnProgreso`)
        .then(response => {
          if(response.data != null && response.data.length > 0){
            this.id_job  = response.data;
            this.dialogProcesando = true;
            this.iniciarIntervalo();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    iniciarIntervalo(){
      if(this.intervalCheck == null){
        this.intervalCheck = setInterval(() => {
          this.actualizarEstado();
        }, 15000);
      }
    },
    detenerIntervalo(){
      if(this.intervalCheck != null){
        clearInterval(this.intervalCheck);
        this.intervalCheck = null;
      }
    },
    actualizarEstado(){
      axios
      .get(`/Migraciones/EstadoCargaMasiva/${this.id_job}`)
      .then(response => {
        if(response.data.completado){
          this.id_job = null;
          this.dialogProcesando = false;
          this.detenerIntervalo();

          if(response.data.errores != null && response.data.errores.length > 0){
            this.dialogArchivoErrores = true;
            this.archivoErrores = response.data.errores;
          }
        }
      })
      .catch(error => {
        console.log(error);
      })
    }
  },
  watch: {
    searchInput: function() {
      this.debouncedSearchInput();
    }
  },
}
</script>