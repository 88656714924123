<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    hide-default-header
    hide-default-footer
    class="fixed-first-column-data table-bordered"
  >
    <!-- Slot for customizing the header -->
    <template v-slot:header>
      <thead class="v-data-table-header">
        <tr class="fixed-first-header">
          <th
            v-for="(item, idx) in groups"
            :key="'group-' + idx"
            :colspan="item.colspan"
            :rowspan="item.rowspan"
            :class="['header-cell', 'border', 'border-1']"
            :style="{ backgroundColor: item.color, color: item.textColor }"
          >
            {{ item.text }}
          </th>
        </tr>
        <tr>
          <template v-for="(header, idx) in headers">
            <th
              v-if="!header.hide"
              :key="'header' + idx"
              class="header-cell border border-1"
              :style="{
                backgroundColor: header.color,
                color: header.textColor,
              }"
            >
              {{ header.text }}
            </th>
          </template>
        </tr>
      </thead>
    </template>

    <!-- Slot for customizing the rows -->
    <template v-slot:item="{ item }">
      <tr>
        <td
          v-for="(header, index) in headers"
          :key="'item' + index"
          :class="['data-cell', 'border', 'border-1']"
          :style="getStyle(header, item)"
        >
          {{ item[header.value] }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>

export default {
  methods: {
    getStyle(header, item){
      var backgroundColor = undefined;
      var color = undefined;

      if(!header.colorJustTitle){
        backgroundColor = header.color;
        color = header.textColor;
      }

      else {
        var idx = this.items.indexOf(item);
        if(idx >= 0 && this.rows != null && this.rows.length > idx) {
          backgroundColor = this.rows[idx].color;
          color = this.rows[idx].textColor;
        }
      }

      return {
        backgroundColor: backgroundColor,
        color: color 
      };
    }
  },
  props: {
    groups: {
      type: Array,
      default: () => ([])
    },
    headers: {
      type: Array,
      default: () => ([])
    },
    items: {
      type: Array,
      default: () => ([])
    },
    rows: {
      type: Array,
      default: () => ([])
    },
  }
}
</script>

<style scoped>
.header-cell,
.data-cell {
  padding: 16px; /* Espaciado interno */
  margin: 0; /* Espaciado externo */
  text-align: center !important; /* Centrar el texto */
  border: 1px solid rgba(0, 0, 0, 0.12) !important; /* Línea de separación */
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.12); /* Borde de la tabla */
}

.fixed-first-column .v-data-table__wrapper {
  overflow-x: auto;
}

.fixed-first-column-data table td:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  border-right: 1px solid #e0e0e0;
}

.fixed-first-header th:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2; /* Aumentar el z-index para asegurarse de que esté sobre las celdas de datos */
  border-right: 1px solid #e0e0e0;
}
</style>
