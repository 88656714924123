<template>
  <v-row justify-sm="center">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-card 
      class="mt-4"
      width="95%"
      fill
    >
      <v-tabs
        background-color="blue darken-2"
        grow
        dark
      >
        <v-tab>
          <v-icon left>
            mdi-cog
          </v-icon>
          Error de Configuración
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <lottie :options="loadingAnimation" :height="250" :width="500" />
                </v-col>
              </v-row>
              <div class="text-body-1 text-justify">
                Para poder hacer uso de Mi UBGA y expedir recetas, es necesario que nos proporcione la siguiente información: su nombre completo, la institución en donde estudió, su cédula profesional, así como su firma. Esto es necesario ya que usted es un médico, dentista o nutriólogo y estos datos son requeridos para el correcto llenado de las recetas médicas. Una vez proporcionada esta información, podrá hacer uso del sistema.
              </div>
              <br>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="doctor.apellido_paterno"
                    label="Apellido Paterno"
                    dense
                    hide-details
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="doctor.apellido_materno"
                    label="Apellido Materno"
                    dense
                    hide-details
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="doctor.nombre"
                    label="Nombre"
                    dense
                    hide-details
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="doctor.cedula"
                    label="Cédula Profesional"
                    dense
                    hide-details
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="doctor.institucion"
                    label="Institución"
                    dense
                    hide-details
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="(doctor.id_doctor == null || nuevaFirma == true)">
                  <v-card>
                    <v-card-text>
                      <TopazSignature
                        ref="doctor"
                        id="doctor"
                      ></TopazSignature>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      Firma del Doctor
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col
                  v-else
                  cols="12"
                >
                  <v-img
                    :src="doctor.firma"
                    contain
                  ></v-img>
                  <v-btn
                    color="blue"
                    @click="nuevaFirma = true"
                    text
                  >
                    Nueva Firma
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="red"
                    block
                    @click="save"
                    :loading="saving"
                    :disabled="saving"
                    class="white--text"
                  >
                    <v-icon>
                      mdi-content-save
                    </v-icon>
                    Guardar información
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-row>
</template>


<script>
import Lottie from 'vue-lottie';
import animationData from '@/assets/stop.json';
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  components:{
    'lottie': Lottie
  },
  computed: {
    ...mapState(['token'])
  },
  mounted() {
    this.obtenerInfo();
  },
  methods: {
    obtenerInfo(){
      axios
      .get(`/Doctores/ObtenerDatosBasicos?ignore_config=true`)
      .then(response => {
        this.doctor.apellido_paterno = response.data.apellido_paterno;
        this.doctor.apellido_materno = response.data.apellido_materno;
        this.doctor.nombre = response.data.nombre;
      })
      .catch(error => {
        console.log(error)
      })
    },
    save(){
      if (this.doctor.apellido_paterno == null || this.doctor.apellido_paterno.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar el Apellido Paterno del Doctor");
        this.$refs.alert.open();
        return;
      }

      if (this.doctor.apellido_materno == null || this.doctor.apellido_materno.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar el Apellido Materno del Doctor");
        this.$refs.alert.open();
        return;
      }

      if (this.doctor.nombre == null || this.doctor.nombre.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar el Nombre del Doctor");
        this.$refs.alert.open();
        return;
      }

      if (this.doctor.cedula == null || this.doctor.cedula.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar la Cédula del Doctor");
        this.$refs.alert.open();
        return;
      }


      if (this.doctor.institucion == null || this.doctor.institucion.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar la Institución de Procedencia del Doctor");
        this.$refs.alert.open();
        return;
      }

      if ((this.doctor.id_doctor == null ||this.nuevaFirma == true) && this.$refs.doctor.isEmpty()) {
        this.$refs.alert.setMessage("", "Debe ingresar una Firma.");
        this.$refs.alert.open();
        return;
      }

      this.saving = true;
      axios
      .put(`/Doctores/ActualizarDatosDoctor?ignore_config=true`,{
        id_usuario: this.doctor.id_usuario,
        apellido_paterno: this.doctor.apellido_paterno,
        apellido_materno: this.doctor.apellido_materno,
        nombre: this.doctor.nombre,
        cedula: this.doctor.cedula,
        institucion: this.doctor.institucion,
        firma: (this.doctor.id_doctor == null ||this.nuevaFirma == true) ? this.$refs.doctor.save() : null
      })
      .then(() => {
        this.$router.push('/dashboard')
        
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.saving = false;
      })
    }
  },
  data: () => ({
    loadingAnimation: {animationData: animationData},
    doctor: {
      apellido_paterno: null,
      apellido_materno: null,
      nombre: null,
      cedula: null,
      institucion: null,
      firma: null,
    },
    defaultDoctor: {
      apellido_paterno: null,
      apellido_materno: null,
      nombre: null,
      cedula: null,
      institucion: null,
      firma: null,
    },
    saving: false
  })
}
</script>