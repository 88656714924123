<template>
  <v-text-field
    v-model="newValue"
    type="number"
    :label="label"
    prepend-inner-icon="add"
    @click:prepend-inner="increment"
    append-icon="remove"
    @click:append="decrement"
    outlined
    dense
    hide-details
    @input="emitirValor"
    style="text-align-last: center;"
  >
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: ""
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: 1
    },
  },
  watch: {
    value(newValue){
      this.newValue = this.parseIntDefault(newValue, 1);
    }
  },
  data: () => ({
    newValue: "1",
  }),
  created() {
  },
  methods: {
    parseIntDefault(number, default_value = 1){
      var value = parseInt(this.newValue,10);
      return isNaN(value) ? default_value : value;
    },

    increment () {
      this.newValue = this.parseIntDefault(this.newValue,1) + this.step;
      this.emitirValor();
    },
    decrement () {
      var v = this.parseIntDefault(this.newValue,1) - this.step;
      if(v < this.min) return;
      this.newValue = v;
      this.emitirValor();
    },
    emitirValor(){
      this.$emit('input', this.parseIntDefault(this.newValue,1));
    }
  }
}
</script>