<template>
  <v-row class="pa-0 ma-0">
    <v-col
      v-if="label != null"
      cols="12" class="py-0 px-0  ma-0 text-caption">
      {{label}}
    </v-col>
    <v-col cols="4" class="pt-0 mt-0 pl-0">
      <v-autocomplete
        v-model="anhio"
        label="Año"
        :items="anhios"
        outlined
        dense
        hide-details
        @change="diasEnMes(1)"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="4" class="pt-0 mt-0">
      <v-autocomplete
        v-model="mes"
        label="Mes"
        :items="mesesFiltrados()"
        outlined
        dense
        hide-details
        @change="diasEnMes(2)"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="4" class="pt-0 mt-0 pr-0">
      <v-autocomplete
        v-model="dia"
        :items="dias"
        label="Dia"
        outlined
        dense
        hide-details
        @change="emitirValor()"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    value:{
      type:String,
      default:null,
    },
    label: {
      type: String,
      default: null
    }
  },
  watch: {
    value(newValue){
      this.configurarValor(newValue);
    }
  },
  data: () => ({
    anhio: -1,
    mes: -1,
    dia: -1,
    anhios: [],
    meses: [
      {
        value: 1,
        text: "Enero"
      },
      {
        value: 2,
        text: "Febrero"
      },
      {
        value: 3,
        text: "Marzo"
      },
      {
        value: 4,
        text: "Abril"
      },
      {
        value: 5,
        text: "Mayo"
      },
      {
        value: 6,
        text: "Junio"
      },
      {
        value: 7,
        text: "Julio"
      },
      {
        value: 8,
        text: "Agosto"
      },
      {
        value: 9,
        text: "Septiembre"
      },
      {
        value: 10,
        text: "Octubre"
      },
      {
        value: 11,
        text: "Noviembre"
      },
      {
        value: 12,
        text: "Diciembre"
      }
    ],
    dias: []
  }),
  created() {
    var current_year = moment().year();
    var since_year = moment().subtract(120, 'years').year();
    since_year = Math.floor(since_year / 10) * 10;
    
    for (let pos = current_year; pos >= since_year; pos--) {
      this.anhios.push(pos)
    }
    
    this.configurarValor(this.value);
  },
  methods: {
    diasEnMes(tipo) {
      this.dias = [];
      var dias = 0;

      if ((this.mes < 1 || this.mes > 12) || this.anhio <= 0) {
          dias = 0;
      }

      else {
        const esBisiesto = (this.anhio % 4 === 0 && this.anhio % 100 !== 0) || (this.anhio % 400 === 0);
        const diasEnMesArray = [31, esBisiesto ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        dias = diasEnMesArray[this.mes - 1];
      }

      var today = moment();

      if(this.mes == (today.month()+1) && this.anhio == today.year()) dias = today.date();

      for (let d = 1; d <= dias; d++) {
        this.dias.push(d);
      }

      if(tipo == 1) this.mes = -1;
      this.dia = -1;
    },
    mesesFiltrados(){
      var today = moment();

      if(this.anhio < today.year()) return this.meses;

      return this.meses.filter(m => m.value <= (today.month()+1));
    },
    emitirValor(){
      if(this.anhio <= 0 || this.mes <= 0 || this.mes > 12 || this.dia <= 0) return;
      this.$emit('input', `${this.anhio}-${this.mes <= 9 ? '0' : ''}${this.mes}-${this.dia <= 9 ? '0' : ''}${this.dia}`);
    },
    configurarValor(valor){
      if(valor != null){
        var fecha = valor.split("-");
        this.anhio = parseInt(fecha[0]);
        this.diasEnMes(1);
        this.mes = parseInt(fecha[1]);
        this.diasEnMes(2);
        this.dia = parseInt(fecha[2]);
      }
      else{
        this.anhio = -1;
        this.mes = -1;
        this.dias = [];
        this.dia = -1;
      }
      
    }
  }
}
</script>
