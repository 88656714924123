<template>
    <v-row class="ma-0 pa-0">
      <v-col cols="2" class="pa-0 ma-0">
        <v-tabs
          v-model="tab"
          vertical
        >
          <v-tab
            href="#programas"
            @click="actualizarItems('programas')"
          >
            Programas
          </v-tab>

          <v-tab
            href="#unidades_medicas"
            @click="actualizarItems('unidades_medicas')"
          >
            Unidades Médicas
          </v-tab>

          <v-tab
            href="#proyectos"
            @click="actualizarItems('proyectos')"
          >
            Proyectos
          </v-tab>

          <v-tab
            href="#diagnosticos_medicos"
            @click="actualizarItems('diagnosticos_medicos')"
          >
            Diagnósticos Médicos
          </v-tab>

          <v-tab
            href="#bibliografia"
            @click="actualizarItems('bibliografia')"
          >
            Documento Referencia<br>Bibliografica
          </v-tab>

          <v-tab
            href="#medicamentos"
            @click="actualizarItems('medicamentos')"
          >
            Medicamentos
          </v-tab>


          <v-tab
            href="#pacientes_historicos"
            @click="actualizarItems('pacientes_historicos')"
          >
            Historicos
          </v-tab>

        </v-tabs>
      </v-col>
      <v-col cols="10"  class="pa-0 ma-0">

        <v-tabs-items v-model="tab">
          
          <v-tab-item value="programas">
            <programas
              ref="programas"
            ></programas>
          </v-tab-item>

          <v-tab-item value="unidades_medicas">
            <unidades-medicas
              ref="unidades_medicas"
            ></unidades-medicas>
          </v-tab-item>
          
          <v-tab-item value="proyectos">
            <proyectos
              ref="proyectos"
            ></proyectos>
          </v-tab-item>
          
          <v-tab-item value="diagnosticos_medicos">
            <diagnostico-medico
              ref="diagnosticos_medicos"
            ></diagnostico-medico>
          </v-tab-item>
          
          <v-tab-item value="bibliografia">
            <documento-referencia-bibliografica
              ref="bibliografia"
            ></documento-referencia-bibliografica>
          </v-tab-item>
          
          <v-tab-item value="medicamentos">
            <medicamentos
              ref="medicamentos"
            ></medicamentos>
          </v-tab-item>

          <v-tab-item value="pacientes_historicos">
            <pacientes-historicos
              ref="pacientes_historicos"
            ></pacientes-historicos>
          </v-tab-item>
        </v-tabs-items>

      </v-col>
    </v-row>
</template>

<script>
import Programas from './Catalogos/Programas.vue'
import UnidadesMedicas from './Catalogos/UnidadesMedicas.vue'
import Proyectos from './Catalogos/Proyectos.vue'
import DiagnosticoMedico from './Catalogos/DiagnosticoMedico.vue'
import Medicamentos from './Catalogos/Medicamentos.vue'
import PacientesHistoricos from './Catalogos/Historico.vue'
import DocumentoReferenciaBibliografica from './Catalogos/DocumentoReferenciaBibliografica.vue'

export default {
  components:{
    Programas,
    UnidadesMedicas,
    Proyectos,
    DiagnosticoMedico,
    Medicamentos,
    PacientesHistoricos,
    DocumentoReferenciaBibliografica
  },
  data: () => ({
    tab: 'movimientos'
  }),
  mounted() {
  },
  methods: {
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    }
  }
}
</script>