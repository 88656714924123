<template>
  <v-footer
    fixed
    :padless="true"
  >
    <v-card
      width="100%"
      class="text-center ma-0 pa-0"
      :color="(config.extemporaneo || false) ? 'red' : 'footer'"
    >
      <v-card-text class="white--text ma-1 pa-1">
        <v-row class="ma-0 pa-0">
          <v-col :cols="(config.extemporaneo || false) ? 3 : 4" class="ma-0 pa-0">
            <template v-if="ids_unidades_medicas.length <= 1">
              {{ unidadMedicaMostrar() }}
            </template>
            <template v-else>
              <v-autocomplete
                v-model="id_unidad_medica_actual"
                dense
                hide-details
                outlined
                :items="unidadesMedicasFiltradas()"
                item-value="id_unidad_medica"
                :item-text="codigoNombre"
                :loading="loadingUnidadesMedicas"
                @change="cambiarUnidadMedica"
                background-color="white"
              >
              </v-autocomplete>
            </template>
          </v-col>
          <v-col :cols="(config.extemporaneo || false) ? 3 : 4" class="ma-0 pa-0 align-self-center">
            <template v-if="config.codigo_programa != null && config.nombre_programa">
              Programa: [{{config.codigo_programa}}] - {{config.nombre_programa}}
            </template>
            <template v-else>
              Programa: No Asignado
            </template>
          </v-col>
          <v-col :cols="(config.extemporaneo || false) ? 3 : 4" class="ma-0 pa-0 align-self-center">
            <template v-if="config.codigo_proyecto != null && config.nombre_proyecto != null">
              Proyecto: [{{config.codigo_proyecto}}] - {{config.nombre_proyecto}}
            </template>
            <template v-else>
              Proyecto: No Asignado
            </template>
          </v-col>

          <v-col v-if="(config.extemporaneo || false)" class="ma-0 pa-0 align-self-center">
            Modo Extemporaneo: {{config.extemporaneo_fecha}}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>


<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  props: {
    ignoreConfig: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['ids_vistas', 'id_unidad_medica_activa', 'ids_unidades_medicas'])
  },
  data: () => ({
    tab: null,
    loading: false,
    config: {
      codigo_programa: null,
      codigo_proyecto: null,
      codigo_unidad_medica: null,
      nombre_programa: null,
      nombre_proyecto: null,
      nombre_unidad_medica: null,
      extemporaneo: null,
      extemporaneo_fecha: null
    },
    unidades_medicas: [],
    loadingUnidadesMedicas: false,
    id_unidad_medica_actual: null,
    intervalCheck: null
  }),
  mounted() {
    this.obtenerConfiguracionActual();
    this.getUnidadesMedicas();
  },
  destroyed() {
    if(this.intervalCheck != null) {
      clearInterval(this.intervalCheck);
      this.intervalCheck = null;
    }
  },
  methods: {
    codigoNombre: item => `[${item.codigo}] - ${item.nombre}`,
    obtenerConfiguracionActual(){
      this.loading = true;
      axios
      .get(this.ignore_config ? '/Configuraciones/MostrarConfiguracion?ignore_config=true' : '/Configuraciones/MostrarConfiguracion')
      .then(response => {
        this.config = response.data;

        let extemporaneo = response.data.extemporaneo;
        //alert(response.data.extemporaneo)

        this.$store.dispatch("extemporaneo",{
          extemporaneo
        }).then(() => {
          if(this.intervalCheck == null){
            this.intervalCheck = setInterval(() => {
              this.getEstadoActual();
            }, 60000);
          }

				});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getEstadoActual(){
      axios
      .get(this.ignore_config ? '/Configuraciones/EstadoExtemporaneo?ignore_config=true' : '/Configuraciones/EstadoExtemporaneo')
      .then(response => {
        this.config.zona_horaria = response.data.zona_horaria;
        this.config.extemporaneo = response.data.extemporaneo;
        this.config.extemporaneo_fecha = response.data.extemporaneo_fecha;

        let extemporaneo = response.data.extemporaneo;
        
        this.$store.dispatch("extemporaneo",{
          extemporaneo
        }).then(() => {
				});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getUnidadesMedicas(){
      this.loadingUnidadesMedicas = true;
      axios
      .get('/Catalogos/ListarUnidadesMedicas?ignore_config=true')
      .then(response => {
        this.unidades_medicas = response.data;
        this.id_unidad_medica_actual = this.id_unidad_medica_activa;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingUnidadesMedicas = false;
      })
    },
    unidadesMedicasFiltradas(){
      return this.unidades_medicas.filter(x => this.ids_unidades_medicas.indexOf(x.id_unidad_medica) >= 0);
    },
    unidadMedicaMostrar(){
      var unidad_medica = this.unidades_medicas.find(x => x.id_unidad_medica == this.id_unidad_medica_activa);
      if(unidad_medica != null) return `[${unidad_medica.codigo}] - ${unidad_medica.nombre}`;
      else return 'Programa: No Asignado';
    },
    cambiarUnidadMedica(){
      //console.log(this.id_unidad_medica_actual);
      axios
      .put(`/Users/CambiarUnidadMedica/${this.id_unidad_medica_actual}?ignore_config=true`)
      .then(() => {
        this.$store.dispatch('refreshtoken', {  })
        .then(() => {
          this.$router.go();
        })
        .catch(err => {
          console.log(err)
        })
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {

      })

    }
  }
}
</script>