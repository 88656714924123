<template>
  <div>
    <v-row justify-sm="center">
      <v-alert-dialog
        ref="alert"
      >
      </v-alert-dialog>
      <v-dialog
          v-model="complete"
          max-width="50%"
        >
          <v-card>
            <v-card-text class="text-center">
              <v-icon
                size="300"
                color="green"
              >
                mdi-check-circle
              </v-icon>
              <div class="text-h4">
                Configuración Guardada Correctamente
              </div>
              <br>
              <v-btn
                block
                color="green"
                class="white--text"
                @click="finalizar"
              >
                Aceptar
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      <v-dialog
        v-model="dialogMunicipio"
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            Agregar Municipio
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="municipioEditedItem.id_estado"
                  :items="estados"
                  outlined
                  label="Estado"
                  :item-text="codigoNombre"
                  item-value="id_estado"
                  :loading="loadingEstados"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="municipioEditedItem.nombre"
                  outlined
                  label="Municipio"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="restablecerMunicipio"
              :loading="savingMunicipio"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="guardarMunicipio"
              :loading="savingMunicipio"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogLocalidad"
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            Agregar Localidad de Atención
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="localidadEditedItem.id_estado"
                  :items="estados"
                  outlined
                  label="Estado"
                  :item-text="codigoNombre"
                  item-value="id_estado"
                  :loading="loadingEstados"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="localidadEditedItem.nombre"
                  outlined
                  label="Localidad de Atención"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="restablecerLocalidad"
              :loading="savingLocalidad"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="guardarLocalidad"
              :loading="savingLocalidad"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card 
        class="mt-4"
        width="95%"
        fill
      >
        <v-tabs
          background-color="primary darken-2"
          grow
          dark
        >
          <v-tab>
            <v-icon left>
              mdi-cog
            </v-icon>
            Configuración General
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <div class="text-body-1 text-justify">
                  Para poder hacer uso de Mi UBGA, es necesario que previamente indique los campos solicitados a continuación. Por favor indique la configuración que tendrá esta Unidad Médica de Mi UBGA.
                  <br>
                  <div>Unidad Medica: {{ unidadMedicaMostrar() }}</div>
                </div>
                <br>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.id_programa"
                      :items="programas"
                      outlined
                      label="Programa"
                      :item-text="codigoNombre"
                      item-value="id_programa"
                      :loading="loadingProgramas"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.id_proyecto"
                      :items="proyectos"
                      outlined
                      label="Proyecto"
                      :item-text="codigoNombre"
                      item-value="id_proyecto"
                      :loading="loadingProyectos"
                    >
                    </v-autocomplete>
                  </v-col>
                  
                  
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.id_estado"
                      :items="estados"
                      outlined
                      label="Estado"
                      :item-text="codigoNombre"
                      item-value="id_estado"
                      @change="getMunicipios(); getLocalidadesAtencion();"
                      :loading="loadingEstados"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.id_municipio"
                      :items="municipios"
                      item-text="nombre"
                      item-value="id_municipio"
                      outlined
                      label="Municipio"
                      :loading="loadingMunicipios"
                      :disabled="editedItem.id_estado == null || editedItem.id_estado <= 0 || loadingMunicipios"
                      append-outer-icon="mdi-plus"
                      @click:append-outer="addMunicipio"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.id_localidad_atencion"
                      outlined
                      :name="uuidv4"
                      :items="localidadesAtencion"
                      :loading="loadingLocalidadesAtencion"
                      label="Localidad de Atención"
                      item-value="id_localidad_atencion"
                      item-text="nombre"
                      :disabled="editedItem.id_estado == null || editedItem.id_estado <= 0 || loadingLocalidadesAtencion"
                      append-outer-icon="mdi-plus"
                      @click:append-outer="addLocalidad"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="6">
                        <v-card>
                          <v-container>
                            <div class="text-center text-subtitle-1">Testigo #1</div>
                            <TopazSignature
                              id="testigo1"
                              v-if="editedItem.testigo1_firma_b64 == null || editedItem.nueva_firma1 == true"
                              ref="testigo1"
                            ></TopazSignature>
                            <template
                              v-else
                            >
                              <v-img
                                :src="editedItem.testigo1_firma_b64"
                                contain
                              ></v-img>
                              <v-btn
                                color="blue"
                                @click="editedItem.nueva_firma1 = true"
                                text
                              >
                                Nueva Firma
                              </v-btn>
                            </template>
                            <v-row class="ma-0 pa-0">
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.testigo1_apellido_paterno"
                                  label="Apellido Paterno"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                v-model="editedItem.testigo1_apellido_materno"
                                  label="Apellido Materno"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.testigo1_nombre"
                                  label="Nombre(s)"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <v-card>
                          <v-container>
                            <div class="text-center text-subtitle-1">Testigo #2</div>
                            <TopazSignature
                              id="testigo2"
                              v-if="editedItem.testigo2_firma_b64 == null || editedItem.nueva_firma2 == true"
                              ref="testigo2"
                            ></TopazSignature>
                            <template
                              v-else
                            >
                              <v-img
                                :src="editedItem.testigo2_firma_b64"
                                contain
                              ></v-img>
                              <v-btn
                                color="blue"
                                @click="editedItem.nueva_firma2 = true"
                                text
                              >
                                Nueva Firma
                              </v-btn>
                            </template>
                            <v-row class="ma-0 pa-0">
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.testigo2_apellido_paterno"
                                  label="Apellido Paterno"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                v-model="editedItem.testigo2_apellido_materno"
                                  label="Apellido Materno"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.testigo2_nombre"
                                  label="Nombre(s)"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-row>
                    <v-col cols="6" class="text-center">
                      <v-switch
                        v-model="editedItem.extemporaneo"
                        :color="editedItem.extemporaneo ? 'red' : 'primary'"
                        :label="`Modo Extemporaneo: ${editedItem.extemporaneo ? 'Activado' : 'Desactivado'}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="6">
                      Fecha de toma de muestra:
                      <date-picker 
                        :disabled-date="notAfterToday"
                        :disabled-time="notAfterToday"
                        :disabled="!editedItem.extemporaneo" 
                        v-model="editedItem.extemporaneo_fecha" 
                        type="datetime"
                        valueType="format"
                        format="YYYY-MM-DD HH:mm"
                      ></date-picker>
                    </v-col>
                  </v-row>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="red"
                      class="white--text"
                      @click="save"
                      :disabled="saving"
                      :loading="saving"
                    >
                      <v-icon>
                        mdi-content-save
                      </v-icon>
                      Guardar Configuración
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-row>
    <Footer
      :ignore-config="true"
    ></Footer>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import axios from "axios";
import { v4 } from 'uuid';
import VAlertDialog from '../VAlertDialog.vue'
//import vueSignature from "vue-signature";
import TopazSignature from '@/components/TopazSignature.vue';
import Footer from '@/components/DashViews/Comunes/Footer.vue'
import DatePicker from 'vue2-datepicker';

export default {
  components:{
    VAlertDialog,
    //vueSignature
    TopazSignature,
    Footer,
    DatePicker
  },
  computed: {
    ...mapState(['id_unidad_medica_activa'])
  },
  mounted() {
    this.getEstados();
    this.getProgramas();
    this.getUnidadesMedicas();
    this.getProyectos();
    this.getConfiguracionActual();
  },
  data: () => ({
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    estados: [],
    loadingEstados: false,
    editedItem: {
      id_programa: null,
      id_unidad_medica: null,
      id_proyecto: null,
      id_estado: null,
      id_municipio: null,
      id_localidad_atencion: null,
      testigo1_apellido_paterno: null,
      testigo1_apellido_materno: null,
      testigo1_nombre: null,
      testigo2_apellido_paterno: null,
      testigo2_apellido_materno: null,
      testigo2_nombre: null,
      testigo1_firma: null,
      testigo2_firma: null,
      testigo1_firma_b64: null,
      testigo2_firma_b64: null,
      nueva_firma1: false,
      nueva_firma2: false,
      extemporaneo: false,
      extemporaneo_fecha: null,
    },
    defaultEditedItem: {
      id_programa: null,
      id_unidad_medica: null,
      id_proyecto: null,
      id_estado: null,
      id_municipio: null,
      id_localidad_atencion: null,
      testigo1_apellido_paterno: null,
      testigo1_apellido_materno: null,
      testigo1_nombre: null,
      testigo2_apellido_paterno: null,
      testigo2_apellido_materno: null,
      testigo2_nombre: null,
      testigo1_firma: null,
      testigo2_firma: null,
      testigo1_firma_b64: null,
      testigo2_firma_b64: null,
      nueva_firma1: false,
      nueva_firma2: false,
      extemporaneo: false,
      extemporaneo_fecha: null,
    },
    municipios: [],
    loadingMunicipios: false,
    dialogMunicipio: false,
    savingMunicipio: false,
    municipioEditedItem: {
      id_estado: null,
      nombre: null
    },
    defaultMunicipioEditedItem: {
      id_estado: null,
      nombre: null
    },
    localidadesAtencion: [],
    loadingLocalidadesAtencion: false,
    dialogLocalidad: false,
    savingLocalidad: false,
    localidadEditedItem: {
      id_estado: null,
      nombre: null
    },
    defaultLocalidadEditedItem: {
      id_estado: null,
      nombre: null
    },
    programas: [],
    loadingProgramas: false,
    unidades_medicas: [],
    loadingUnidadesMedicas: false,
    proyectos: [],
    loadingProyectos: false,
    saving: false,
    complete: false
  }),
  methods: {
    codigoNombre: item => `[${item.codigo}] - ${item.nombre}`,
    uuidv4(){
      return v4();
    },
    getConfiguracionActual(){
      axios
      .get('/Configuraciones/MostrarConfiguracion?ignore_config=true')
      .then(response => {
        if(response.data == null || response.data == '') return;
        this.editedItem.id_programa = response.data.id_programa;
        this.editedItem.id_unidad_medica = response.data.id_unidad_medica;
        this.editedItem.id_proyecto = response.data.id_proyecto;
        this.editedItem.id_estado = response.data.id_estado;
        this.getMunicipios();
        this.getLocalidadesAtencion();
        this.editedItem.id_municipio = response.data.id_municipio;
        this.editedItem.id_localidad_atencion = response.data.id_localidad_atencion;
        
        this.editedItem.testigo1_apellido_paterno = response.data.testigo1_apellido_paterno;
        this.editedItem.testigo1_apellido_materno = response.data.testigo1_apellido_materno;
        this.editedItem.testigo1_nombre = response.data.testigo1_nombre;
        this.editedItem.testigo2_apellido_paterno = response.data.testigo2_apellido_paterno;
        this.editedItem.testigo2_apellido_materno = response.data.testigo2_apellido_materno;
        this.editedItem.testigo2_nombre = response.data.testigo2_nombre;
        this.editedItem.testigo1_firma_b64 = response.data.testigo1_firma_b64;
        this.editedItem.testigo2_firma_b64 = response.data.testigo2_firma_b64;
      })
      .catch(error => {
        console.log(error)
      })
    },
    getProgramas(){
      this.loadingProgramas = true;
      axios
      .get('/Catalogos/ListarProgramas?ignore_config=true')
      .then(response => {
        this.programas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingProgramas = false;
      })
    },
    getUnidadesMedicas(){
      this.loadingUnidadesMedicas = true;
      axios
      .get('/Catalogos/ListarUnidadesMedicas?ignore_config=true')
      .then(response => {
        this.unidades_medicas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingUnidadesMedicas = false;
      })
    },
    getProyectos(){
      this.loadingProyectos = true;
      axios
      .get('/Catalogos/ListarProyectos?ignore_config=true')
      .then(response => {
        this.proyectos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingProyectos = false;
      })
    },
    getEstados(){
      this.loadingEstados = true;
      axios
      .get('/Catalogos/ListarEstados?ignore_config=true')
      .then(response => {
        this.estados = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingEstados = false;
      })
    },
    getMunicipios(){
      if(this.editedItem.id_estado == null) return;
      this.loadingMunicipios = true;
      axios
      .get(`/Catalogos/ListarMunicipios/${this.editedItem.id_estado}?ignore_config=true`)
      .then(response => {
        this.municipios = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingMunicipios = false;
      })
    },
    getLocalidadesAtencion(){
      if(this.editedItem.id_estado == null) return;
      this.loadingLocalidadesAtencion = true;
      axios
      .get(`/Catalogos/ListarLocalidadesAtencion/${this.editedItem.id_estado}?ignore_config=true`)
      .then(response => {
        this.localidadesAtencion = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingLocalidadesAtencion = false;
      })
    },
    addMunicipio(){
      this.municipioEditedItem = Object.assign({}, this.defaultMunicipioEditedItem);
      this.dialogMunicipio = true;
      this.municipioEditedItem.id_estado = this.editedItem.id_estado;
    },
    restablecerMunicipio(){
      this.municipioEditedItem = Object.assign({}, this.defaultMunicipioEditedItem);
      this.dialogMunicipio = false;
    },
    guardarMunicipio(){
      if(this.municipioEditedItem.id_estado == null){
        this.$refs.alert.setMessage('', 'Debe de indicar un Estado');
        this.$refs.alert.open();
        return;
      }

      this.savingMunicipio = true;
      axios
      .post(`/Catalogos/CrearMunicipio?ignore_config=true`,{
        id_estado: this.municipioEditedItem.id_estado,
        nombre: this.municipioEditedItem.nombre
      })
      .then(response => {
        this.getMunicipios();
        this.editedItem.id_municipio = response.data;
        this.restablecerMunicipio();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.savingMunicipio = false;
      })
    },
    addLocalidad(){
      this.localidadEditedItem = Object.assign({}, this.defaultLocalidadEditedItem);
      this.dialogLocalidad = true;
      this.localidadEditedItem.id_estado = this.editedItem.id_estado;
    },
    restablecerLocalidad(){
      this.localidadEditedItem = Object.assign({}, this.defaultLocalidadEditedItem);
      this.dialogLocalidad = false;
    },
    guardarLocalidad(){
      if(this.localidadEditedItem.id_estado == null){
        this.$refs.alert.setMessage('', 'Debe de indicar un Estado');
        this.$refs.alert.open();
        return;
      }

      this.savingLocalidad = true;

      axios
      .post(`/Catalogos/CrearLocalidadAtencion?ignore_config=true`,{
        id_estado: this.localidadEditedItem.id_estado,
        nombre: this.localidadEditedItem.nombre
      })
      .then(response => {
        this.getLocalidadesAtencion();
        this.editedItem.id_localidad_atencion = response.data;
        this.restablecerLocalidad();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.savingLocalidad = false;
      })
    },
    save(){
      if(this.editedItem.id_programa == null){
        this.$refs.alert.setMessage("", "Falta Ingresar el Programa");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_proyecto == null){
        this.$refs.alert.setMessage("", "Falta Ingresar el Proyecto");
        this.$refs.alert.open();
        return;
      }
      
      if(this.editedItem.id_estado == null){
        this.$refs.alert.setMessage("", "Falta Ingresar el Estado");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_municipio == null){
        this.$refs.alert.setMessage("", "Falta Ingresar el Municipio");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.id_localidad_atencion == null){
        this.$refs.alert.setMessage("", "Falta Ingresar la Localidad de Atención");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.testigo1_apellido_paterno == null || this.editedItem.testigo1_apellido_paterno.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Paterno del Testigo #1");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.testigo1_apellido_materno == null && this.editedItem.testigo1_apellido_materno.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Materno del Testigo #1");
        this.$refs.alert.open();
        return;
      }
      
      if(this.editedItem.testigo1_nombre == null && this.editedItem.testigo1_nombre.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Nombre del Testigo #1");
        this.$refs.alert.open();
        return;
      }

      if((this.editedItem.testigo1_firma_b64 == null || this.editedItem.nueva_firma1 == true) && this.$refs.testigo1.isEmpty()){
        this.$refs.alert.setMessage("", "Falta Ingresar la Firma del Testigo #1");
        this.$refs.alert.open();
        return;
      }



      if(this.editedItem.testigo2_apellido_paterno == null || this.editedItem.testigo2_apellido_paterno.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Paterno del Testigo #2");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.testigo2_apellido_materno == null && this.editedItem.testigo2_apellido_materno.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Apellido Materno del Testigo #2");
        this.$refs.alert.open();
        return;
      }
      
      if(this.editedItem.testigo2_nombre == null && this.editedItem.testigo2_nombre.length <= 0){
        this.$refs.alert.setMessage("", "Falta Ingresar el Nombre del Testigo #2");
        this.$refs.alert.open();
        return;
      }

      if((this.editedItem.testigo2_firma_b64 == null || this.editedItem.nueva_firma2 == true) && this.$refs.testigo2.isEmpty()){
        this.$refs.alert.setMessage("", "Falta Ingresar la Firma del Testigo #2");
        this.$refs.alert.open();
        return;
      }

      if(this.editedItem.extemporaneo && (this.editedItem.extemporaneo_fecha == null || this.editedItem.extemporaneo_fecha.length < 0)){
        this.$refs.alert.setMessage("", "Falta Ingresar la Fecha Extemporanea");
        this.$refs.alert.open();
        return;
      }



      this.saving = true;

      axios
      .post(`/Configuraciones/CrearEditarConfiguracion?ignore_config=true`,{
        id_proyecto: this.editedItem.id_proyecto,
        id_unidad_medica: this.editedItem.id_unidad_medica,
        id_programa: this.editedItem.id_programa,
        id_estado: this.editedItem.id_estado,
        id_municipio: this.editedItem.id_municipio,
        id_localidad_atencion: this.editedItem.id_localidad_atencion,
        testigo1_apellido_paterno: this.editedItem.testigo1_apellido_paterno,
        testigo1_apellido_materno: this.editedItem.testigo1_apellido_materno,
        testigo1_nombre: this.editedItem.testigo1_nombre,
        testigo2_apellido_paterno: this.editedItem.testigo2_apellido_paterno,
        testigo2_apellido_materno: this.editedItem.testigo2_apellido_materno,
        testigo2_nombre: this.editedItem.testigo2_nombre,
        testigo1_firma: (this.editedItem.testigo1_firma_b64 == null || this.editedItem.nueva_firma1 == true) ? this.$refs.testigo1.save() : null,
        testigo2_firma: (this.editedItem.testigo2_firma_b64 == null || this.editedItem.nueva_firma2 == true) ? this.$refs.testigo2.save() : null,
        extemporaneo: this.editedItem.extemporaneo,
        extemporaneo_fecha: this.editedItem.extemporaneo_fecha
      })
      .then(() => {
        this.complete = true;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.saving = false;
      })

    },
    finalizar(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.complete = false;
      this.$router.push('/dashboard');
    },
    unidadMedicaMostrar(){
      var unidad_medica = this.unidades_medicas.find(x => x.id_unidad_medica == this.id_unidad_medica_activa);
      if(unidad_medica != null) return `[${unidad_medica.codigo}] - ${unidad_medica.nombre}`;
      else return 'Programa: No Asignado';
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    }
  }
}
</script>