<template>
  <v-container style="max-width: 1800px !important">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <div class="pa-3 ma-3">
      <v-dialog
        v-model="dialogoDetalles"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :max-width="dialogMaxWidth"
      >
        <v-card>
          <v-toolbar dark color="primary" style="margin-bottom: 15px">
            <v-btn icon dark @click="closeMostrarDetalles">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title style="padding: 20px">
              Detalles de la Solicitud de Pago
            </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <span class="font-weight-bold">Nombre:</span> {{detallesItem.proveedor}}
                <br />
                <span class="font-weight-bold">RFC:</span> {{detallesItem.rfc}}
                <br />
                <span class="font-weight-bold">Tipo de Persona:</span> {{detallesItem.tipo_persona}}
                <br />
                <span class="font-weight-bold">Tipo de Proveedor:</span> {{detallesItem.proveedor}}
                
                <br />
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold">Tipo de Solicitud:</span> {{detallesItem.tipo_solicitud}}
                <br />
                <span class="font-weight-bold">Concepto:</span> {{detallesItem.concepto}}
                <br />
                <span class="font-weight-bold">Monto:</span> {{ detallesItem.monto != null ? `$${formatMoney(detallesItem.monto)} ${detallesItem.moneda}` : '$0.00' }}
                <br />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                      <v-expansion-panel-header class="text-subtitle-1 text-center">
                        Documentos de Sustentación
                      </v-expansion-panel-header>
                        
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col
                            v-if="detallesItem.factura_pdf != null"
                            cols="6"
                            md="4"
                          >
                            <v-btn
                              block
                              text
                              class="text-none mb-4"
                              color="indigo-darken-3"
                              size="x-large"
                              variant="flat"
                              style="background-color: #0589bb; color: #fff"
                              @click="nuevaPestana(`/api/Pagos/DescargarDocumento/${detallesItem.factura_pdf}?jwt=${token}`)"
                            >
                              <v-icon> mdi-paperclip </v-icon>
                              Factura PDF
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="detallesItem.factura_xml != null"
                            cols="6"
                            md="4"
                          >
                            <v-btn
                              block
                              text
                              class="text-none mb-4"
                              color="indigo-darken-3"
                              size="x-large"
                              variant="flat"
                              style="background-color: #0589bb; color: #fff"
                              @click="nuevaPestana(`/api/Pagos/DescargarDocumento/${detallesItem.factura_xml}?jwt=${token}`)"
                            >
                              <v-icon> mdi-paperclip </v-icon>
                              Factura XML
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="detallesItem.constancia_situacion_fiscal != null"
                            cols="6"
                            md="4"
                          >
                            <v-btn
                              block
                              text
                              class="text-none mb-4"
                              color="indigo-darken-3"
                              size="x-large"
                              variant="flat"
                              style="background-color: #0589bb; color: #fff"
                              @click="nuevaPestana(`/api/Pagos/DescargarDocumento/${detallesItem.constancia_situacion_fiscal}?jwt=${token}`)"
                            >
                              <v-icon> mdi-paperclip </v-icon>
                              Constancia de Situación Fiscal
                            </v-btn>
                          </v-col>

                          <template v-if="detallesItem.medio_verificacion != null && detallesItem.medio_verificacion.length > 0">
                            <v-col
                              v-for="(mv, idx) in detallesItem.medio_verificacion"
                              :key="`mv-${idx}`"
                              cols="6"
                              md="4"
                            >
                              <v-btn
                                block
                                text
                                class="text-none mb-4"
                                color="indigo-darken-3"
                                size="x-large"
                                variant="flat"
                                style="background-color: #0589bb; color: #fff"
                                @click="nuevaPestana(`/api/Pagos/DescargarDocumento/${detallesItem.caratula_bancaria}?jwt=${token}`)"
                              >
                                <v-icon> mdi-paperclip </v-icon>
                                Médio de Verificación {{idx + 1}}
                              </v-btn>
                            </v-col>
                          </template>

                          <v-col
                            v-if="detallesItem.caratula_bancaria != null"
                            cols="6"
                            md="4"
                          >
                            <v-btn
                              block
                              text
                              class="text-none mb-4"
                              color="indigo-darken-3"
                              size="x-large"
                              variant="flat"
                              style="background-color: #0589bb; color: #fff"
                              @click="nuevaPestana(`/api/Pagos/DescargarDocumento/${detallesItem.caratula_bancaria}?jwt=${token}`)"
                            >
                              <v-icon> mdi-paperclip </v-icon>
                              Caratúla Bancaria
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="detallesItem.opinion_cumplimiento != null"
                            cols="6"
                            md="4"
                          >
                            <v-btn
                              block
                              text
                              class="text-none mb-4"
                              color="indigo-darken-3"
                              size="x-large"
                              variant="flat"
                              style="background-color: #0589bb; color: #fff"
                              @click="nuevaPestana(`/api/Pagos/DescargarDocumento/${detallesItem.opinion_cumplimiento}?jwt=${token}`)"
                            >
                              <v-icon> mdi-paperclip </v-icon>
                              Opinión de Cumplimiento
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="detallesItem.bitacora != null"
                            cols="6"
                            md="4"
                          >
                            <v-btn
                              block
                              text
                              class="text-none mb-4"
                              color="indigo-darken-3"
                              size="x-large"
                              variant="flat"
                              style="background-color: #0589bb; color: #fff"
                              @click="nuevaPestana(`/api/Pagos/DescargarDocumento/${detallesItem.bitacora}?jwt=${token}`)"
                            >
                              <v-icon> mdi-paperclip </v-icon>
                              Bitácora
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                      <v-expansion-panel-header class="text-subtitle-1 text-center">
                        Seguimiento de Autorización de Pagos
                      </v-expansion-panel-header>
                        
                      <v-expansion-panel-content>
                        <v-col cols="12">
                        <v-data-table
                          :items="estado_solicitud_pagos"
                          :headers="estado_solicitud_headers"
                          :loading="loadingEstadoSolicitudPagos"
                        >
                        </v-data-table>
                      </v-col>
                      </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-subtitle-1 text-center font-weight-bold">
                Estado de la Solicitud de Pago
              </v-col>
              <v-col cols="12">
                <v-slider
                  v-model="selectedPosition"
                  :min="1"
                  :max="maxPosition"
                  :step="1"
                  ticks
                  tick-size="4"
                  thumb-label
                  :tick-labels="tickLabels"
                  @input="updateEstadoPago"
                  :disabled="!detallesItem.activo"
                ></v-slider>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="detallesItem.comentario"
                  auto-grow
                  rows="1"
                  label="Comentario"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="red"
                  @click="saveDetalles"
                  class="white--text"
                >
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                  Guardar Cambios
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--Dialogo para enviar y recibir los mensajes-->
      <v-dialog
        v-model="dialogoMensajes"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :max-width="dialogMaxWidth"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="cerrarDialogoMensajes">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title style="padding: 20px"> CHAT </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-col cols="12" md="12" style="background-color: rgb(255, 255, 255)">
            <v-card class="chat-container">
              <v-card-text
                v-for="message in conversaciones"
                :key="message.id"
                :class="[
                  message.propio ? 'user-message' : 'chat-message',
                ]"
              >
                <span class="message-sender">{{ message.usuario }}:</span>
                {{ message.mensaje }} -
                <span class="message-time">{{ message.fecha }}</span>
              </v-card-text>
            </v-card>

            <!-- Nuevo código para el campo de entrada de mensajes con el botón "Enviar" dentro -->
            <v-row style="background-color: #f3f3f3">
              <v-col cols="10" md="11">
                <v-textarea
                  v-model="newMessage"
                  label="Escribe un mensaje..."
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  @keydown.enter="sendMessage"
                >
                </v-textarea>
              </v-col>
              <v-col cols="1" md="1">
                <v-btn
                  text
                  color="red"
                  class="enviar"
                  @click="sendMessage"
                  :disabled="!newMessage.trim()"
                >
                  <v-icon> mdi-send </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-dialog>

      <!--Cierre del apartado de los mensajes-->

      
      <v-row>
        
        <v-col cols="12" md="4">
          <v-card
            class="mx-auto my-1"
            max-width="100%"
            style="background-color: #f39c2d"
          >
            <v-card-title style="color: #fff" class="d-flex justify-center">
              <v-icon style="font-size: 35px; color: #fff">
                mdi-file-document
              </v-icon>
              &nbsp; Solicitudes de Pago - Últimos 30 Días: &nbsp;&nbsp;&nbsp;
              <ICountUp
                :duration="5000"
                :delay="1000"
                :endVal="estadisticas.num_solicitudes"
                class="text-h5"
                style="color: #fff; font-weight: bold"
                :options="countupOptions"
              />
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card
            class="mx-auto my-1"
            max-width="100%"
            style="background-color: #0589bb"
          >
            <v-card-title style="color: #fff" class="d-flex justify-center">
              <v-icon style="font-size: 35px; color: #fff">
                mdi mdi-file-document-refresh-outline
              </v-icon>
              &nbsp; Solicitudes en Progreso: &nbsp;&nbsp;
              <ICountUp
                :duration="5000"
                :delay="1000"
                :endVal="estadisticas.num_progreso"
                class="text-h5"
                style="color: #fff; font-weight: bold"
                :options="countupOptions"
              />
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card
            class="mx-auto my-1"
            max-width="100%"
            style="background-color: #039569"
          >
            <v-card-title style="color: #fff" class="d-flex justify-center">
              <v-icon style="font-size: 35px; color: #fff">
                mdi mdi-file-document-check
              </v-icon>
              &nbsp; Solicitudes Finalizadas - Últimos 30 Días: &nbsp;&nbsp;
              <ICountUp
                :duration="5000"
                :delay="1000"
                :endVal="estadisticas.num_finalizadas"
                class="text-h5"
                style="color: #fff; font-weight: bold"
                :options="countupOptions"
              />
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card>
            <v-card-title class="justify-content-center">Solicitudes por Mes</v-card-title>
            <v-card-text>
              <chart-line
                ref="numsolicitudes"
              >
              </chart-line>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card>
            <v-card-title class="justify-content-center">Solicitudes por Estado</v-card-title>
            <v-card-text>
              <chart-donut
                ref="estadopagos"
              >
              </chart-donut>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card>
            <v-card-title class="justify-content-center">Ingreso por Mes</v-card-title>
            <v-card-text>
              <chart-bar
                ref="montosolicitudes"
                :useCurrencyFormat="true"
              >
              </chart-bar>
            </v-card-text>
          </v-card>
        </v-col>

        <!--Cierre de la grafica pero ahora con ApexCharts-->

        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="search.id_proveedor"
            :items="proveedores"
            label="Proveedor"
            item-value="id_proveedor"
            :item-text="codigoNombre"
            outlined
            dense
            hide-details
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="search.id_tipo_proveedor"
            :items="tipo_proveedores"
            label="Tipo de Proveedor"
            item-value="id_tipo_proveedor"
            item-text="nombre"
            dense
            outlined
            hide-details
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="search.id_estado_pago"
            :items="estados_pagos"
            label="Estado de la Solicitud"
            item-text="nombre"
            item-value="id_estado_pago"
            dense
            outlined
            hide-details
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4">
          <v-menu
            ref="menuDesde"
            v-model="search.menuDesde"
            :close-on-content-click="false"
            :return-value.sync="search.dateDesde"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="search.dateDesde"
                label="Desde"
                prepend-icon="mdi-calendar"
                readonly
                dense
                hide-details
                clearable
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="search.dateDesde" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="green" @click="search.menuDesde = false">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="red"
                @click="$refs.menuDesde.save(search.dateDesde)"
              >
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="4">
          <v-menu
            ref="menuHasta"
            v-model="search.menuHasta"
            :close-on-content-click="false"
            :return-value.sync="search.dateHasta"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="search.dateHasta"
                label="Hasta"
                prepend-icon="mdi-calendar"
                readonly
                dense
                hide-details
                clearable
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="search.dateHasta" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="green" @click="search.menuHasta = false">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="red"
                @click="$refs.menuHasta.save(search.dateHasta)"
              >
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="4">
          <v-btn block color="primary" text @click="getItems">
            <v-icon>mdi-magnify</v-icon>
            Buscar
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="solicitudes_pago"
            :loading="loading"
            :item-class="getRowClass"
          >
            <template v-slot:[`item.monto`]="{ item }">
              {{ item.monto != null ? `$${formatMoney(item.monto)} ${item.moneda}` : '$0.0' }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon class="mr-2" small @click="openMostrarDetalles(item)">
                mdi-eye
              </v-icon>

              <v-icon class="mr-2" small @click="abrirDialogoMensajes(item)">
                mdi-chat
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ICountUp from "vue-countup-v2";
import axios from "axios"
import { mapState } from 'vuex'
import VAlertDialog from '@/components/VAlertDialog.vue'
import ChartLine from '@/components/Charts/Line.vue'
import ChartBar from '@/components/Charts/Bar.vue'
import ChartDonut from '@/components/Charts/Donut.vue'

export default {
  components: {
    ICountUp,
    VAlertDialog,
    ChartLine,
    ChartBar,
    ChartDonut
  },
  computed: {
    ...mapState(['token']),
    maxPosition() {
      return Math.max(...this.estado_pagos_label.map(estado => estado.posicion));
    },
    tickLabels() {
      return this.estado_pagos_label.map(estado => estado.nombre);
    },
    currentEstadoPago() {
      return this.estado_pagos_label.find(estado => estado.posicion === this.selectedPosition);
    },
  },
  data: () => ({
    estadisticas: {
      num_solicitudes: 0,
      num_progreso: 0,
      num_finalizadas: 0
    },
    estado_solicitud_headers: [
      {
        text: "Realizado",
        value: "realizado",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado_pago",
        align: "center",
      },
      
      {
        text: "Realizado por",
        value: "usuario",
        align: "center",
      },
      {
        text: "Comentario",
        value: "comentario",
        align: "center",
      }
    ],
    dialog: false,
    dialogVer: false,
    dialogoDetalles: false,
    dialogoMensajes: false,
    messages: [],
    newMessage: "",
    dialogMaxWidth: 950,
    countupOptions: {
      suffix: ''
    },
    search: {
      id_proveedor: null,
      id_tipo_proveedor: null,
      id_estado_pago: null,
      menuDesde: false,
      dateDesde: null,
      menuHasta: false,
      dateHasta: null,
    },
    detallesItem: {
      id_solicitud_pago: null,
      id_proveedor: null,
      proveedor: null,
      id_tipo_solicitud: null,
      tipo_solicitud: null,
      id_metodo_pago: null,
      metodo_pago: null,
      id_usuario: null,
      nombre_completo_usuario: null,
      concepto: null,
      monto: null,
      id_moneda: null,
      moneda: null,
      factura_pdf: null,
      factura_xml: null,
      constancia_situacion_fiscal: null,
      medio_verificacion: null,
      caratula_bancaria: null,
      opinion_cumplimiento: null,
      bitacora: null,
      solicitada: null,
      tipo_persona: null,
      rfc: null,
      tipo_proveedor: null,
      id_estado_pago: null,
      id_estado_pago_original: null,
      comentario: null,
      activo: true
    },
    defaultDetallesItem: {
      id_solicitud_pago: null,
      id_proveedor: null,
      proveedor: null,
      id_tipo_solicitud: null,
      tipo_solicitud: null,
      id_metodo_pago: null,
      metodo_pago: null,
      id_usuario: null,
      nombre_completo_usuario: null,
      concepto: null,
      monto: null,
      id_moneda: null,
      moneda: null,
      factura_pdf: null,
      factura_xml: null,
      constancia_situacion_fiscal: null,
      medio_verificacion: null,
      caratula_bancaria: null,
      opinion_cumplimiento: null,
      bitacora: null,
      solicitada: null,
      tipo_persona: null,
      rfc: null,
      tipo_proveedor: null,
      id_estado_pago: null,
      id_estado_pago_original: null,
      comentario: null,
      activo: true
    },
    selectedPosition: null,

    //Cierra Datos de la Grafica

    headers: [
      {
        text: "Proveedor",
        value: "proveedor",
        align: "center",
      },
      {
        text: "Tipo de Solicitud",
        value: "tipo_solicitud",
        align: "center",
      },
      {
        text: "Concepto",
        value: "concepto",
        align: "center",
      },
      {
        text: "Monto",
        value: "monto",
        align: "center",
      },
      {
        text: "Método de Pago",
        value: "metodo_pago",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado_pago",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      }
    ],
    solicitudes_pago: [],
    loading: false,
    loadingTipoProveedores: false,
    tipo_proveedores: [],
    loadingProveedores: false,
    proveedores: [],
    loadingEstatusPagoss: false,
    estados_pagos: [],
    estado_pagos_label: [],
    loadingEstadoSolicitudPagos: false,
    estado_solicitud_pagos: [],
    loadingConversaciones: false,
    conversaciones: []
  }),

  mounted() {
    // Verificar el ancho de la pantalla al cargar la página
    this.actualizarAnchoDialogo();
    this.getItems();
    this.getProveedores();
    this.getTipoProveedores();
    this.getEstatusPagos();
    this.getEstadisticas();
  },

  methods: {
    getRowClass(item) {
      return item.sospechoso ? 'red-row' : '';
    },
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    codigoNombre: item => `[${item.clave}] - ${item.nombre_corto}`,
    getEstadisticas(){
      axios
        .get(`/Pagos/Estadisticas`)
        .then((response) => {
          this.estadisticas.num_solicitudes = response.data.num_solicitudes;
          this.estadisticas.num_progreso = response.data.num_progreso;
          this.estadisticas.num_finalizadas = response.data.num_finalizadas;

          this.$refs.numsolicitudes.setData(
            [
              {
                name: "# Solicitudes",
                data: response.data.solicitudes_mes_cantidad
              }
            ], response.data.solicitudes_mes_labels
          );

          this.$refs.montosolicitudes.setData(response.data.monto_moneda_mes, response.data.solicitudes_mes_labels);

          this.$refs.estadopagos.setData(response.data.estados_pagos_cantidad, response.data.estados_pagos_labels);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    getEstadoSolicitudPagos() {
      this.loadingEstadoSolicitudPagos = true;
      axios
        .get(`/Pagos/ListarEstadoPago/${this.detallesItem.id_solicitud_pago}`)
        .then((response) => {
          this.estado_solicitud_pagos = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstadoSolicitudPagos = false;
        });
    },
    getConversaciones() {
      this.loadingConversaciones = true;
      axios
        .get(`/Pagos/ListarMensajes/${this.detallesItem.id_solicitud_pago}`)
        .then((response) => {
          this.conversaciones = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingConversaciones = false;
        });
    },
    getProveedores() {
      this.loadingProveedores = true;
      axios
        .get("/Pagos/ListarProveedores")
        .then((response) => {
          this.proveedores = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingProveedores = false;
        });
    },
    getTipoProveedores() {
      this.loadingTipoProveedores = true;
      axios
        .get("/Catalogos/ListarTipoProveedores")
        .then((response) => {
          this.tipo_proveedores = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTipoProveedores = false;
        });
    },
    getEstatusPagos() {
      this.loadingEstatusPagoss = true;
      axios
        .get("/Catalogos/ListarEstadosPagos")
        .then((response) => {
          this.estados_pagos = response.data;
          this.estado_pagos_label = response.data
          .filter(x => x.posicion != 9);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstatusPagos = false;
        });
    },
    getItems() {
      this.loading = true;

      const url = new URL(`http://localhost/Pagos/ListarPagos`);
      if(this.search.id_proveedor != null) url.searchParams.append("id_proveedor", this.search.id_proveedor);
      if(this.search.id_tipo_proveedor != null) url.searchParams.append("id_tipo_proveedor", this.search.id_tipo_proveedor);
      if(this.search.id_estado_pago != null) url.searchParams.append("id_estado_pago", this.search.id_estado_pago);
      if(this.search.dateDesde != null) url.searchParams.append("desde", this.search.dateDesde);
      if(this.search.dateHasta != null) url.searchParams.append("hasta", this.search.dateHasta);
      
      var url_string = url.href.replace('http://localhost', '');

      axios
        .get(url_string)
        .then((response) => {
          this.solicitudes_pago = response.data.map(objeto => {
            return { ...objeto, comentario: null };
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    actualizarAnchoDialogo() {
      // Verificar si el ancho de la ventana es menor o igual a 600px (dispositivos móviles)
      if (window.innerWidth <= 600) {
        // Establecer dialogMaxWidth en null para que el diálogo ocupe todo el ancho de la pantalla
        this.dialogMaxWidth = null;
      } else {
        // Establecer el ancho máximo predeterminado del diálogo
        this.dialogMaxWidth = 950;
      }
    },

    openMostrarDetalles(item) {
      this.detallesItem =  Object.assign({}, item);
      this.detallesItem.id_estado_pago_original = this.detallesItem.id_estado_pago;
      this.getEstadoSolicitudPagos();
      this.dialogoDetalles = true;

      this.selectedPosition = this.estado_pagos_label.findIndex(estado => estado.id_estado_pago === item.id_estado_pago) + 1;
    },
    closeMostrarDetalles() {
      this.detallesItem = Object.assign({}, this.defaultDetallesItem);
      this.estado_solicitud_pagos = [];
      this.dialogoDetalles = false;
      this.selectedPosition = null;
    },
    saveDetalles(){
      axios
      .post('/Pagos/ActualizarEstadoPago',{
        id_solicitud_pago: this.detallesItem.id_solicitud_pago,
        id_estado_pago: this.detallesItem.id_estado_pago,
        comentario: this.detallesItem.comentario
      })
      .then(() => {
        this.closeMostrarDetalles();
        this.getItems();
        this.getEstadisticas();
        this.$emit('change', null);
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingEstados = false;
      })
    },

    abrirDialogoMensajes(item) {
      this.detallesItem = item;
      this.dialogoMensajes = true;
      this.getConversaciones();
    },
    formatTime(time) {
      const options = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("es-MX", options).format(time);
    },

    sendMessage() {
      // Verifica si newMessage no está vacío ni contiene solo espacios en blanco
      if (this.newMessage.trim() !== "") {
        this.sending_msg = true;
        axios
        .post('/Pagos/CrearMensaje', {
          id_solicitud_pago: this.detallesItem.id_solicitud_pago,
          mensaje: this.newMessage.trim()
        })
        .then(() => {
          this.getConversaciones();
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.sending_msg = false;
        })
        /*
        const currentTime = new Date();
        const formattedTime = this.formatTime(currentTime);

        this.messages.push({
          id: this.messages.length + 1,
          text: this.newMessage.trim(),
          sender: "Tú",
          formattedTime: formattedTime,
          // Puedes agregar más información del mensaje si es necesario
        });
        */

        // Limpia el campo de entrada después de enviar el mensaje
        this.newMessage = "";
      }
    },

    cerrarDialogoMensajes() {
      this.conversaciones = [];
      this.detallesItem = Object.assign({}, this.defaultDetallesItem);
      this.dialogoMensajes = false;

    },

    //Metodos de la grafica
    updateChartData(newData) {
      this.chartData = newData;
    },

    formatMoney(number) {
        // Convierte el número a un string con dos decimales
        let numberString = number.toFixed(2);

        // Usa una expresión regular para agregar comas cada tres dígitos
        let formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return formattedNumber;
    },

    created() {
      // Escuchar cambios en el tamaño de la ventana y actualizar el ancho del diálogo en consecuencia
      window.addEventListener("resize", this.actualizarAnchoDialogo);
    },
    beforeDestroy() {
      // Eliminar el listener del evento 'resize' antes de destruir el componente
      window.removeEventListener("resize", this.actualizarAnchoDialogo);
    },
    updateEstadoPago(newPosition) {
      var actual_position = (this.estado_pagos_label.findIndex(estado => estado.id_estado_pago == this.detallesItem.id_estado_pago_original)+1);
      if(actual_position == 0) return;
      var nuevo_estado = this.estado_pagos_label.find(estado => estado.posicion === newPosition);
      
      if (
        (newPosition <= actual_position) || //Retroceder a Fases Anteriores
        ((actual_position + 1) == nuevo_estado.posicion) || //Fase Siguiente
        (nuevo_estado.id_estado_pago == 'fe64c65b-bc29-403a-accf-46e9c8cba10d' || nuevo_estado.id_estado_pago == 'e566a737-c844-4346-aa8c-578b01134e65') //Fase de Terminado o Cancelacion
      )
      {
        if(
          nuevo_estado.id_estado_pago == '30a39df5-abc4-42e0-b531-cfe69d3e43a0' &&
          (
            (this.detallesItem.id_moneda == '99add4b6-a0e3-4dcd-b7ab-b7b82ad21b7d' && this.detallesItem.monto >= 100000) || //Mayor a 100K MXN
            (this.detallesItem.id_moneda == 'abcce1d3-96c2-418f-a6a5-3ba87f0cee7e' && this.detallesItem.monto >= 5500) //Mayor a 5.5K USD
          )
        ){
          this.$refs.alert.setMessage('', `Fase Denegada: Esta operación debe de ser realizada en Autorización de Pagos de Alto Monto`);
          this.$refs.alert.open();
          setTimeout(() => {
            this.selectedPosition = actual_position;
            
          },250);
        }
        else{
          this.detallesItem.id_estado_pago = this.currentEstadoPago.id_estado_pago
        } 
      }
      else{
        this.$refs.alert.setMessage('', `Fase Denegada: Debe completar las fases previas antes de acceder a esta fase.`);
        this.$refs.alert.open();
        setTimeout(() => {
          this.selectedPosition = actual_position;
        },250);
        return;
      }
    }
  },
};
</script>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

.estilo-tabla th {
  background-color: #00a8ab;
  border: solid 1px #fff;
  color: #fff !important;
  font-size: 15px !important;
}

.estilo-tabla th:hover {
  background-color: #016567;
}

.estilo-tabla.v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

/* Estilo para el contenedor de mensajes */
.chat-container {
  max-height: 400px;
  overflow-y: scroll;
  background-color: #dfe6ec !important;
  padding: 10px;
  border-radius: 8px;
  height: 250px;
  margin-bottom: 2%;
  overflow: auto;
  background-color: transparent;
}

/* Estilo para cada mensaje en la bandeja */
.chat-message {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: #49a76f;
  color: #fff !important;
  font-size: 14px !important;
}

/* Estilo para los mensajes del usuario */
.user-message {
  background-color: #49a76f;
  text-align: right;
  width: 70% !important;
  position: relative;
  margin: 0 auto;
  right: 0;
  color: #fff !important;
  margin-right: 0;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  border-radius: 10px !important;
}

.botonenviar {
  position: relative !important;
  top: 290% !important;
}

@media only screen and (max-width: 600px) {
  .titulo {
    font-size: 14px !important;
  }

  .theme--light.v-file-input .v-file-input__text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 10px !important;
  }

  .chat-container {
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 8px;
    height: 100vh;
    padding: 50px !important;
    overflow: auto;
    background-color: transparent;
  }

  .user-message {
    background-color: #49a76f;
    text-align: right;
    width: 100% !important;
    position: relative;
    margin: 0 auto;
    right: 0;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-bottom: 10px !important;
    border-radius: 10px !important;
    margin-right: -40px;
  }
}

.enviar {
  position: relative !important;
  align-self: flex-end !important;
  margin-top: auto !important;
  top: 30% !important;
}

@media only screen and (max-width: 370px) {
  .titulo {
    font-size: 14px !important;
  }

  .theme--light.v-file-input .v-file-input__text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px !important;
  }
}

.message-time {
  color: #fff !important;
  font-weight: 800 !important;
}

/* Estilo para el remitente del mensaje */
.message-sender {
  font-weight: bold;
  margin-right: 5px;
}

/* Estilo para la marca de tiempo del mensaje */
.message-time {
  font-weight: bold;
  font-size: 12px;
  color: #a0a0a0b0;
}

/* Estilo para el área de entrada de mensajes */
.message-input {
  margin-top: 10px;
}


.justify-content-center {
  justify-content: center !important;
}

.red-row {
  background-color: red !important;
  color: white;
}
</style>
