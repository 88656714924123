<template>
  <div
    class="pa-3 ma-3"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_documento_referencia_bibliografica === null ? "Crear" : "Editar" }} Documento Referencia Bibliografica
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"
              v-if="editedItem.id_documento_referencia_bibliografica != null"
            >
              <v-text-field
                v-model="editedItem.documento"
                label="Documento"
                outlined
                readonly
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12"
              v-else
            >
              <v-file-input
                v-model="editedItem.fichero"
                :rules="[requiredRule, pdfRule]"
                label="Archivo PDF"
                accept=".pdf"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="editedItem.ids_diagnosticos_medicos"
                dense
                hide-details
                multiple
                chips
                deletable-chips
                label="Diagnosticos Médicos"
                :items="diagnosticosMedicos"
                :item-text="nombreDiagnosticoMedico"
                item-value="id_diagnostico_medico"
                outlined

              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Documento Referencia Bibliografica
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el documento de referencia bibliográfica llamado "{{ editedItem.documento }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
        >
          Crear Documento Referencia Bibliografica
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.diagnosticos_medicos`]="{ item }">
            <ul>
              <li v-for="(dm, idx) in item.diagnosticos_medicos" :key="`drb-${idx}`">
                {{dm}}
              </li>
            </ul>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue';

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Documento Referencia Bibliografica",
        value: "documento",
        align: "center",
        width: "60%"
      },
      {
        text: "Diagnosticos Médicos",
        value: "diagnosticos_medicos",
        align: "center",
        width: "30%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%"
      }
    ],
    editedItem: {
      id_documento_referencia_bibliografica: null,
      documento: "",
      ids_diagnosticos_medicos: [],
      diagnosticos_medicos: [],
      fichero: null,
    },
    defaultEditedItem: {
      id_documento_referencia_bibliografica: null,
      documento: "",
      ids_diagnosticos_medicos: [],
      diagnosticos_medicos: [],
      fichero: null,
    },
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false,
    loadingDiagnosticoMedico: false,
    diagnosticosMedicos: [],
  }),
  mounted() {
    this.getItems();
    this.getDiagnosticosMedicos();
  },
  methods: {
    requiredRule() {
      return [(v) => !!v || "Este campo es obligatorio"];
    },
    pdfRule() {
      return [(v) => /\.pdf$/.test(v.name) || "El archivo debe ser PDF"];
    },
    nombreDiagnosticoMedico: estado => `[${estado.codigo}] - ${estado.diagnostico}`,
    getItems(){
      this.loading = true;
      axios
        .get('/Catalogos/ListarDocumentosReferenciasBibliograficas')
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getDiagnosticosMedicos() {
      this.loadingDiagnosticoMedico = true;
      axios
        .get('/Catalogos/ListarDiagnosticosMedicos')
        .then((response) => {
          this.diagnosticosMedicos = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDiagnosticoMedico = false;
        });
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      if(this.editedItem.id_documento_referencia_bibliografica === null) {
         if (!this.editedItem.fichero) {
          // Archivo no seleccionado
          this.$refs.alert.setMessage("", "Debe de Ingresar un Archivo PDF");
          this.$refs.alert.open();
          return;
        }
        
        if (!this.pdfRule(this.editedItem.fichero)) {
          // Archivo no es PDF
          this.$refs.alert.setMessage("", "El Archivo debe de ser PDF");
          this.$refs.alert.open();
          return;
        }

        let formData = new FormData();
        formData.append('documento', this.editedItem.fichero);
        formData.append('ids_diagnosticos_medicos', JSON.stringify(this.editedItem.ids_diagnosticos_medicos));

        axios.post('/api/Catalogos/CrearDocumentoReferenciaBibliografica', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          // Manejar la respuesta del servidor
          this.getItems();
          this.closeDialogAddEdit();
        })
        .catch(error => {
          // Manejar errores
          console.error('Error al cargar el archivo:', error);
        })
        .finally(() => {
          this.loadingSave = false;
        });
      }
      else {
        axios
        .put(`/Catalogos/EditarDocumentoReferenciaBibliografica/${this.editedItem.id_documento_referencia_bibliografica}`,{
          ids_diagnosticos_medicos: this.editedItem.ids_diagnosticos_medicos
        })
        .then(() => {
          this.getItems();
          this.closeDialogAddEdit();
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loadingSave = false;
        });
      }
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
        .delete(`/Catalogos/EliminarDocumentoReferenciaBibliografica/${this.editedItem.id_documento_referencia_bibliografica}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    }
  }
}
</script>
