<template>
  <v-dialog
    max-width="90%"
    v-model="dialogExpediente"
    persistent
  >
     <v-card>
      <v-card-title>
        <!--
        Expediente de {{paciente.nombre}} {{paciente.apellido_materno}} {{paciente.apellido_paterno}}
        -->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-card>
              <v-card-text>
                <v-img
                  height="200"
                  :src="(paciente.fotografia != null) ? `/api/archivos/pacientes/${paciente.fotografia}?jwt=${token}` : '/img/persona.jpg'"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="10">
            <v-card>
              <v-card-text>
                <v-row class="ma-0 pa-0">
                  <v-col cols="6">
                    <span class="font-weight-bold">Folio Paciente:</span> {{paciente.folio}}
                    <br>
                    <span class="font-weight-bold">Nombre:</span> {{paciente.nombre}}
                    <br>
                    <span class="font-weight-bold">Apellido Paterno:</span> {{paciente.apellido_paterno}}
                    <br>
                    <span class="font-weight-bold">Apellido Materno:</span> {{paciente.apellido_materno}}
                    <br>
                    <span class="font-weight-bold">Fecha de Nacimiento:</span> {{paciente.fecha_nacimiento}}
                    <br>
                    <span class="font-weight-bold">Edad:</span> {{paciente.edad}}
                    <br>
                    <span class="font-weight-bold">Sexo:</span> {{paciente.genero ? 'Masculino' : 'Femenino'}}
                  </v-col>
                  <v-col cols="6">
                    <span class="font-weight-bold">Estado:</span> {{paciente.estado}}
                    <br>
                    <span class="font-weight-bold">Municipio:</span> {{paciente.municipio}}
                    <br>
                    <span class="font-weight-bold">Localidad de Atención:</span> {{paciente.localidad_atencion}}
                    <br>
                    <span class="font-weight-bold">CURP:</span> {{paciente.curp || "N/D"}}
                    <br>
                    <span class="font-weight-bold">Poliza Seguro Popular:</span> {{paciente.seguro_popular ? 'Si' : 'No'}}
                    <br>
                    <span class="font-weight-bold">No. Póliza Seguro Popular:</span> {{paciente.num_poliza || "N/D"}}
                    <br>
                    <span class="font-weight-bold">Celular o teléfono de contacto:</span> {{paciente.telefono || "N/D"}}
                    <br>
                    <span class="font-weight-bold">Correo electrónico:</span> {{paciente.email || "N/D"}}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              grow
            >
              <v-tab>
                Información General
              </v-tab>
              <v-tab>
                Historial de Consultas
              </v-tab>

              <v-tab-item eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3">
                        <v-card>
                          <v-card-text>
                            <div class="text-subtitle-1">Signos Vitales mas Recientes</div>
                            <br>
                            <v-row class="ma-0 pa-0">
                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="green darken-2"
                                >
                                  mdi-weight-kilogram
                                </v-icon>
                              </v-col>
                              <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                Peso: {{paciente.signos_vitales.peso}} Kg
                              </v-col>
                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="blue darken-2"
                                >
                                  mdi-human-male-height-variant
                                </v-icon>
                              </v-col>
                              <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                Estatura: {{paciente.signos_vitales.estatura}} m
                              </v-col>
                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="purple darken-2"
                                >
                                  mdi-percent-outline
                                </v-icon>
                              </v-col>
                              <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                IMC: {{paciente.signos_vitales.imc}}
                              </v-col>
                              <v-col
                                v-if="paciente.signos_vitales.cintura != null"
                                cols="2"
                                class="ma-0 pa-0 text-center"
                              >
                                <v-icon
                                  large
                                  color="teal darken-2"
                                >
                                  mdi-tape-measure
                                </v-icon>
                              </v-col>
                              <v-col
                                v-if="paciente.signos_vitales.cintura != null"
                                cols="10"
                                class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                              >
                                Cintura: {{paciente.signos_vitales.cintura}} cm
                              </v-col>
                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="blue-grey darken-2"
                                >
                                  mdi-thermometer
                                </v-icon>
                              </v-col>
                              <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                Temperatura: {{paciente.signos_vitales.temperatura}}° C
                              </v-col>
                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="red darken-2"
                                >
                                  mdi-heart
                                </v-icon>
                              </v-col>
                              <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                Pulso: {{paciente.signos_vitales.pulso}} ppm
                              </v-col>
                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="orange darken-2"
                                >
                                  mdi-lungs
                                </v-icon>
                              </v-col>
                              <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                Respiración: {{paciente.signos_vitales.respiracion}} rpm
                              </v-col>
                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="indigo darken-2"
                                >
                                  mdi-counter
                                </v-icon>
                              </v-col>
                              <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                Oximetría: {{paciente.signos_vitales.oximetria}}% Sp02
                              </v-col>

                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="teal darken-2"
                                  v-if="paciente.signos_vitales.antigeno_prostatico != null"
                                >
                                  mdi-clipboard-account-outline
                                </v-icon>
                              </v-col>
                              <v-col
                                v-if="paciente.signos_vitales.antigeno_prostatico != null"
                                cols="10"
                                class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                              >
                                Antigeno Prostatico: {{paciente.signos_vitales.antigeno_prostatico}}% Sp02
                              </v-col>

                              <v-col cols="2" class="ma-0 pa-0 text-center">
                                <v-icon
                                  large
                                  color="green darken-2"
                                  v-if="paciente.signos_vitales.otros != null"
                                >
                                  mdi-comment-text
                                </v-icon>
                              </v-col>
                              <v-col
                                v-if="paciente.signos_vitales.otros != null"
                                cols="10"
                                class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                              >
                                Observaciones: {{paciente.signos_vitales.otros}}
                              </v-col>

                              <v-col
                                v-if="paciente.signos_vitales.sistolica_izquierda != null || paciente.signos_vitales.sistolica_derecha != null"
                                cols="2"
                                class="ma-0 pa-0 text-center align-self-center"
                              >
                                <v-icon
                                  large
                                  color="blue darken-2"
                                >
                                  mdi-heart-pulse
                                </v-icon>
                              </v-col>
                              <v-col
                                v-if="paciente.signos_vitales.sistolica_izquierda != null || paciente.signos_vitales.sistolica_derecha != null || paciente.signos_vitales.hipertension_arterial_otros != null"
                                cols="10"
                                class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                              >
                                <template
                                  v-if="paciente.signos_vitales.sistolica_izquierda != null"
                                >
                                  T.A. Sistólica izq: {{paciente.signos_vitales.sistolica_izquierda}}
                                  <br>
                                </template>
                                <template
                                  v-if="paciente.signos_vitales.diastolostica_izquierda != null"
                                >
                                  T.A. Diastólica izq: {{paciente.signos_vitales.diastolostica_izquierda}}
                                  <br>
                                </template>

                                <template
                                  v-if="paciente.signos_vitales.sistolica_derecha != null"
                                >
                                  T.A. Sistólica der: {{paciente.signos_vitales.sistolica_derecha}}
                                  <br>
                                </template>
                                <template
                                  v-if="paciente.signos_vitales.diastolostica_derecha != null"
                                >
                                  T.A. Diastólica der: {{paciente.signos_vitales.diastolostica_derecha}}
                                  <br>
                                </template>
                                <template
                                  v-if="paciente.signos_vitales.hipertension_arterial_otros != null"
                                >
                                  Observaciones: {{paciente.signos_vitales.hipertension_arterial_otros}}
                                  <br>
                                </template>
                                <br>
                              </v-col>

                              <v-col cols="2" class="ma-0 pa-0 text-center align-self-center">
                                <v-icon
                                  large
                                  color="purple darken-2"
                                  v-if="paciente.signos_vitales.glucosa != null || (paciente.signos_vitales.colesterol != null || paciente.signos_vitales.colesterol_otro != null) || (paciente.signos_vitales.trigliceridos != null || paciente.signos_vitales.trigliceridos_otro != null)"
                                >
                                  mdi-flask
                                </v-icon>
                              </v-col>
                              <v-col
                                v-if="paciente.signos_vitales.glucosa != null || (paciente.signos_vitales.colesterol != null || paciente.signos_vitales.colesterol_otro != null) || (paciente.signos_vitales.trigliceridos != null || paciente.signos_vitales.trigliceridos_otro != null)"
                                cols="10"
                                class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                              >
                                <template v-if="paciente.signos_vitales.glucosa != null">
                                  Glucosa: {{paciente.signos_vitales.glucosa}}
                                  <br>
                                </template>
                                <template v-if="(paciente.signos_vitales.colesterol != null || paciente.signos_vitales.colesterol_otro != null)">
                                  Colesterol: {{paciente.signos_vitales.colesterol_otro || paciente.signos_vitales.colesterol}}
                                  <br>
                                </template>
                                <template v-if="(paciente.signos_vitales.trigliceridos != null || paciente.signos_vitales.trigliceridos_otro != null)">
                                  Trigliceridos: {{paciente.signos_vitales.trigliceridos_otro || paciente.signos_vitales.trigliceridos}}
                                  <br>
                                </template>
                                <template v-if="paciente.signos_vitales.quimicas_secas_observaciones != null">
                                  Observaciones: {{paciente.signos_vitales.quimicas_secas_observaciones}}
                                </template>
                              </v-col>
                            </v-row>
                            <br>
                            <div class="text-body-2">Fecha de Mediciones: {{paciente.signos_vitales.fecha}}</div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="9">
                        <v-card>
                          <v-card-text>
                            <div class="text-subtitle-1">Revisiones Medicas</div>
                            <br>
                            <v-data-table
                              :items="paciente.consultas"
                              :headers="paciente.servicios"
                              no-data-text="No hay Revisiones Medicas Anteriores"
                              hide-default-footer
                            >
                              <template v-for="header in paciente.servicios" v-slot:[`item.${header.value}`]="{ item }">
                                <!-- Contenido personalizado para cada columna -->
                                <template v-if="typeof item[header.value] == 'string'">
                                  <span
                                    :key="'col-'+header.value"
                                  >
                                    {{ item[header.value] }}
                                  </span>
                                </template>
                                <template v-else>
                                  <v-icon
                                    v-if="item[header.value] == true"
                                    :key="'col-'+header.value"
                                    color="green"
                                  >
                                    mdi-check
                                  </v-icon>

                                  <v-icon
                                    v-if="item[header.value] == false"
                                    :key="'col-'+header.value"
                                    color="red"
                                  >
                                    mdi-clock-alert-outline
                                  </v-icon>
                                </template>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item eager>
                <v-card flat>
                  <v-card-text v-if="!paciente.historico">
                    <historial
                      ref="historial"
                    >
                    </historial>
                  </v-card-text>
                  <v-card-text v-else>
                    <div class="text-center">
                      <v-icon
                        size="100"
                        color="yellow"
                      >
                        mdi-alert
                      </v-icon>
                    </div>
                    Este paciente se considera histórico debido a que sus registros se realizaron antes de la implementación del sistema actual. Como resultado, su historial de consultas no está disponible en esta plataforma.
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Historial from '@/components/DashViews/Comunes/Historial.vue'

export default {
  components: {
    Historial
  },
  data: () => ({
    tab: 0,
    dialogExpediente: false,
    id_paciente: null,
    paciente: {
      id_paciente: null,
      id_municipio: null,
      municipio: "",
      id_estado: null,
      estado: "",
      id_localidad_atencion: null,
      localidad_atencion: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      edad: "",
      genero: false,
      curp: null,
      seguro_popular: false,
      num_poliza: null,
      telefono: null,
      email: null,
      servicios: [],
      consultas: [],
      historico: false,
      signos_vitales: {
        peso: null,
        estatura: null,
        imc: null,
        cintura: null,
        temperatura: null,
        pulso: null,
        respiracion: null,
        oximetria: null,
        antigeno_prostatico: null,
        otros: null,
        sistolica_izquierda: null,
        diastolostica_izquierda: null,
        sistolica_derecha: null,
        diastolostica_derecha: null,
        hipertension_arterial_otros: null,
        glucosa: null,
        colesterol: null,
        colesterol_otro: null,
        trigliceridos: null,
        trigliceridos_otro: null,
        quimicas_secas_observaciones: null,
        fecha: null
      }
    },
    defaultPaciente: {
      id_paciente: null,
      id_municipio: null,
      municipio: "",
      id_estado: null,
      estado: "",
      id_localidad_atencion: null,
      localidad_atencion: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      edad: "",
      genero: false,
      curp: null,
      seguro_popular: false,
      num_poliza: null,
      telefono: null,
      email: null,
      servicios: [],
      consultas: [],
      historico: false,
      signos_vitales: {
        peso: null,
        estatura: null,
        imc: null,
        cintura: null,
        temperatura: null,
        pulso: null,
        respiracion: null,
        oximetria: null,
        antigeno_prostatico: null,
        otros: null,
        sistolica_izquierda: null,
        diastolostica_izquierda: null,
        sistolica_derecha: null,
        diastolostica_derecha: null,
        hipertension_arterial_otros: null,
        glucosa: null,
        colesterol: null,
        colesterol_otro: null,
        trigliceridos: null,
        trigliceridos_otro: null,
        quimicas_secas_observaciones: null,
        fecha: null
      }
    }
  }),
  computed: {
    ...mapState(['token'])
  },
  methods: {
    getInformation(){
      this.paciente = JSON.parse(JSON.stringify(this.defaultPaciente));
      this.loading = true;
      axios
      .get(`/Pacientes/Expediente/${this.id_paciente}`)
      .then(response => {
        setTimeout(() => {
          this.$refs.historial.cambiarPaciente(this.id_paciente);
        },500);
        this.paciente = response.data;
        this.paciente.servicios.unshift({
          text: "Fecha",
          value: "fecha",
          align: "center",
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialog(id_paciente){
      this.dialogExpediente = true;
      this.id_paciente = id_paciente;
      this.getInformation();
    },
    closeDialog(){
      try {
        this.$refs.historial.limpiarHistorial();
      } catch (error) { 
          console.log(error);
      }

      this.dialogExpediente = false;
      this.tab = 0;
      this.paciente = JSON.parse(JSON.stringify(this.defaultPaciente));
    }
  }
}
</script>