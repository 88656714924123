<template>
  <v-row justify="center">
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      v-model="dialogComplete"
      max-width="50%"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon
            size="300"
            color="green"
          >
            mdi-check-circle
          </v-icon>
          <div class="text-h4">
            Consulta Dental Guardada Correctamente
          </div>
          <br>
          <v-btn
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasDentales/ObtenerHistoriaDental/${consultaDental.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Historia Clínica Odontologíca
          </v-btn>
          <v-btn
            v-if="response_consulta.receta"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasDentales/ObtenerRecetaMedica/${consultaDental.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Receta Médica
          </v-btn>
          <v-btn
            v-if="editedItem.referencia_medica == true"
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasDentales/ReferenciaMedica/${consultaDental.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Referencia Médica
          </v-btn>
          <v-btn
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasDentales/ObtenerPreventivaOdontologica/${consultaDental.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Odontología Preventiva
          </v-btn>
          <v-btn
            block
            color="primary"
            text
            @click="nuevaPestana(`/api/ConsultasDentales/ObtenerConsentimientoDental/${consultaDental.id_paciente_servicio}?jwt=${token}`)"
          >
            <v-icon>
              mdi-download
            </v-icon>
            Consentimiento de Procedimientos Dentales
          </v-btn>
          <br>          
          <v-btn
            block
            color="green"
            class="white--text"
            @click="cerrarDialogo"
          >
            Aceptar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
              icon
              dark
              @click="cancelarInicioConsulta"
            >
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Consulta Dental</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <br>
        <referencia-medica
          ref="referenciamedica"
        >
        </referencia-medica>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-row class="ma-0 pa-0 sticky-content">
                <v-col cols="12" class="ma-0 pa-0">
                  <PacienteDatos
                    :id_paciente_servicio="consultaDental.id_paciente_servicio"
                    ref="paciente_datos"
                    @change="actualizarDatos"
                  ></PacienteDatos>
                </v-col>
                <v-col cols="12"
                  v-if="consultaDental.num_consultas_medicas > 1 || consultaDental.num_consultas_dentales > 1"
                >
                  <v-btn
                    block
                    text
                    color="primary"
                    @click="abrirHistorial"
                  >
                    <v-icon>
                      mdi-book-open-blank-variant-outline
                    </v-icon>
                    Mostrar Historia Clinica
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9">
              <v-row class="pa-1 ma-0" v-if="consultaDental.edad < 18">
                <v-col cols="3" class="pa-0 ma-0">
                  Nombre completo y parentesco del informante
                  <br>
                  <span class="text-caption">
                    (en caso necesario)
                  </span>
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 pr-2">
                  <v-text-field
                    v-model="editedItem.informante_apellido_paterno"
                    label="Apellido Paterno"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 px-2">
                  <v-text-field
                    v-model="editedItem.informante_apellido_materno"
                    label="Apellido Materno"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 pl-2">
                  <v-text-field
                    v-model="editedItem.informante_nombre"
                    label="Nombre(s)"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete-add
                    v-model="editedItem.id_ocupacion"
                    label="Ocupación"
                    item-value="id_ocupacion"
                    item-text="nombre"
                    url-get="/Catalogos/ListarOcupaciones"
                    url-save="/Catalogos/CrearOcupacion"
                  >
                  </v-autocomplete-add>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete-add
                    v-model="editedItem.id_estado_civil"
                    label="Estado Civil"
                    item-value="id_estado_civil"
                    item-text="nombre"
                    url-get="/Catalogos/ListarEstadosCiviles"
                    url-save="/Catalogos/CrearEstadoCivil"
                  >
                  </v-autocomplete-add>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete-add
                    v-model="editedItem.id_grupo_etnico"
                    label="Grupo Etnico"
                    item-value="id_grupo_etnico"
                    item-text="nombre"
                    url-get="/Catalogos/ListarGruposEtnicos"
                    url-save="/Catalogos/CrearGrupoEtnico"
                  >
                  </v-autocomplete-add>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete-add
                    v-model="editedItem.id_religion"
                    label="Religión"
                    item-value="id_religion"
                    item-text="nombre"
                    url-get="/Catalogos/ListarReligiones"
                    url-save="/Catalogos/CrearReligion"
                  >
                  </v-autocomplete-add>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="editedItem.domicilio"
                    label="Domicilio"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="editedItem.telefono"
                    label="Teléfono"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.motivo_consulta"
                    label="Motivo de Consulta"
                    outlined
                    hide-details
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.padecimiento_actual"
                    label="Padecimiento Actual"
                    auto-grow
                    outlined
                    rows="2"
                    hide-details
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <span class="font-weight-bold">Antecedentes heredofamiliares</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Diabetes:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_heredofamiliares_diatebes"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                          label="Si"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_heredofamiliares_diatebes_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Hipertensión:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_heredofamiliares_hipertension"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_heredofamiliares_hipertension_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Otros:
                    </v-col>
                    <v-col cols="9" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_heredofamiliares_otros"
                        outlined
                        hide-details
                        dense
                        rows="1"
                        auto-grow
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <br>
                  <br>
                  <span class="font-weight-bold">Higiene Oral</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-textarea
                        v-model="editedItem.higiene_oral"
                        :rows="3"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <span class="font-weight-bold">Articulación temporomandibular y otros hallazgos</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="12" class="pa-0 ma-0">
                      <v-textarea
                        v-model="editedItem.articulacion_temporomandibular"
                        :rows="3"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <span class="font-weight-bold">Antecedentes patológicos y no patológicos</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Diabetes:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_diabetes"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                          label="Si"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_diabetes_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Hipertensión:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_hipertension"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_hipertension_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>


                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Cardiopatías
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_cardiopatias"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_cardiopatias_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Respiratorias:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_respiratorias"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_respiratorias_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Neoplásticas:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_neoplasticas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_neoplasticas_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>


                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      E.T.S. (VIH)
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_ets"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_ets_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Alergias
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_alergias"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_alergias_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Transfusiones:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_transfusiones"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_transfusiones_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Embarazo:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_embarazo"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_embarazo_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Medicamentos:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_medicamentos"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_medicamentos_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Toxicomanias
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.antecedentes_patologicos_toxicomanias"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_toxicomanias_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Otros:
                    </v-col>
                    <v-col cols="9" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.antecedentes_patologicos_otros"
                        outlined
                        hide-details
                        dense
                        rows="1"
                        auto-grow
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <span class="font-weight-bold">Exploración Física</span>
              <br>
              <span>(Marcar en el recuadro si se ha encontrado alguna alteración durante la exploración y especificar)</span>
              <v-row>
                
                <v-col cols="6">
                  <span class="font-weight-bold">Intraoral</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Labios:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_labios"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                          label="Si"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_labios_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Lengua:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_lengua"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_lengua_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Carrillos:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_carrillos"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_carrillos_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Paladar Duro:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_paladar_duro"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_paladar_duro_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Piso de boca:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_piso_boca"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_piso_boca_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Glándulas
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_glandulas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_glandulas_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>


                <v-col cols="6">
                  <span></span>
                  <br>
                  <span></span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Salivares:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_salivares"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_salivares_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Encias:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_intraoral_encias"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_intraoral_encias_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <span class="font-weight-bold">Extraoral</span>
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Cabeza:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_extraoral_cabeza"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                          label="Si"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_extraoral_cabeza_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Cuello:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_extraoral_cuello"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_extraoral_cuello_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Rostro:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_extraoral_rostro"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_extraoral_rostro_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Músculos:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.exploracion_extraoral_musculos"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.exploracion_extraoral_musculos_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <br>
              <!--
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Impresión Diagnóstica"
                    :rows="2"
                    auto-grow
                    outlined
                    hide-details
                    dense
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              -->

              <v-row class="pa-1 ma-0">
                <v-col cols="4" class="pa-0 ma-0 font-weight-bold">
                  Expedir Receta Médica
                </v-col>
                <v-col cols="8" class="pa-0 ma-0">
                  <v-radio-group
                    v-model="editedItem.receta_medica"
                    required
                    row
                    hide-details
                    dense
                    class="ma-0 pa-0"
                  >
                    <v-radio
                        label="Si"
                        :value="true"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row class="ma-0 pa-0"
                v-if="editedItem.receta_medica"
              >
                <v-col cols="12">
                  <v-data-table
                    :headers="headersMedicamentos"
                    :items="editedItem.tratamientos"
                    hide-default-footer
                    hide-default-header
                  >
                    <template v-slot:[`item.tratamiento`]="{ item }">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="8" class="ma-0 pa-2">
                          <v-autocomplete
                            v-model="item.id_medicamento"
                            outlined
                            label="Medicamento"
                            hide-details
                            :items="medicamentos"
                            :item-text="medicamentoNombre"
                            item-value="id_medicamento"
                            dense
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4" class="ma-0 pa-2">
                          <v-number-field
                            v-model="item.cantidad"
                            label="Cantidad"
                          >
                          </v-number-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-2">
                          <v-textarea
                            v-model="item.indicaciones"
                            outlined
                            label="Indicaciones"
                            rows="1"
                            auto-grow
                            dense
                            hide-details
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip-icon
                        icon="mdi-trash-can-outline"
                        text="Eliminar"
                        @click="eliminarMedicamento(item)"
                      ></v-tooltip-icon>
                    </template> 
                  </v-data-table>
                  <v-btn
                    color="primary"
                    @click="agregarMedicamento"
                    text
                  >
                    Agregar Medicamento
                  </v-btn>
                </v-col>
              </v-row>
              <br>
              <span class="font-weight-bold">Recomendaciones de acuerdo con su diagnóstico</span>
              <v-row>
                <v-col cols="6">
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Tipo de Cepillo:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_tipo_cepillo"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                          label="Si"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_tipo_cepillo_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Técnica de cepillado:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_tecnica_cepillado"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_tecnica_cepillado_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Uso de tabletas reveladoras:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_uso_tabletas"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_uso_tabletas_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Aplicación de flúor:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_aplicacion_fluor"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_aplicacion_fluor_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Tx profiláctico:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_tx_profilactico"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_tx_profilactico_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Tx parodontal:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_tx_parodontal"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_tx_parodontal_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Tx endodóntico:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_tx_endodontico"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_tx_endodontico_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Tx ortodóntico:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_tx_ortodontico"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_tx_ortodontico_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Tx quirúrgico:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_tx_quirurgico"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                          label="Si"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_tx_quirurgico_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Extracciones indicadas:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_extracciones"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_extracciones_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Restauraciones indicadas:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_restauraciones"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_restauraciones_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <!--
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Músculos:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  -->

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Sobredentaduras:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_sobredentaduras"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_sobredentaduras_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Endoposte:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_endoposte"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_endoposte_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Prótesis fija:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_protesis_fija"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_protesis_fija_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Prótesis removible:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_protesis_removible"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_protesis_removible_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Prótesis total:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_protesis_total"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_protesis_total_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Ajuste oclusal:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_ajuste_oclusal"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_ajuste_oclusal_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Guarda oclusal:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.recomendaciones_guarda_oclusal"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.recomendaciones_guarda_oclusal_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <br>
              <v-row>
                <span class="font-weight-bold">Odontograma diagnóstico</span>
                <v-col cols="12">
                  <odontograma
                    ref="odontograma"
                  ></odontograma>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.impresion_diagnostica"
                    outlined
                    dense
                    hide-details
                    rows="1"
                    auto-grow
                    label="Impresión Diagnóstica"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.detalle_impresion_diagnostica"
                    outlined
                    dense
                    hide-details
                    rows="1"
                    auto-grow
                    label="Detalle de Impresión Diagnóstica"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedItem.intervenciones_odontologicas"
                    label="Intervenciones Odontológicas"
                    outlined
                    :items="intervencionesOdontologicas"
                    item-value="id_intervencion_odontologica"
                    :item-text="intervencionNombre"
                    multiple
                    hide-details
                    chips
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.interpretacion_radiologica"
                    label="Interpretación radiográfica (en caso de realizarse):"
                    :rows="2"
                    auto-grow
                    outlined
                    hide-details
                    dense
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="headersRadiografias"
                    :items="editedItem.radiografias"
                    :items-per-page="-1"
                    hide-default-footer
                    hide-default-header
                    no-data-text="No se han adjuntado radiografias (Opcional)"
                  >
                    <template v-slot:[`item.archivo`]="{ item }">
                      <v-file-input
                        v-model="item.archivo"
                        label="Radiografía"
                        dense
                        hide-details
                        outlined
                        accept="image/*"
                        @change="convertToBase64(item)"
                      >
                      </v-file-input>
                    </template>
                    <template v-slot:[`item.descripcion`]="{ item }">
                      <v-textarea
                        v-model="item.descripcion"
                        label="Descripción"
                        auto-grow
                        rows="1"
                        dense
                        hide-details
                        outlined
                      >
                      </v-textarea>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip-icon
                        icon="mdi-trash-can-outline"
                        text="Eliminar"
                        @click="eliminarRadiografia(item)"
                      ></v-tooltip-icon>
                    </template> 
                  </v-data-table>
                  <v-btn
                    color="primary"
                    @click="agregarRadiografia"
                    text
                  >
                    Agregar Radiografia
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedItem.ids_noms"
                    label="NOMS"
                    outlined
                    hide-details
                    dense
                    :items="noms"
                    item-value="id_nom"
                    item-text="nom"
                    multiple
                    chips
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12">
                  <span class="font-weight-bold">Promoción a la salud bucodental</span>
                  <!--
                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Aplicación de flúor:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.promocion_aplicacion_fluor"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  -->

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Técnica de cepillado:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.promocion_tecnica_cepillado"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      Instrucción de uso del hilo dental:
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.promocion_uso_hilo_dental"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.promocion_uso_hilo_dental_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="pa-1 ma-0">
                    <v-col cols="3" class="pa-0 ma-0">
                      ¿Le fue entregado un kit dental (pasta dental y cepillo)?
                    </v-col>
                    <v-col cols="3" class="pa-0 ma-0">
                      <v-radio-group
                        v-model="editedItem.promocion_kit_dental"
                        required
                        row
                        hide-details
                        dense
                        class="ma-0 pa-0"
                      >
                        <v-radio
                            label="Si"
                            :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                      <v-textarea
                        v-model="editedItem.promocion_kit_dental_nota"
                        :rows="1"
                        auto-grow
                        outlined
                        hide-details
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <consulta-checkbox
                    v-model="editedItem.consulta_medica"
                    :enable="!consultaDental.consulta_medica"
                    consulta="Consulta Médica"
                    :outlined="true"
                    :dense="true"
                    :hide-details="true"
                  >
                  </consulta-checkbox>
                  <consulta-checkbox
                    v-model="editedItem.nutricion"
                    :enable="!consultaDental.nutricion"
                    consulta="Consulta Nutricional"
                    :outlined="true"
                    :dense="true"
                    :hide-details="true"
                  >
                  </consulta-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-row class="ma-0 pa-0">
                    <v-col col cols="6" class="ma-0 pa-0">
                      <v-checkbox
                        v-model="editedItem.referencia_medica"
                        label="Agregar Referencia Medica"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col col cols="6" class="ma-0 pa-0 align-self-center text-align-center">
                      <v-btn
                        :disabled="!editedItem.referencia_medica"
                        color="primary"
                        @click="$refs.referenciamedica.iniciarReferenciaMedica()"
                      >
                        Información de <br> Referencia Medica
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="6">
                  <v-card>
                    <v-container class="text-center font-weight-bold">
                      <TopazSignature
                        v-if="mostrar_firma"
                        ref="paciente"
                        id="paciente"
                      ></TopazSignature>
                      <div class="text-center text-subtitle-2">
                        {{consultaDental.paciente.nombre}} {{consultaDental.paciente.apellido_paterno}} {{consultaDental.paciente.apellido_materno}}
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="finalizarConsulta"
                    :loading="saving"
                    :disabled="saving"
                  >Finalizar Consulta</v-btn>
                  <br>
                  <br>
                  <br>
                  <br>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogHistorial"
      persistent

    >
      <v-card>
        <v-card-title>
          Historial Clinico
        </v-card-title>
        <v-card-text>
          <historial
            ref="historial"
          >
          </historial>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green"
            @click="cerrarHistorial"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import PacienteDatos from '@/components/DashViews/Comunes/PacienteDatosVertical.vue'
import VAutocompleteAdd from '@/components/VAutocompleteAdd.vue'
import Odontograma from './Odontograma.vue'
import Historial from '@/components/DashViews/Comunes/Historial.vue'
import ReferenciaMedica from './ReferenciaMedica.vue';
import VAlertDialog from '@/components/VAlertDialog.vue'
//import vueSignature from "vue-signature";
import TopazSignature from '@/components/TopazSignature.vue';
import ConsultaCheckbox from '@/components/ConsultaCheckbox.vue'

export default {
  components: {
    Odontograma,
    PacienteDatos,
    VAutocompleteAdd,
    ReferenciaMedica,
    Historial,
    VAlertDialog,
    //vueSignature
    TopazSignature,
    ConsultaCheckbox
  },
  data: () => ({
    dialog: false,
    consultaDental: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      paciente: {
          id_paciente: null,
          id_municipio: null,
          municipio: "",
          id_estado: null,
          estado: "",
          id_localidad_atencion: null,
          localidad_atencion: "",
          folio: "",
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          fecha_nacimiento: "",
          edad: "",
          edad_anhios: 0,
          genero: true,
          curp: "",
          seguro_popular: false,
          num_poliza: "",
          telefono: "",
          email: ""
      },
      num_consultas_dentales: 0,
      num_consultas_medicas: 0,
      consulta_medica: false,
      nutricion: false
    },
    defaultConsultaDental: {
      id_paciente_servicio: null,
      edad: 0,
      embarazada: false,
      paciente: {
          id_paciente: null,
          id_municipio: null,
          municipio: "",
          id_estado: null,
          estado: "",
          id_localidad_atencion: null,
          localidad_atencion: "",
          folio: "",
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          fecha_nacimiento: "",
          edad: "",
          edad_anhios: 0,
          genero: true,
          curp: "",
          seguro_popular: false,
          num_poliza: "",
          telefono: "",
          email: ""
      },
      num_consultas_dentales: 0,
      num_consultas_medicas: 0,
      consulta_medica: false,
      nutricion: false
    },
    intervencionesOdontologicas: [],
    loadingIntervencionesOdontologicas: false,
    editedItem: {
      id_paciente_servicio: null,
      receta_medica: null,
      tratamientos: [],
      radiografias: [],
      ids_noms: ['b3e0f3fc-8e7a-43c3-b4ea-3960473049e5'],
      referencia_medica: false,
      formulario_referencia_medica: null,
      informante_apellido_paterno: null,
      informante_apellido_materno: null,
      informante_nombre: null,
      id_ocupacion: null,
      id_estado_civil: null,
      id_grupo_etnico: null,
      id_religion: null,
      domicilio: null,
      telefono: null,
      motivo_consulta: null,
      padecimiento_actual: null,
      antecedentes_heredofamiliares_diatebes: null,
      antecedentes_heredofamiliares_diatebes_nota: null,
      antecedentes_heredofamiliares_hipertension: null,
      antecedentes_heredofamiliares_hipertension_nota: null,
      antecedentes_heredofamiliares_otros: null,
      higiene_oral: null,
      articulacion_temporomandibular: null,
      antecedentes_patologicos_diabetes: null,
      antecedentes_patologicos_diabetes_nota: null,
      antecedentes_patologicos_hipertension: null,
      antecedentes_patologicos_hipertension_nota: null,
      antecedentes_patologicos_cardiopatias: null,
      antecedentes_patologicos_cardiopatias_nota: null,
      antecedentes_patologicos_respiratorias: null,
      antecedentes_patologicos_respiratorias_nota: null,
      antecedentes_patologicos_neoplasticas: null,
      antecedentes_patologicos_neoplasticas_nota: null,
      antecedentes_patologicos_ets: null,
      antecedentes_patologicos_ets_nota: null,
      antecedentes_patologicos_alergias: null,
      antecedentes_patologicos_alergias_nota: null,
      antecedentes_patologicos_transfusiones: null,
      antecedentes_patologicos_transfusiones_nota: null,
      antecedentes_patologicos_embarazo: null,
      antecedentes_patologicos_embarazo_nota: null,
      antecedentes_patologicos_medicamentos: null,
      antecedentes_patologicos_medicamentos_nota: null,
      antecedentes_patologicos_toxicomanias: null,
      antecedentes_patologicos_toxicomanias_nota: null,
      antecedentes_patologicos_otros: null,
      exploracion_intraoral_labios: null,
      exploracion_intraoral_labios_nota: null,
      exploracion_intraoral_lengua: null,
      exploracion_intraoral_lengua_nota: null,
      exploracion_intraoral_carrillos: null,
      exploracion_intraoral_carrillos_nota: null,
      exploracion_intraoral_paladar_duro: null,
      exploracion_intraoral_paladar_duro_nota: null,
      exploracion_intraoral_piso_boca: null,
      exploracion_intraoral_piso_boca_nota: null,
      exploracion_intraoral_glandulas: null,
      exploracion_intraoral_glandulas_nota: null,
      exploracion_intraoral_salivares: null,
      exploracion_intraoral_salivares_nota: null,
      exploracion_intraoral_encias: null,
      exploracion_intraoral_encias_nota: null,
      exploracion_extraoral_cabeza: null,
      exploracion_extraoral_cabeza_nota: null,
      exploracion_extraoral_cuello: null,
      exploracion_extraoral_cuello_nota: null,
      exploracion_extraoral_rostro: null,
      exploracion_extraoral_rostro_nota: null,
      exploracion_extraoral_musculos: null,
      exploracion_extraoral_musculos_nota: null,
      intervenciones_odontologicas: [],
      recomendaciones_tipo_cepillo: null,
      recomendaciones_tipo_cepillo_nota: null,
      recomendaciones_tecnica_cepillado: null,
      recomendaciones_tecnica_cepillado_nota: null,
      recomendaciones_uso_tabletas: null,
      recomendaciones_uso_tabletas_nota: null,
      recomendaciones_aplicacion_fluor: null,
      recomendaciones_aplicacion_fluor_nota: null,
      recomendaciones_tx_profilactico: null,
      recomendaciones_tx_profilactico_nota: null,
      recomendaciones_tx_parodontal: null,
      recomendaciones_tx_parodontal_nota: null,
      recomendaciones_tx_endodontico: null,
      recomendaciones_tx_endodontico_nota: null,
      recomendaciones_tx_ortodontico: null,
      recomendaciones_tx_ortodontico_nota: null,
      recomendaciones_tx_quirurgico: null,
      recomendaciones_tx_quirurgico_nota: null,
      recomendaciones_extracciones: null,
      recomendaciones_extracciones_nota: null,
      recomendaciones_restauraciones: null,
      recomendaciones_restauraciones_nota: null,
      recomendaciones_sobredentaduras: null,
      recomendaciones_sobredentaduras_nota: null,
      recomendaciones_endoposte: null,
      recomendaciones_endoposte_nota: null,
      recomendaciones_protesis_fija: null,
      recomendaciones_protesis_fija_nota: null,
      recomendaciones_protesis_removible: null,
      recomendaciones_protesis_removible_nota: null,
      recomendaciones_protesis_total: null,
      recomendaciones_protesis_total_nota: null,
      recomendaciones_ajuste_oclusal: null,
      recomendaciones_ajuste_oclusal_nota: null,
      recomendaciones_guarda_oclusal: null,
      recomendaciones_guarda_oclusal_nota: null,
      impresion_diagnostica: null,
      detalle_impresion_diagnostica: null,
      interpretacion_radiologica: null,
      firma_paciente: null,
      promocion_aplicacion_fluor: null,
      promocion_tecnica_cepillado: null,
      promocion_uso_hilo_dental: null,
      promocion_uso_hilo_dental_nota: null,
      promocion_kit_dental: null,
      promocion_kit_dental_nota: null,
      odontograma: [],
      consulta_medica: false,
      nutricion: false,
    },
    defaultEditedItem: {
      id_paciente_servicio: null,
      receta_medica: null,
      tratamientos: [],
      radiografias: [],
      ids_noms: ['b3e0f3fc-8e7a-43c3-b4ea-3960473049e5'],
      referencia_medica: false,
      formulario_referencia_medica: null,
      informante_apellido_paterno: null,
      informante_apellido_materno: null,
      informante_nombre: null,
      id_ocupacion: null,
      id_estado_civil: null,
      id_grupo_etnico: null,
      id_religion: null,
      domicilio: null,
      telefono: null,
      motivo_consulta: null,
      padecimiento_actual: null,
      antecedentes_heredofamiliares_diatebes: null,
      antecedentes_heredofamiliares_diatebes_nota: null,
      antecedentes_heredofamiliares_hipertension: null,
      antecedentes_heredofamiliares_hipertension_nota: null,
      antecedentes_heredofamiliares_otros: null,
      higiene_oral: null,
      articulacion_temporomandibular: null,
      antecedentes_patologicos_diabetes: null,
      antecedentes_patologicos_diabetes_nota: null,
      antecedentes_patologicos_hipertension: null,
      antecedentes_patologicos_hipertension_nota: null,
      antecedentes_patologicos_cardiopatias: null,
      antecedentes_patologicos_cardiopatias_nota: null,
      antecedentes_patologicos_respiratorias: null,
      antecedentes_patologicos_respiratorias_nota: null,
      antecedentes_patologicos_neoplasticas: null,
      antecedentes_patologicos_neoplasticas_nota: null,
      antecedentes_patologicos_ets: null,
      antecedentes_patologicos_ets_nota: null,
      antecedentes_patologicos_alergias: null,
      antecedentes_patologicos_alergias_nota: null,
      antecedentes_patologicos_transfusiones: null,
      antecedentes_patologicos_transfusiones_nota: null,
      antecedentes_patologicos_embarazo: null,
      antecedentes_patologicos_embarazo_nota: null,
      antecedentes_patologicos_medicamentos: null,
      antecedentes_patologicos_medicamentos_nota: null,
      antecedentes_patologicos_toxicomanias: null,
      antecedentes_patologicos_toxicomanias_nota: null,
      antecedentes_patologicos_otros: null,
      exploracion_intraoral_labios: null,
      exploracion_intraoral_labios_nota: null,
      exploracion_intraoral_lengua: null,
      exploracion_intraoral_lengua_nota: null,
      exploracion_intraoral_carrillos: null,
      exploracion_intraoral_carrillos_nota: null,
      exploracion_intraoral_paladar_duro: null,
      exploracion_intraoral_paladar_duro_nota: null,
      exploracion_intraoral_piso_boca: null,
      exploracion_intraoral_piso_boca_nota: null,
      exploracion_intraoral_glandulas: null,
      exploracion_intraoral_glandulas_nota: null,
      exploracion_intraoral_salivares: null,
      exploracion_intraoral_salivares_nota: null,
      exploracion_intraoral_encias: null,
      exploracion_intraoral_encias_nota: null,
      exploracion_extraoral_cabeza: null,
      exploracion_extraoral_cabeza_nota: null,
      exploracion_extraoral_cuello: null,
      exploracion_extraoral_cuello_nota: null,
      exploracion_extraoral_rostro: null,
      exploracion_extraoral_rostro_nota: null,
      exploracion_extraoral_musculos: null,
      exploracion_extraoral_musculos_nota: null,
      intervenciones_odontologicas: [],
      recomendaciones_tipo_cepillo: null,
      recomendaciones_tipo_cepillo_nota: null,
      recomendaciones_tecnica_cepillado: null,
      recomendaciones_tecnica_cepillado_nota: null,
      recomendaciones_uso_tabletas: null,
      recomendaciones_uso_tabletas_nota: null,
      recomendaciones_aplicacion_fluor: null,
      recomendaciones_aplicacion_fluor_nota: null,
      recomendaciones_tx_profilactico: null,
      recomendaciones_tx_profilactico_nota: null,
      recomendaciones_tx_parodontal: null,
      recomendaciones_tx_parodontal_nota: null,
      recomendaciones_tx_endodontico: null,
      recomendaciones_tx_endodontico_nota: null,
      recomendaciones_tx_ortodontico: null,
      recomendaciones_tx_ortodontico_nota: null,
      recomendaciones_tx_quirurgico: null,
      recomendaciones_tx_quirurgico_nota: null,
      recomendaciones_extracciones: null,
      recomendaciones_extracciones_nota: null,
      recomendaciones_restauraciones: null,
      recomendaciones_restauraciones_nota: null,
      recomendaciones_sobredentaduras: null,
      recomendaciones_sobredentaduras_nota: null,
      recomendaciones_endoposte: null,
      recomendaciones_endoposte_nota: null,
      recomendaciones_protesis_fija: null,
      recomendaciones_protesis_fija_nota: null,
      recomendaciones_protesis_removible: null,
      recomendaciones_protesis_removible_nota: null,
      recomendaciones_protesis_total: null,
      recomendaciones_protesis_total_nota: null,
      recomendaciones_ajuste_oclusal: null,
      recomendaciones_ajuste_oclusal_nota: null,
      recomendaciones_guarda_oclusal: null,
      recomendaciones_guarda_oclusal_nota: null,
      impresion_diagnostica: null,
      detalle_impresion_diagnostica: null,
      interpretacion_radiologica: null,
      firma_paciente: null,
      promocion_aplicacion_fluor: null,
      promocion_tecnica_cepillado: null,
      promocion_uso_hilo_dental: null,
      promocion_uso_hilo_dental_nota: null,
      promocion_kit_dental: null,
      promocion_kit_dental_nota: null,
      odontograma: [],
      consulta_medica: false,
      nutricion: false,
    },
    medicamentos: [],
    defaultTratamiento: {
      id_medicamento: null,
      cantidad: 1,
      indicaciones: null
    },
    headersMedicamentos:[
      {
        text: "Tratamiento",
        value: "tratamiento",
        align: "center",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%",
        sortable: false
      }
    ],
    defaultRadiografia: {
      archivo: null,
      base64: null,
      descripcion: null
    },
    headersRadiografias: [
      {
        text: "Archivo",
        value: "archivo",
        align: "center",
        sortable: false
      },
      {
        text: "Descripción",
        value: "descripcion",
        align: "center",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%",
        sortable: false
      }
    ],
    dialogHistorial: false,
    loadingNoms: false,
    noms: [],
    validaciones: [
      { nombre: 'Domicilio', campo: 'domicilio', tipo: 'string' },
      //{ nombre: 'Teléfono', campo: 'telefono', tipo: 'string'},
      { nombre: 'Motivo de Consulta', campo: 'motivo_consulta', tipo: 'string' },
      { nombre: 'Padecimiento Actuañ', campo: 'padecimiento_actual', tipo: 'string' },
      { nombre: 'Antecedentes heredofamiliares - Diabetes', campo: 'antecedentes_heredofamiliares_diatebes', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes heredofamiliares - Hipertensión', campo: 'antecedentes_heredofamiliares_hipertension', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Higiene Oral', campo: 'higiene_oral', tipo: 'string' },
      { nombre: 'Articulación temporomandibular y otros hallazgos', campo: 'articulacion_temporomandibular', tipo: 'string' },
      { nombre: 'Antecedentes patológicos y no patológicos - Diabetes', campo: 'antecedentes_patologicos_diabetes', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Hipertensión', campo: 'antecedentes_patologicos_hipertension', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Cardiopatías', campo: 'antecedentes_patologicos_cardiopatias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Respiratorias', campo: 'antecedentes_patologicos_respiratorias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Neoplásticas', campo: 'antecedentes_patologicos_neoplasticas', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - E.T.S. (VIH)', campo: 'antecedentes_patologicos_ets', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Alergias', campo: 'antecedentes_patologicos_alergias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Transfusiones', campo: 'antecedentes_patologicos_transfusiones', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Embarazo', campo: 'antecedentes_patologicos_embarazo', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Medicamentos', campo: 'antecedentes_patologicos_medicamentos', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes patológicos y no patológicos - Toxicomanias', campo: 'antecedentes_patologicos_toxicomanias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Labios', campo: 'exploracion_intraoral_labios', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Lengua', campo: 'exploracion_intraoral_lengua', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Carrillos', campo: 'exploracion_intraoral_carrillos', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Paladar Duro', campo: 'exploracion_intraoral_paladar_duro', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Piso de boca', campo: 'exploracion_intraoral_piso_boca', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Glándulas', campo: 'exploracion_intraoral_glandulas', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Salivares', campo: 'exploracion_intraoral_salivares', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Intraoral - Encias', campo: 'exploracion_intraoral_encias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Extraoral - Cabeza', campo: 'exploracion_extraoral_cabeza', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Extraoral - Cuello', campo: 'exploracion_extraoral_cuello', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Extraoral - Rostro', campo: 'exploracion_extraoral_rostro', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Exploración Física - Extraoral - Músculos', campo: 'exploracion_extraoral_musculos', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Expedir Receta Médica', campo: 'receta_medica', tipo: 'boolean', nota: false, valor_nota: false },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Tipo de Cepillo', campo: 'recomendaciones_tipo_cepillo', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Técnica de Cepillado', campo: 'recomendaciones_tecnica_cepillado', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Uso de tabletas reveladoras', campo: 'recomendaciones_uso_tabletas', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Aplicación de flúor', campo: 'recomendaciones_aplicacion_fluor', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Tx profiláctico', campo: 'recomendaciones_tx_profilactico', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Tx parodontal', campo: 'recomendaciones_tx_parodontal', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Tx endodóntico', campo: 'recomendaciones_tx_endodontico', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Tx ortodóntico', campo: 'recomendaciones_tx_ortodontico', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Tx quirúrgico', campo: 'recomendaciones_tx_quirurgico', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Extracciones indicadas', campo: 'recomendaciones_extracciones', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Restauraciones indicadas', campo: 'recomendaciones_restauraciones', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Sobredentaduras', campo: 'recomendaciones_sobredentaduras', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Endoposte', campo: 'recomendaciones_endoposte', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Prótesis fija', campo: 'recomendaciones_protesis_fija', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Prótesis removible', campo: 'recomendaciones_protesis_removible', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Prótesis total', campo: 'recomendaciones_protesis_total', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Ajuste oclusal', campo: 'recomendaciones_ajuste_oclusal', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Recomendaciones de acuerdo con su diagnóstico - Guarda oclusal', campo: 'recomendaciones_guarda_oclusal', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Impresión Diagnóstica', campo: 'impresion_diagnostica', tipo: 'string' },
      { nombre: 'Detalle de Impresión Diagnóstica', campo: 'detalle_impresion_diagnostica', tipo: 'string' },
      //{ nombre: 'Promoción a la salud bucodental - Aplicación de flúor', campo: 'promocion_aplicacion_fluor', tipo: 'boolean', nota: false, valor_nota: false },
      { nombre: 'Promoción a la salud bucodental - Técnica de cepillado', campo: 'promocion_tecnica_cepillado', tipo: 'boolean', nota: false, valor_nota: false },
      { nombre: 'Promoción a la salud bucodental - Instrucción de uso del hilo dental', campo: 'promocion_uso_hilo_dental', tipo: 'boolean', nota: false, valor_nota: false },
      { nombre: 'Promoción a la salud bucodental - ¿Le fue entregado un kit dental (pasta dental y cepillo)?', campo: 'promocion_kit_dental', tipo: 'boolean', nota: false, valor_nota: false },
      
    ],
    saving: false,
    mostrar_firma: false,
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    response_consulta: {
      receta: false,
      receta_farmcia: false
    },
    default_response_consulta: {
      receta: false,
      receta_farmcia: false
    },
    dialogComplete: false
  }),
  mounted() {
    this.getMedicamentos();
    this.getIntervencionesOdontologicas();
    this.getNOMS();
  },
  computed: {
    ...mapState(['token', 'extemporaneo']),
  },
  methods: {
    medicamentoNombre: medicamento => `[${medicamento.codigo}] - ${medicamento.farmaco} ${medicamento.concentracion} - ${medicamento.stock} PZA - ${medicamento.presentacion}`,
    intervencionNombre: intervencion => `${intervencion.intervencion}${(intervencion.sub_intervencion != null) ? ` - ${intervencion.sub_intervencion}` : ''}`,
    getMedicamentos(){
      this.loadingMedicamentos = true;
      axios
        .get('/Catalogos/ListarMedicamentos?id_servicio=ba93d324-93b6-4fa6-bbcf-4073a16eb9fa&mostrar_stock=true')
        .then(response => {
          this.medicamentos = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingMedicamentos = false;
        })
    },
    getNOMS(){
      this.loadingNoms = true;
      axios
        .get('/Catalogos/ListarNOMS/ba93d324-93b6-4fa6-bbcf-4073a16eb9fa')
        .then(response => {
          this.noms = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingNoms = false;
        })
    },
    getIntervencionesOdontologicas(){
      
      this.loadingIntervencionesOdontologicas = true;
      axios
        .get('/Catalogos/ListarIntervencionesOdontologicas')
        .then(response => {
          this.intervencionesOdontologicas = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingIntervencionesOdontologicas = false;
        })
    },
    iniciarConsulta(id_paciente_servicio){
      //console.log(id_paciente_servicio);
      //this.consultaDental.id_paciente_servicio = id_paciente_servicio;
      //this.dialog = true;
      axios
      .post(`/ConsultasDentales/IniciarConsulta/${id_paciente_servicio}`)
      .then(response => {
        this.consultaDental = response.data;

        this.dialog = true;

        setTimeout(() => {
          this.$refs.paciente_datos.actualizar(id_paciente_servicio);
          this.mostrar_firma = true;
        },250);

        setTimeout(() => {
          this.editedItem.id_ocupacion = response.data.id_ocupacion;
          this.editedItem.id_estado_civil = response.data.id_estado_civil;
          this.editedItem.id_grupo_etnico = response.data.id_grupo_etnico;
          this.editedItem.id_religion = response.data.id_religion;
          
          if (response.data.domicilio != null && response.data.domicilio.length > 0) this.editedItem.domicilio = response.data.domicilio;
          else this.editedItem.domicilio = null;
        },500);

        
      })
      .catch(error => {
        console.log(error)
      })
    },
    cancelarInicioConsulta(){
      //this.dialog = false;
      //this.$emit('complete', null);
      axios
      .post(`/ConsultasDentales/CancelarInicioConsulta/${this.consultaDental.id_paciente_servicio}`)
      .then(() => {
        this.cerrarDialogo();
        this.$emit('complete', null);
      })
      .catch(error => {
        console.log(error)
      });
    },
    agregarMedicamento(){
      this.editedItem.tratamientos.push(Object.assign({}, this.defaultTratamiento))
    },
    eliminarMedicamento(item){
      this.editedItem.tratamientos = this.editedItem.tratamientos.filter(x => x != item);
    },
    agregarRadiografia(){
      this.editedItem.radiografias.push(Object.assign({}, this.defaultRadiografia))
    },
    eliminarRadiografia(item){
      this.editedItem.radiografias = this.editedItem.radiografias.filter(x => x != item);
    },
    actualizarDatos(){

    },
    abrirHistorial(){
      this.dialogHistorial = true;
      setTimeout(() => 
      this.$refs.historial.cambiarPaciente(this.consultaDental.paciente.id_paciente), 100);
    },
    cerrarHistorial(){
      try {
        this.$refs.historial.limpiarHistorial();
      } catch (error) { 
          console.log(error);
      }

      this.dialogHistorial = false;
    },
    async finalizarConsulta(){
      var valido = true;

      if(this.consultaDental.edad < 18){
        if(this.editedItem.informante_apellido_paterno == null || this.editedItem.informante_apellido_paterno.length <= 0){
          this.$refs.alert.setMessage('', `Debe de indicar el Apellido Paterno del Informante`);
          this.$refs.alert.open();
          valido = false;
          return;
        }

        if(this.editedItem.informante_apellido_materno == null || this.editedItem.informante_apellido_materno.length <= 0){
          this.$refs.alert.setMessage('', `Debe de indicar el Apellido Paterno del Informante`);
          this.$refs.alert.open();
          valido = false;
          return;
        }

        if(this.editedItem.informante_nombre == null || this.editedItem.informante_nombre.length <= 0){
          this.$refs.alert.setMessage('', `Debe de indicar el Apellido Paterno del Informante`);
          this.$refs.alert.open();
          valido = false;
          return;
        }
      }

      if(this.editedItem.id_ocupacion == null){
        this.$refs.alert.setMessage('', `Debe de indicar la Ocupación del Paciente`);
        this.$refs.alert.open();
        valido = false;
        return;
      }

      if(this.editedItem.id_estado_civil == null){
        this.$refs.alert.setMessage('', `Debe de indicar el Estado Civil del Paciente`);
        this.$refs.alert.open();
        valido = false;
        return;
      }

      if(this.editedItem.id_grupo_etnico == null){
        this.$refs.alert.setMessage('', `Debe de indicar el Grupo Etnico del Paciente`);
        this.$refs.alert.open();
        valido = false;
        return;
      }

      if(this.editedItem.id_religion == null){
        this.$refs.alert.setMessage('', `Debe de indicar la Religión del Paciente`);
        this.$refs.alert.open();
        valido = false;
        return;
      }
      
      for (let i = 0; i < this.validaciones.length; i++) {
        if(this.editedItem[this.validaciones[i].campo] == null || (this.validaciones[i].tipo == 'string' && this.editedItem[this.validaciones[i].campo].length < 0)){
          valido = false;
          this.$refs.alert.setMessage("", `El Campo ${this.validaciones[i].nombre} es Obligatorio`);
          this.$refs.alert.open();
          break;
        }

        if(this.validaciones[i].tipo == 'boolean' && this.validaciones[i].nota == true && this.editedItem[this.validaciones[i].campo] == this.validaciones[i].valor_nota && ( this.editedItem[this.validaciones[i].campo+'_nota'] == null ||  this.editedItem[this.validaciones[i].campo+'_nota'].length < 0    )){
          valido = false;
          this.$refs.alert.setMessage("", `La Nota del Campo ${this.validaciones[i].nombre} es Obligatoria`);
          this.$refs.alert.open();
          break;
        }
      }

      /*
      if(this.$refs.odontograma.obtenerDatos() == null || this.$refs.odontograma.obtenerDatos().length <= 0){
        this.$refs.alert.setMessage("", `Debe de indicar al menos una pieza dental`);
        this.$refs.alert.open();
        return;
      }
      */

      if(this.editedItem.referencia_medica == true){
        this.editedItem.formulario_referencia_medica = this.$refs.referenciamedica.obtenerDatos();
        if(this.editedItem.formulario_referencia_medica == null){
          this.$refs.alert.setMessage("", "Falta llenar el Formulario de Referencia Medica");
          this.$refs.alert.open();
          return;
        }        
      }

      if(this.editedItem.radiografias.length > 0){
        this.editedItem.radiografias.forEach((rad,renglon) => {
          if(rad.archivo == null){
            this.$refs.alert.setMessage("", `No se cargo una Radiografía en el Renglon #${renglon+1}`);
            this.$refs.alert.open();
            return;
          }

          if(rad.descripcion == null || rad.descripcion.length <= 0){
            this.$refs.alert.setMessage("", `No se indicó una Descripción en el Renglon #${renglon+1}`);
            this.$refs.alert.open();
            return;
          }
          
        });
      }

      if(this.$refs.paciente.isEmpty() && !this.extemporaneo){
        this.$refs.alert.setMessage("", "Falta la Firma del Paciente");
        this.$refs.alert.open();
        valido = false;
        return;
      }

      if(!valido) return;

      this.saving = true;

      this.editedItem.odontograma = this.$refs.odontograma.obtenerDatos();

      this.editedItem.formulario_referencia_medica = (this.editedItem.formulario_referencia_medica != null) ? JSON.stringify(this.editedItem.formulario_referencia_medica) : null,
        

      this.editedItem.id_paciente_servicio = this.consultaDental.id_paciente_servicio;
      this.editedItem.firma_paciente = (!this.extemporaneo) ? this.$refs.paciente.save() : null;

      if(this.editedItem.receta_medica == false) this.editedItem.tratamientos = [];

      axios
      .post(`/ConsultasDentales/FinalizarConsulta`,this.editedItem)
      .then(response => {
        this.response_consulta = Object.assign({}, response.data);
        this.dialogComplete = true;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.saving = false;
      })
    },
    cerrarDialogo(){
      this.$refs.odontograma.restablecer();
      this.consultaDental = Object.assign({}, this.defaultConsultaDental);
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.editedItem.tratamientos = [];
      this.editedItem.radiografias = [];
      this.dialog = false;
      this.$refs.paciente.clear();
      this.mostrar_firma = false;
      this.response_consulta = Object.assign({}, this.default_response_consulta);
      this.dialogComplete = false;

      this.$emit('complete', null);
    },
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    convertToBase64(item){
      if (!item.archivo) return;
      const reader = new FileReader();

      reader.onload = () => {
        item.base64 = reader.result;
      };
      reader.readAsDataURL(item.archivo);
    }
  }
}
</script>

<style scoped>
.sticky-content {
  position: sticky;
  top: 20px; /* Ajusta la posición según sea necesario */
 }
</style>