<template>
  <div
    class="pa-3 ma-3"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogValidar"
      persistent
    >
      <v-card>
        <v-card-title>
          Estandarizar Diagnostico Médico
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.diagnostico"
                readonly
                dense
                hide-details
                disabled
                outlined
                label="Diagnóstico No Identificado"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.ids_diagnosticos_medicos"
                :items="diagnosticoMedicos"
                item-value="id_diagnostico_medico"
                item-text="diagnostico"
                multiple
                outlined
                dense
                hide-details
                clearable
                chips
                deletable-chips
                label="Diagnóstico Estandarizado"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green"
            class="white--text"
            @click="closeDialog"
            :loading="saving"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="red"
            class="white--text"
            @click="guardarNormalizacion"
            :loading="saving"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
        >
          <template v-slot:[`item.diagnosticos_candidatos`]="{ item }">
            <template v-if="item.diagnosticos_candidatos.length == 0">
              No hay coincidencias
            </template>
            <template v-else>
              <ul>
                <li v-for="(d,idx) in item.diagnosticos_candidatos" :key="`idx-`+idx">{{d}}</li>
              </ul>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialog(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue';

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    loading: false,
    items: [],
    headers:[
      {
        text: "Diagnóstico Médico",
        value: "diagnostico",
        align: "center",
        width: "45%"
      },
      {
        text: "Diagnóstico Médico Candidatos",
        value: "diagnosticos_candidatos",
        align: "center",
        width: "40%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "15%"
      }
    ],
    diagnosticoMedicos: [],
    loadingDiagnosticosMedicos: false,
    dialogValidar: false,
    editedItem: {
      id_diagnostico_medico_conversion: null,
      diagnostico: null,
      ids_diagnosticos_medicos: [],
      diagnosticos_candidatos: []
    },
    defaultEditedItem: {
      id_diagnostico_medico_conversion: null,
      diagnostico: null,
      ids_diagnosticos_medicos: [],
      diagnosticos_candidatos: []
    },
    saving: false,
  }),
  mounted() {
    this.getItems();
    this.getDiagnosticosMedicos();
  },
  methods: {
    getDiagnosticosMedicos(){
      this.loadingDiagnosticosMedicos = true;
      axios
        .get('/Catalogos/ListarDiagnosticosMedicos')
        .then(response => {
          this.diagnosticoMedicos = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingDiagnosticosMedicos = false;
        })
    },
    getItems(){
      this.loading = true;
      axios
      .get('/Migraciones/ListarConversionesPendientes')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialog(item){
      this.editedItem = Object.assign({}, item);
      this.dialogValidar = true;
    },
    closeDialog(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogValidar = false;
    },
    guardarNormalizacion(){
      this.saving = true;
      axios
      .post(`/Migraciones/ValidarConversion`,{
        id_diagnostico_medico_conversion: this.editedItem.id_diagnostico_medico_conversion,
        ids_diagnosticos_medicos: this.editedItem.ids_diagnosticos_medicos
      })
      .then(() => {
        this.closeDialog();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.saving = false;
      })
    }
  }
}
</script>
